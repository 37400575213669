import React, { useState, useEffect } from 'react';
import {Card, CardContent, Typography, Divider, Grid,Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import axios from 'axios';
import CreditTracking from './CreditTracking';
import CategoryScores from './CategoryScores';
import ClientOverview from './ClientOverview';
import CreditFactors from './CreditFactors';
import LoanDetails from './LoanDetails';
import ReportActionButtons from './ReportActionButtons';
import ReportDialogs from './ReportDialogs';
import { fetchWithAuth } from '../../../auth';
import Cookies from 'js-cookie';

const Report = ({ customerId }) => {
  const [customer, setCustomer] = useState(null);
  const [loanRecommendation, setLoanRecommendation] = useState({});
  const [loanDetails, setLoanDetails] = useState({});
  const [reviewers, setReviewers] = useState([]);
  const [admins, setAdmins] = useState([]); 
  const [selectedReviewer, setSelectedReviewer] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [note, setNote] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [confirmDisbursementDialogOpen, setConfirmDisbursementDialogOpen] = useState(false);
  const [openRejectionDialog, setOpenRejectionDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
    // **New State Variables for Disbursement Dialog**
  const [loanDisbursementDialogOpen, setLoanDisbursementDialogOpen] = useState(false);
  const [loanAmount, setLoanAmount] = useState('');
  const [loanProduct, setLoanProduct] = useState('');
  const [loanPeriod, setLoanPeriod] = useState('');
  const [modeOfDisbursal, setModeOfDisbursal] = useState('bank transfer');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [emi, setEmi] = useState('');
  const [firstPayment, setFirstPayment] = useState('');


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/customer-report-details/${customerId}`);
        setCustomer(response.data);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      }
    };

    const fetchLoanRecommendation = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/unsecured_recommendation/${customerId}`);
        setLoanRecommendation(response.data);
      } catch (error) {
        console.error('Error fetching loan recommendation:', error);
      }
    };

    const fetchDisbursementDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/customer-disbursement-details/${customerId}`);
        const data = response.data;
        setBankAccountNumber(data.bank_account_number);
        setPhoneNumber(data.phone_number);
        setLoanPeriod(data.loan_tenure);
        setBankName(data.bank_name);
      } catch (error) {
        console.error('Error fetching disbursement details:', error);
      }
    };

    const fetchLoanDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/loan-details/${customerId}`);
        const data = response.data;
        setLoanDetails(data);
    
        // Autopopulate disbursement dialog fields
        setLoanAmount(data.amount);
        setLoanProduct(data.product_name);
        setInterestRate(data.interest_rate);
        setEmi(Math.round(data.emi || (data.amount / (data.loan_tenor/3) + 8672)));
      } catch (error) {
        console.error('Error fetching loan details:', error);
      }
    };

    const fetchReviewers = async () => {
      try {
        const response = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/fetch_reviewers`);
        const reviewersData = response.map((reviewer) => ({
          id: reviewer.id,
          name: `${reviewer.first_name} ${reviewer.last_name}`,
          email: reviewer.email,
          role: reviewer.role,
        }));
        setReviewers(reviewersData);
      } catch (error) {
        console.error('Error fetching reviewers:', error);
      }
    };

    const fetchAdmins = async () => {
      try {
        const response = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/fetch_admins`);
        const adminsData = response.map((admin) => ({
          id: admin.id,
          name: `${admin.first_name} ${admin.last_name}`,
          email: admin.email,
          role: admin.role,
        }));
        setAdmins(adminsData);
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    };

    const fetchUserRole = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user_role`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('accessToken')}`,
          },
        });
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    fetchCustomerDetails();
    fetchLoanRecommendation();
    fetchDisbursementDetails();
    fetchLoanDetails();
    fetchReviewers();
    fetchAdmins();
    fetchUserRole();
  }, [customerId]);

  const handleDownloadPDF = () => {
  };

  const handleSendToReview = () => {
    setDialogTitle('Send Report for Review');
    setOpenDialog(true);
  };

  const handleSendForApproval = () => {
    setDialogTitle('Send Report for Approval');
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleProceedToDisbursement = () => {
    setConfirmDisbursementDialogOpen(true);
  };
  
  const handleConfirmDisbursementClose = () => {
    setConfirmDisbursementDialogOpen(false);
  };
  
  const handleConfirmDisbursement = () => {
    // console.log(`Approved Loan Application for ${customer.first_name} ${customer.last_name}`);
    setConfirmDisbursementDialogOpen(false);
    setLoanDisbursementDialogOpen(true);
  };


  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const accessToken = Cookies.get('accessToken');
  const handleSendReport = async () => {
    try {
      const url = dialogTitle.includes('Review')
        ? `${process.env.REACT_APP_API_BASE_URL}/api/send-to-review`
        : `${process.env.REACT_APP_API_BASE_URL}/api/send-to-approval`;
        
      const payload = dialogTitle.includes('Review')
        ? {
            reviewer_id: selectedReviewer.id,
            note: note,
            customer_id: customerId
          }
        : {
            admin_id: selectedAdmin.id,
            note: note,
            customer_id: customerId
          };
  
      const response = await axios.post(
        url,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setOpenDialog(false);
      setSnackbarMessage('Report sent successfully!');
      setSnackbarOpen(true);
      setSelectedReviewer(null);
      setSelectedAdmin(null);
      setNote('');
    } catch (error) {
      console.error('Error sending report:', error);
    }
  }; 

  const handleDisburseFunds = async () => {
    const disbursementData = {
      customer_id: customerId,
      loan_amount: loanAmount,
      loan_product: loanProduct,
      loan_period: loanPeriod,
      mode_of_disbursal: modeOfDisbursal,
      bank_account_number: modeOfDisbursal === 'bank transfer' ? bankAccountNumber : null,
      interest_rate: interestRate,
      emi: emi,
      first_payment: firstPayment,
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/disburse-loan`,
        disbursementData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoanDisbursementDialogOpen(false); // Close the disbursement dialog on success
      setSnackbarMessage('Funds Disbursed Successfully!');
      setSnackbarOpen(true); // Show success notification
    } catch (error) {
      console.error('Error disbursing loan:', error);
      // Optionally, show an error snackbar or message
    }
  };  

  // Handle opening the rejection dialog
  const handleOpenRejectionDialog = () => {
    setOpenRejectionDialog(true);
  };

  // Handle closing the rejection dialog
  const handleCloseRejectionDialog = () => {
    setOpenRejectionDialog(false);
    setRejectionReason(''); // Reset reason on close
  };

  // Handle rejecting the application
  const handleRejectApplication = () => {
    // Logic for handling rejection will go here (e.g., calling the API)
    console.log('Rejection reason:', rejectionReason);
    // After handling rejection logic, close the dialog
    setOpenRejectionDialog(false);
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'role', headerName: 'Role', flex: 1 },
  ];

  return (
    <div style={{ padding: '20px' }}>
       <ReportActionButtons
          userRole={userRole}
          handleSendToReview={handleSendToReview}
          handleSendForApproval={handleSendForApproval}
          handleDownloadPDF={handleDownloadPDF}
          handleProceedToDisbursement={handleProceedToDisbursement}
          handleOpenRejectionDialog={handleOpenRejectionDialog} // Pass this to the action buttons
        />
      {customer && (
        <>
          <Card sx={{
            justifyContent: 'space-between',
            backgroundColor: colors.primary[500],
          }}>
            <ClientOverview customer={customer} />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <CreditTracking customerId={customerId} />
                </Grid>
                <Grid item xs={4}>
                  <Card sx={{ backgroundColor: colors.primary[500] }}>
                    <CategoryScores customerId={customerId} />
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <CreditFactors 
                loanRecommendation={loanRecommendation} 
                colors={colors} 
                customerId={customerId}
              />
            </CardContent>
            <Divider />
            <CardContent>
              <LoanDetails 
                loanDetails={loanDetails} 
                loanRecommendation={loanRecommendation} 
                colors={colors} 
              />
            </CardContent>
          </Card>
          <ReportDialogs
            openDialog={openDialog}
            handleDialogClose={handleDialogClose}
            dialogTitle={dialogTitle}
            reviewers={reviewers}
            admins={admins}
            columns={columns}
            note={note}
            setNote={setNote}
            selectedReviewer={selectedReviewer}
            setSelectedReviewer={setSelectedReviewer}
            selectedAdmin={selectedAdmin}
            setSelectedAdmin={setSelectedAdmin}
            handleSendReport={handleSendReport}
            confirmDisbursementDialogOpen={confirmDisbursementDialogOpen}
            handleConfirmDisbursementClose={handleConfirmDisbursementClose}
            handleConfirmDisbursement={handleConfirmDisbursement}
            customer={customer}
            // **Pass New Props for Disbursement Dialog**
            loanDisbursementDialogOpen={loanDisbursementDialogOpen}
            setLoanDisbursementDialogOpen={setLoanDisbursementDialogOpen}
            loanAmount={loanAmount}
            setLoanAmount={setLoanAmount}
            loanProduct={loanProduct}
            setLoanProduct={setLoanProduct}
            loanPeriod={loanPeriod}
            setLoanPeriod={setLoanPeriod}
            modeOfDisbursal={modeOfDisbursal}
            setModeOfDisbursal={setModeOfDisbursal}
            bankAccountNumber={bankAccountNumber}
            setBankAccountNumber={setBankAccountNumber}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            bankName={bankName}
            setBankName={setBankName}
            interestRate={interestRate}
            setInterestRate={setInterestRate}
            emi={emi}
            setEmi={setEmi}
            firstPayment={firstPayment}
            setFirstPayment={setFirstPayment}
            handleDisburseFunds={handleDisburseFunds}
            //Rejection dialog passing
            openRejectionDialog={openRejectionDialog}
            handleRejectionDialogClose={handleCloseRejectionDialog}
            rejectionDialogTitle="Loan Application Rejection:"
            dialogContent={`Reject loan application for ${customer?.first_name} ${customer?.last_name}?`}
            rejectionReason={rejectionReason}
            setRejectionReason={setRejectionReason}
            handleRejectApplication={handleRejectApplication}
          />
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Report;