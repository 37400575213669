import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useTheme } from "@mui/material";
import { tokens } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiMenu from '@mui/material/Menu';
import MuiAvatar from '@mui/material/Avatar';
import MuiMenuItem from '@mui/material/MenuItem';
import { Box, Typography, Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PerfectScrollbarComponent from 'react-perfect-scrollbar';
import { io } from 'socket.io-client';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Cookies from 'js-cookie';
import ReviewerNotifications from './ReviewerNotifications';
import ApproverNotifications from './ApproverNotifications';

const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`
}));

const styles = {
  maxHeight: 349,
  '& .MuiMenuItem-root:last-of-type': {
    border: 0
  }
};

const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  ...styles
});

const Avatar = styled(MuiAvatar)({
  width: '2.375rem',
  height: '2.375rem',
  fontSize: '1.125rem'
});

const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  flex: '1 1 100%',
  overflow: 'hidden',
  fontSize: '0.75rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75)
}));

const MenuItemSubtitle = styled(Typography)({
  flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
});

const NotificationDropdown = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [currentTab, setCurrentTab] = useState('unread');
  const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const ScrollWrapper = ({ children }) => {
    if (hidden) {
      return <Box sx={{ ...styles, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>;
    } else {
      return (
        <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>
      );
    }
  };

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
          query: { token: accessToken },
          transports: ["websocket"], // Ensure WebSocket transport is used
          reconnection: true, // Enable reconnection
          reconnectionAttempts: 5, // Limit reconnection attempts
          reconnectionDelay: 2000, // Wait 2 seconds before retrying
          reconnectionDelayMax: 5000, // Max delay of 5 seconds
        });
  
    const handleConnect = () => {
      console.log('WebSocket connection established.');
    };
  
    const handleDisconnect = () => {
      console.log('WebSocket connection closed.');
    };
  
    const handleNotification = (notification) => {
      console.log('Received notification:', notification);
      notification.isRead = false;
    
      // Parse the content of the notification
      try {
        notification.content = JSON.parse(notification.content);
      } catch (error) {
        console.error('Failed to parse notification content:', error);
      }
    
      const notificationType = notification.type || notification.content.type;
    
      if (notificationType === 'reviewer' || notificationType === 'admin' || notificationType === 'approver') {
        setNotifications(prevNotifications => [notification, ...prevNotifications]);
        setUnreadNotifications(prevNotifications => [notification, ...prevNotifications]);
      } else if (notificationType === 'appraisal_completed') {
        setNotifications(prevNotifications => [notification, ...prevNotifications]);
        setUnreadNotifications(prevNotifications => [notification, ...prevNotifications]);
      } else {
        setNotifications(prevNotifications => [notification, ...prevNotifications]);
        setUnreadNotifications(prevNotifications => [notification, ...prevNotifications]);
      }
    };    

    const handleTokenRefresh = (data) => {
      const newToken = data.token;
      Cookies.set('access_token', newToken);
      // Reconnect the WebSocket with the new token
      socket.io.opts.query = { token: newToken };
      socket.connect();
    };
  
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);
    socket.on('notification', handleNotification);
    // socket.on('token_refresh', handleTokenRefresh);

    socket.on("token_refresh", (data) => {
          console.log("Token refreshed:", data);
          Cookies.set('accessToken', data.token); // Update the token in cookies
          socket.emit("connect"); // Attempt reconnection with new token
        });    
  
    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
      socket.off('notification', handleNotification);
      socket.off('token_refresh', handleTokenRefresh);
      socket.disconnect();
    };
  }, []);  
  
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/notifications/unread`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('accessToken')}`
          }
        });
        const data = await response.json();
    
        const parsedData = data.map(notification => {
          if (typeof notification.content === 'string') {
            try {
              notification.content = JSON.parse(notification.content);
            } catch (error) {
              console.error('Failed to parse notification content:', error);
            }
          }
          return notification;
        });
    
        setUnreadNotifications(parsedData);
      } catch (error) {
        console.error('Failed to fetch unread notifications:', error);
      }
    };    
  
    fetchUnreadNotifications();
  }, []);
  

  const markAsRead = async (notification) => {
    notification.isRead = true;
  
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/notifications/mark-as-read`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('accessToken')}`
        },
        body: JSON.stringify({ notification_id: notification.id })
      });
  
      setUnreadNotifications(prevNotifications => 
        prevNotifications.filter(notif => notif.id !== notification.id)
      );
      setReadNotifications(prevNotifications => [notification, ...prevNotifications]);
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };
  
  

  const renderNotifications = (notifications, isUnread) => {
    return notifications.map((notification, index) => {
      const isReviewerNotification = notification.type === 'reviewer' || notification.content?.type === 'reviewer';
      const isAdminNotification = notification.type === 'admin' || notification.content?.type === 'admin' || notification.type === 'approver';
      const isAppraisalCompleted = notification.type === 'appraisal_completed' || notification.content?.type === 'appraisal_completed';
  
      const customerId = notification.customer_id || notification.content?.customer_id;
  
      if (isReviewerNotification) {
        return (
          <ReviewerNotifications 
            key={index} 
            notification={notification} 
            onClick={() => {
              navigate('/CreditAppraisal', { state: { customerId } });
              if (isUnread) {
                markAsRead(notification);
              }
              handleDropdownClose();
            }}
            markAsRead={markAsRead}
          />
        );
      } else if (isAdminNotification) {
        return (
          <ApproverNotifications 
            key={index} 
            notification={notification} 
            onClick={() => {
              navigate('/CreditAppraisal', { state: { customerId } });
              if (isUnread) {
                markAsRead(notification);
              }
              handleDropdownClose();
            }}
            markAsRead={markAsRead}
          />
        );
      } else if (isAppraisalCompleted) {
        return (
          <MenuItem 
            key={index} 
            onClick={() => {
              navigate('/CreditAppraisal', { state: { customerId } });
              if (isUnread) {
                markAsRead(notification);
              }
              handleDropdownClose();
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ color: 'common.white', backgroundColor: 'success.main' }}>
                {notification.initials || 'AC'}
              </Avatar>
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>{notification.customer_name} Appraisal Completed</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>{notification.appraisal_date}</MenuItemSubtitle>
              </Box>
              <Button size="small" variant="contained" color="success">
                <Typography variant='caption'>
                  View
                </Typography>
              </Button>
            </Box>
          </MenuItem>
        );
      } else {
        // Default case for other notifications
        return (
          <MenuItem 
            key={index} 
            onClick={() => {
              navigate('/CreditAppraisal', { state: { customerId } });
              if (isUnread) {
                markAsRead(notification);
              }
              handleDropdownClose();
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ color: 'common.white', backgroundColor: 'secondary.main' }}>
                {notification.initials}
              </Avatar>
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>{notification.customer_name} Completed.</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>{notification.appraisal_date}</MenuItemSubtitle>
              </Box>
              <Button size="small" variant="contained" color="secondary">
                <Typography variant='caption'>
                  View
                </Typography>
              </Button>
            </Box>
          </MenuItem>
        );
      }
    });
  };

  return (
    <Fragment>
      <Tooltip title="Notifications" placement="bottom">
        <IconButton 
          color='inherit' 
          aria-haspopup='true' 
          onClick={handleDropdownOpen} 
          aria-controls='customized-menu'
        >
          <Badge badgeContent={unreadNotifications.length} color="secondary">
            <NotificationsOutlinedIcon color="action" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            backgroundColor: colors.primary[500],
          }
        }}
      >
        <MenuItem disableRipple>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography variant="h5" style={{ fontWeight: 600, marginTop: '0px' }}>Notifications</Typography>
            <Chip
              size='small'
              label={`${unreadNotifications.length} New`}
              color='secondary'
              sx={{ height: 25, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px' }}
            />
          </Box>
        </MenuItem>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="notification tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab
            label="Unread"
            value="unread"
            sx={{ fontWeight: '600' }}
          />
          <Tab
            label="Read"
            value="read"
            sx={{ fontWeight: '600' }}
          />
        </Tabs>
        <ScrollWrapper>
          {currentTab === 'unread' ? renderNotifications(unreadNotifications, true) : renderNotifications(readNotifications, false)}
        </ScrollWrapper>
        <MenuItem
          disableRipple
          sx={{ py: 3.5, borderBottom: 0, borderTop: theme => `1px solid ${theme.palette.divider}` }}
        >
          <Button 
            fullWidth
            variant='contained'
            onClick={() => {
              setReadNotifications([...readNotifications, ...unreadNotifications]);
              setUnreadNotifications([]);
              setCurrentTab('read');
              handleDropdownClose();
            }}
            sx={{
                backgroundColor: colors.blueAccent[700],
                "&:hover": {
                  backgroundColor: colors.myAccent[800],
                },
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "545",
                padding: "10px 20px",
              }}
          >
            Mark All As Read
          </Button>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default NotificationDropdown;

















































































