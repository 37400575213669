import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import { ArrowDown as ArrowDownIcon } from '@phosphor-icons/react/dist/ssr/ArrowDown';
import { ArrowUp as ArrowUpIcon } from '@phosphor-icons/react/dist/ssr/ArrowUp';
import { Wallet as WalletIcon } from '@phosphor-icons/react/dist/ssr/Wallet';
import axios from 'axios';

export default function CompanyMonthlyIPFNetSales({ selectedMonth, selectedYear }) {
  const [ipfMonthlyNetSales, setIpfMonthlyNetSales] = useState(null);
  const [error, setError] = useState(null);

  const TrendIcon = Math.random() > 0.5 ? ArrowUpIcon : ArrowDownIcon; // Randomly choose up or down
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const trendColor = colors.greenAccent[400]; // Randomly choose a trend color
  const diff = Math.floor(Math.random() * 101); // Random percentage between 0 and 100

  const fetchSalesData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ipf-sales-summary`, {
        params: {
          month: selectedMonth,
          year: selectedYear,
        },
      });
      console.log('Sales Data:', response.data.sales_data); // Debugging log

      const ipfMonthlyNetSales = response.data.sales_data?.[0]?.ipf_net_sales || 0;
      setIpfMonthlyNetSales(ipfMonthlyNetSales);
      setError(null);
    } catch (err) {
      console.error('Error fetching sales data:', err.response?.data?.message || err); // Debugging log
      setError(err.response?.data?.message || 'Failed to fetch data.');
      setIpfMonthlyNetSales(null);
    }
  };

  useEffect(() => {
    fetchSalesData();
  }, [selectedMonth, selectedYear]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'KES', // Currency code for Kenyan Shilling
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <Card sx={{ padding: '10px', backgroundColor: colors.primary[400] }}>
      <CardContent>
        <Stack spacing={0.5}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={1.5}>
            <Stack spacing={1}>
              <Typography color={colors.grey[100]} fontWeight="600" variant="h6">
                IPF Net Sales:
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]} fontWeight="bold">
                {ipfMonthlyNetSales !== null ? formatCurrency(ipfMonthlyNetSales) : 'KES 0.00'}
              </Typography>
            </Stack>
            <Avatar sx={{ backgroundColor: colors.blueAccent[300], height: '56px', width: '56px' }}>
              <WalletIcon fontSize="var(--icon-fontSize-lg)" />
            </Avatar>
          </Stack>
          {diff ? (
            <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
              <Stack sx={{ alignItems: 'center' }} direction="row" spacing={0.5}>
                <TrendIcon color={trendColor} fontSize="var(--icon-fontSize-md)" />
                <Typography color={trendColor} variant="body2">
                  0%
                </Typography>
              </Stack>
              <Typography color="text.secondary" variant="caption">
                Since last month
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}