import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header';
import Workflow from './workflow';
import LoanNotification from './Messaging/message_templates';
import ListIcon from '@mui/icons-material/List';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ChatIcon from '@mui/icons-material/Chat';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import DisbursedLoans from './loan management/disbursedLoans';
import LoanConfiguration from './loan settings/loanConfig';
import AslPortfolio from './ASL Portfolio';

function Loans() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="10px">
      <Header title="Loans" subtitle="Long Term Secured Loans." />
      <Box sx={{ width: '100%', marginTop: '-4px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          textTransform="none"
          aria-label="secondary tabs example"
          sx={{ gap: '10px' }}
        >
          <Tab
            value={0}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountTreeIcon sx={{ marginRight: '5px' }} /> 
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  Workflow
                </Typography> 
              </Box>
            }
          />
          <Tab
            value={1}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ListIcon sx={{ marginRight: '5px' }} />
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  Long Term Secured
                </Typography>
              </Box>
            }
          />
          <Tab
            value={2}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ChatIcon sx={{ marginRight: '5px' }} /> 
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  Message Templates
                </Typography> 
              </Box>
            }
          />
          <Tab
            value={3}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SettingsIcon sx={{ marginRight: '5px' }} /> 
              <Typography variant="h6" fontWeight="600" textTransform="none">
                  Loan Settings
              </Typography>
          </Box>
           }
          />
           <Tab
            value={4}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AssessmentIcon sx={{ marginRight: '5px' }} /> 
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  ASL PortFolio
                </Typography> 
              </Box>
            }
          />
        </Tabs>
      </Box>
      {/* Render the corresponding component based on the selected tab */}
      {value === 0 && <Workflow />}
      {value === 1 && <DisbursedLoans />}
      {value === 2 && <LoanNotification />}
      {value === 3 && <LoanConfiguration />}
      {value === 4 && <AslPortfolio />}
    </Box>
  );
}

export default Loans;