import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import './FinanceChart.css';

const PersonalFinanceChart = ({ monthlyPersonalFinance }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!monthlyPersonalFinance) {
    return null; 
  }

  // Extracting data for the chart
  const months = monthlyPersonalFinance.map(item => item.month);
  const loans = monthlyPersonalFinance.map(item => item.loans);
  const mshwari = monthlyPersonalFinance.map(item => item.mshwari);
  const savings = monthlyPersonalFinance.map(item => item.savings);
  const repayment = monthlyPersonalFinance.map(item => item.repayment);

  // Chart options
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          colors: colors.grey[100],
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: colors.grey[100],
        }
      }
    },
    legend: {
      position: 'top',
      labels: {
        colors: colors.grey[100],
      }
    },
    fill: {
      opacity: 1,
    },
  };

  // Chart series data
  const series = [
    {
      name: 'Loans',
      data: loans,
    },
    {
      name: 'M-Shwari',
      data: mshwari,
    },
    {
      name: 'Savings',
      data: savings,
    },
    {
      name: 'Repayments',
      data: repayment,
    },
  ];

  return (
    <div style={{ width: '95%', paddingTop: '15px' }}> 
      <Chart options={options} series={series} type="bar" height={245} />
    </div>
  );
};

export default PersonalFinanceChart;
