import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from "@mui/material";
import { tokens } from '../../../theme';

const Gauge = ({ height, value }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [fontSize, setFontSize] = useState(48);

  useEffect(() => {
    if (height < 200) {
      setFontSize(24);
    } else if (height < 300) {
      setFontSize(36);
    } else {
      setFontSize(48);
    }
  }, [height]);

  const percentage = value * 100;

  const getScoreText = (score) => {
    if (score >= 0.81) return 'Excellent!';
    if (score >= 0.66) return 'Very Good';
    if (score >= 0.56) return 'Good';
    if (score >= 0.41) return 'Fair';
    return 'Poor!';
  };

  const getColor = (text) => {
    switch (text) {
      case 'Excellent!':
        return '#6DD400';
      case 'Very Good':
        return '#00A76F';
      case 'Good':
        return '#5BE49B';
      case 'Fair':
        return '#FFAB00';
      case 'Poor!':
        return '#B71D18';
      default:
        return '#6DD400';
    }
  };

  const scoreText = getScoreText(value);
  const textColor = getColor(scoreText);

  const options = {
    chart: {
      height: height,
      type: "radialBar",
    },
    series: [percentage],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
          background: colors.primary[500],
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            color: colors.grey[100],
            fontSize: `${fontSize}px`,
            offsetY: 10,
            formatter: function (val) {
              return (val / 100).toFixed(2);
            },
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: ["#6DD400"],
        stops: [0, 50, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Progress"],
  };

  return (
    <div style={{ position: 'relative' }}>
      <Chart
        options={options}
        series={options.series}
        type="radialBar"
        width="100%"
      />
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, 50%)', textAlign: 'center', color: textColor, fontWeight: 'bold' }}>
        {scoreText}
      </div>
    </div>
  );
};

export default Gauge;
