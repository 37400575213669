import { useState, useEffect } from 'react';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Cookies from 'js-cookie';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import avatarImage from '../../../images/avatar1.jpg';

const ImgStyled = styled('img')(({ theme }) => ({
  width: 120,
  height: 120,
  marginRight: theme.spacing(6.25),
  borderRadius: theme.shape.borderRadius
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center'
  }
}));

const ResetButtonStyled = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(4.5),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0,
    textAlign: 'center',
    marginTop: theme.spacing(4)
  }
}));

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://carren.cc/">
        Carren Technologies Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const TabAccount = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = () => {
    setLoading(true);
    setError(null);
    
    const token = Cookies.get('accessToken');
    if (!token) {
      setError('JWT token is missing');
      setLoading(false);
      return;
    }
    
    // Now continue with fetching user details using the token...
    // You can use the token to make authenticated requests to your backend API

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch user details');
      }
      return response.json();
    })
    .then(data => {
      setUserDetails(data);
    })
    .catch(error => {
      setError(error.message);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  return (
    <Box mt="10px" backgroundColor={colors.primary[400]} justifyContent="space-between">
      <CardContent>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : userDetails ? (
          <form>
            <Grid container spacing={7}>
              <Grid item xs={12} sm={6} mt={3}>
                <TextField fullWidth label='First Name' value={userDetails.firstName} readOnly />
              </Grid>
              <Grid item xs={12} sm={6} mt={3}>
                <TextField fullWidth label='Last Name' value={userDetails.lastName} readOnly />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth type='email' label='Email' value={userDetails.email} readOnly />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Role' value={userDetails.role} readOnly />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select label='Status' value={userDetails.status} disabled>
                    <MenuItem value='active'>Active</MenuItem>
                    <MenuItem value='inactive'>Inactive</MenuItem>
                    <MenuItem value='pending'>Pending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Company' value={userDetails.company} readOnly />
              </Grid>
              <Grid item xs={12}>
             </Grid>
            </Grid>
          </form>
        ) : (
          <Typography>No user details found</Typography>
        )}
      </CardContent>
      <Copyright sx={{ mt: 2, mb: 2 }} />
    </Box>
  );
};

export default TabAccount;
