import React, { useState } from "react";
import {
  Box,
  TextField,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Select,
  MenuItem,
  useTheme,
  FormControl,
  InputLabel,
  FormHelperText,
  IconButton,
  Tooltip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Header from "../../components/Header";
import CloseIcon from '@mui/icons-material/Close';
import { fetchWithAuth } from "../../auth";
import { tokens } from "../../theme";

const CustomerKYC = React.memo(({ values, errors, touched, handleBlur, handleChange, isNonMobile }) => {
  const [isValidId, setIsValidId] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const StyledTypography = ({ children }) => (
    <Typography sx={{ mb: 1, fontWeight: 500, fontSize: "1rem", color: colors.grey[100] }}>
      {children}
    </Typography>
  );

  const handleIdEnter = async (e) => {
    if (e.key === "Enter") {
      try {
        const payload = {
          idnumber: values.idNumber,
        };

        const response = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/verify-id`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify(payload), 
        });

        if (response?.data?.valid) {
          const {
            first_name,
            last_name,
            other_name,
            gender,
            dob,
            citizenship,
            id_number,
            serial_no,
            valid,
          } = response.data;

          handleChange({ target: { name: "firstName", value: first_name } });
          handleChange({ target: { name: "lastName", value: last_name } });

          setIsValidId(valid);
          setUserDetails({
            first_name,
            last_name,
            other_name,
            gender,
            dob,
            citizenship,
            id_number,
            serial_no,
            valid,
          });
        } else {
          throw new Error(response?.message || "Invalid ID");
        }
      } catch (error) {
        console.error("Failed to fetch ID details:", error);
        setIsValidId(false);
      }
    }
  };

  const handleViewClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="First Name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.firstName}
          name="firstName"
          error={!!touched.firstName && !!errors.firstName}
          helperText={touched.firstName && errors.firstName}
          sx={{ gridColumn: "span 2" }}
          InputProps={{
            endAdornment: isValidId && <CheckCircleIcon color="secondary" />,
          }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Last Name"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.lastName}
          name="lastName"
          error={!!touched.lastName && !!errors.lastName}
          helperText={touched.lastName && errors.lastName}
          sx={{ gridColumn: "span 2" }}
          InputProps={{
            endAdornment: isValidId && <CheckCircleIcon color="secondary" />,
          }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          name="email"
          error={!!touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          sx={{ gridColumn: "span 4" }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="tel"
          label="Phone Number"
          onBlur={handleBlur}
          onChange={(e) => {
            const formattedPhoneNumber = e.target.value.replace(/[^\d]/g, "").slice(0, 10);
            handleChange({
              target: {
                name: "phoneNumber",
                value: formattedPhoneNumber,
              },
            });
          }}
          value={values.phoneNumber}
          name="phoneNumber"
          error={!!touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="ID Number"
          onBlur={handleBlur}
          onChange={(e) => {
            const formattedIdNumber = e.target.value.replace(/[^\d]/g, "");
            handleChange({
              target: {
                name: "idNumber",
                value: formattedIdNumber,
              },
            });
          }}
          value={values.idNumber}
          name="idNumber"
          error={!!touched.idNumber && !!errors.idNumber}
          helperText={touched.idNumber && errors.idNumber}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          onKeyDown={handleIdEnter}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          InputProps={{
            endAdornment: (
              <>
                {isValidId && <CheckCircleIcon color="secondary" />}
                {isValidId && (
                  <Button onClick={handleViewClick} color="secondary" size="small">
                    View
                  </Button>
                )}
              </>
            ),
          }}
        />
      </Box>
      <Divider sx={{ my: 3 }} />
      <Header subtitle="Next of Kin Details:" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
         <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Next of Kin First Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.first_name}
            name="first_name"
            error={!!touched.first_name && !!errors.first_name}
            helperText={touched.first_name && errors.first_name}
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Next of Kin Last Name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.last_name}
            name="last_name"
            error={!!touched.last_name && !!errors.last_name}
            helperText={touched.last_name && errors.last_name}
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.next_of_kin_email}
            name="next_of_kin_email"
            error={!!touched.next_of_kin_email && !!errors.next_of_kin_email}
            helperText={touched.next_of_kin_email && errors.next_of_kin_email}
            sx={{ gridColumn: "span 4" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="tel"
            label="Phone Number"
            onBlur={handleBlur}
            onChange={(e) => {
              const formattedphone_number = e.target.value.replace(/[^\d]/g, '').slice(0, 10);
              handleChange({
                target: {
                  name: 'phone_number',
                  value: formattedphone_number,
                },
              });
            }}
            value={values.phone_number}
            name="phone_number"
            error={!!touched.phone_number && !!errors.phone_number}
            helperText={touched.phone_number && errors.phone_number}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="ID Number"
            onBlur={handleBlur}
            onChange={(e) => {
            const formattedid_number = e.target.value.replace(/[^\d]/g, '');
              handleChange({
                target: {
                  name: 'id_number',
                  value: formattedid_number,
                },
              });
            }}
            value={values.id_number}
            name="id_number"
            error={!!touched.id_number && !!errors.id_number}
            helperText={touched.id_number && errors.id_number}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              step: '1',
              min: '0',
              style: { appearance: 'textfield' },
            }}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          />
          <FormControl fullWidth variant="filled" error={!!touched.relationship && !!errors.relationship} sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}>
            <InputLabel>Relationship</InputLabel>
              <Select
                label="Relationship"
                onBlur={handleBlur}
                onChange={(e) => {
                handleChange(e);
                  if (e.target.value === 'other') {
                    values.specify_relationship = '';
                  }
                }}
                value={values.relationship}
                name="relationship"
              >
                <MenuItem value="spouse">Spouse</MenuItem>
                <MenuItem value="sibling">Sibling</MenuItem>
                <MenuItem value="parent">Parent</MenuItem>
                <MenuItem value="guardian">Guardian</MenuItem>
                <MenuItem value="child">Child</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
                {touched.relationship && errors.relationship && (
                  <FormHelperText>{errors.relationship}</FormHelperText>
                )}
          </FormControl>
            {values.relationship === 'other' && (
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Specify Relationship"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.specify_relationship}
                name="specify_relationship"
                error={!!touched.specify_relationship && !!errors.specify_relationship}
                helperText={touched.specify_relationship && errors.specify_relationship}
                sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
              />
            )}
      </Box>
      <Divider sx={{ my: 3 }} />
      {/* Dialog to display user details */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: colors.primary[500],
            maxWidth: "480px",
          },
        }}
      >
        <DialogTitle variant="h5" fontWeight="600" color={colors.greenAccent[400]}>
          Verification Result - National ID
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          {userDetails ? (
            <Box>
              <StyledTypography>
                <strong>First Name:</strong> {userDetails.first_name}
              </StyledTypography>
              <StyledTypography>
                <strong>Last Name:</strong> {userDetails.last_name}
              </StyledTypography>
              <StyledTypography>
                <strong>Other Name:</strong> {userDetails.other_name}
              </StyledTypography>
              <StyledTypography>
                <strong>Gender:</strong> {userDetails.gender}
              </StyledTypography>
              <StyledTypography>
                <strong>Date of Birth:</strong> {userDetails.dob}
              </StyledTypography>
              <StyledTypography>
                <strong>Citizenship:</strong> {userDetails.citizenship}
              </StyledTypography>
              <StyledTypography>
                <strong>ID Number:</strong> {userDetails.id_number}
              </StyledTypography>
              <StyledTypography>
                <strong>Serial Number:</strong> {userDetails.serial_no}
              </StyledTypography>
              <StyledTypography>
                <strong>Valid:</strong> {userDetails.valid === true ? "TRUE" : "FALSE"}
              </StyledTypography>
            </Box>
          ) : (
            <StyledTypography>No user details available.</StyledTypography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
});

export default CustomerKYC;
