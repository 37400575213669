import React from 'react';
import { Avatar, Typography, CardContent, Divider } from '@mui/material';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const ClientOverview = ({ customer }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <CardContent>
        <Typography variant="h4" component="div" fontWeight="600" gutterBottom>
          Client Overview
        </Typography>
      </CardContent>
      <Divider />
      <CardContent style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt="Customer Avatar" style={{ marginRight: '20px', backgroundColor: 'secondary.main' }}>
          {customer.initials}
        </Avatar>
        <div>
          <Typography variant="h5" component="div" fontWeight="600">
            {`${customer.first_name} ${customer.last_name}`}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Phone: {customer.phone_number}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Email: {customer.email}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            ID Number: {customer.id_number}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Date of Appraisal: {customer.appraisal_date}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Loan Status: {customer.loan_status}
          </Typography>
        </div>
      </CardContent>
    </>
  );
};

export default ClientOverview;
