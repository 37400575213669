import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Snackbar,
  Typography,
  Card,
  CardContent,
  Tooltip,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NewLoanProduct from './NewLoanProduct';
import MuiAlert from '@mui/material/Alert';
import LoanSettings from './loanSettings';
import Header from '../../../components/Header';
import { fetchWithAuth } from '../../../auth';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoanConfiguration = () => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [interestType, setInterestType] = useState('reducing balance');
  const [multipleBorrowings, setMultipleBorrowings] = useState(false);
  const [products, setProducts] = useState([]);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const fetchLoanProducts = async () => {
    try {
      const data = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/loan-products`);
      setProducts(data);
    } catch (error) {
      console.error('Error fetching loan products:', error);
    }
  };

  useEffect(() => {
    fetchLoanProducts();
  }, []);

  const handleAddProduct = async (values) => {
    const formattedData = {
      product_name: values.productName,
      description: values.description,
      loan_tenor: values.loanTenor,
      interest_rate: values.interestRate,
      processing_fees: values.processingFees,
      penalty_rate: values.penaltyRate,
      mode_of_interest: values.modeOfInterest,
      interest_type: interestType,
      multiple_borrowings: multipleBorrowings,
      conditions: values.conditions,
    };
  
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/add_loan_product`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formattedData,
      });
  
      if (response) {
        setProducts([...products, response.loan_product]);
        setSnackbarMessage('Product Added Successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setOpenAddDialog(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSnackbarMessage('Unauthorized: You do not have permission to perform this operation.');
      } else {
        setSnackbarMessage('Error adding product.');
      }
      setSnackbarSeverity('error'); 
      setSnackbarOpen(true);
      setOpenAddDialog(false);
    }
  };  

  const handleEditProduct = async (values) => {
    const formattedData = {
      product_name: values.productName,
      description: values.description,
      loan_tenor: values.loanTenor,
      interest_rate: values.interestRate,
      processing_fees: values.processingFees,
      penalty_rate: values.penaltyRate,
      mode_of_interest: values.modeOfInterest,
      conditions: values.conditions,
      interest_type: interestType,
      multiple_borrowings: multipleBorrowings,
    };

    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/loan-product/${values.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formattedData,
      });
      if (response) {
        setProducts(products.map(p => (p.id === values.id ? response.loan_product : p)));
        setSnackbarMessage('Product Updated Successfully!');
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        setOpenEditDialog(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSnackbarMessage('Unauthorized: You do not have permission to perform this operation.');
      } else {
        setSnackbarMessage('Error adding product.');
      }
      setSnackbarSeverity('error'); 
      setSnackbarOpen(true);
      setOpenEditDialog(false);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/loan-product/${id}`, {
        method: 'DELETE',
      });
      setProducts(products.filter(p => p.id !== id));
      setSnackbarMessage('Product Deleted Successfully!');
      setSnackbarOpen(true);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 403) {
        setSnackbarMessage('Unauthorized: You do not have permission to delete this product.');
      } else {
        setSnackbarMessage('Failed to delete product. Please try again.');
      }
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      setDeleteDialogOpen(false);
    }
  }; 

  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setDeleteDialogOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    { field: 'product_name', headerName: 'Product Name', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 2 },
    { field: 'interest_rate', headerName: 'Interest Rate', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                setSelectedProduct(params.row);
                setOpenEditDialog(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteClick(params.row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];  

  const rows = products.map(product => ({
    id: product.id,
    product_name: product.product_name,
    description: product.description,
    interest_rate: product.interest_rate,
    loan_tenor: product.loan_tenor,
    penalty_rate: product.penalty_rate,
    processing_fees: product.processing_fees,
    conditions: product.conditions
    }));

  return (
    <Box mt={2} display="flex" flexDirection="column" gap={2}>
      {/* Headers for the settings and loan products */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={-3}>
        <Header subtitle="Loan Configuration Options:" />
      </Box>

      <Grid container spacing={2}>
        {/* Settings Panel */}
        <Grid item xs={12} md={4} lg={3}>
          <LoanSettings
            interestType={interestType}
            setInterestType={setInterestType}
            multipleBorrowings={multipleBorrowings}
            setMultipleBorrowings={setMultipleBorrowings}
          />
        </Grid>

        {/* DataGrid Panel */}
        <Grid item xs={12} md={8} lg={9}>
          <Card>
            <CardContent
              sx={{
                p: 2,
                backgroundColor: colors.primary[500],
                borderRadius: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '1rem',
                }}
              >
                <Typography variant="h6" fontWeight="600" sx={{ color: colors.grey[100] }}>
                  My Registered Loan Products:
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenAddDialog(true)}
                >
                  Add New Loan Product
                </Button>
              </Box>
              <Box sx={{ height: '65vh', width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10, 20]}
                  checkboxSelection
                  disableSelectionOnClick
                  m="5px 0 0 0"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Add Product Dialog */}
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        PaperProps={{ sx: { backgroundColor: colors.primary[500] } }}
        fullWidth
      >
        <DialogTitle variant="h5" fontWeight="600" sx={{ color: colors.grey[100] }}>
          Register Loan Product:
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              onClick={() => setOpenAddDialog(false)}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <NewLoanProduct
          initialValues={{
            productName: '',
            description: '',
            loanTenor: '',
            interestRate: '',
            processingFees: '',
            penaltyRate: '',
            modeOfInterest: 'yearly',
          }}
          handleSubmit={handleAddProduct}
          isEditing={false}
          handleClose={() => setOpenAddDialog(false)}
        />
      </Dialog>

      {/* Edit Product Dialog */}
      {selectedProduct && (
        <Dialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          PaperProps={{ sx: { backgroundColor: colors.primary[500] } }}
          fullWidth
        >
          <DialogTitle variant="h5" fontWeight="600" sx={{ color: colors.grey[100] }}>
            Edit Loan Product:
            <Tooltip title="Close">
              <IconButton
                aria-label="close"
                onClick={() => setOpenEditDialog(false)}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </DialogTitle>
          <NewLoanProduct
            initialValues={{
              id: selectedProduct.id,
              productName: selectedProduct.product_name,
              description: selectedProduct.description,
              loanTenor: selectedProduct.loan_tenor,
              interestRate: selectedProduct.interest_rate,
              processingFees: selectedProduct.processing_fees,
              penaltyRate: selectedProduct.penalty_rate,
              modeOfInterest: selectedProduct.mode_of_interest || 'yearly',
            }}
            handleSubmit={handleEditProduct}
            isEditing={true}
            handleClose={() => setOpenEditDialog(false)}
          />
        </Dialog>
      )}

      {/* Delete Product Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{ sx: { backgroundColor: colors.primary[500] } }}
        fullWidth
      >
        <DialogTitle variant="h5" fontWeight="600" sx={{ color: colors.grey[100] }}>
          Delete Product?
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: colors.grey[100] }}>Proceed to delete this product?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteProduct(productToDelete.id)}
            color="secondary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoanConfiguration;

