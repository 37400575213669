import React from 'react';
import { Box, Typography, Button, Avatar, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  overflow: 'hidden',
  fontSize: '0.75rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75),
}));

const MenuItemSubtitle = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '0.65rem',
  color: theme.palette.text.secondary,
}));

const ApproverNotifications = ({ notification, onClick, markAsRead }) => {
  const getInitials = (name) => {
    if (!name) return '';
    const names = name.trim().split(' ');
    const initials = names.map((n) => n[0]).join('');
    return initials.toUpperCase();
  };

  const sender = notification.sender || notification.content.sender;
  const note = notification.note || notification.content.note;

  return (
    <MenuItem onClick={onClick} sx={{ padding: '12px 16px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Avatar
          sx={{
            bgcolor: 'secondary.main',
            color: 'common.white',
            mr: 2, 
          }}
        >
          {getInitials(sender)}
        </Avatar>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <MenuItemTitle>
            {sender} has sent a report for approval.
          </MenuItemTitle>
          <MenuItemSubtitle variant="body2">
            {note}
          </MenuItemSubtitle>
        </Box>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            markAsRead(notification);
            onClick();
          }}
        >
          <Typography variant="caption">View</Typography>
        </Button>
      </Box>
    </MenuItem>
  );
};

export default ApproverNotifications;
