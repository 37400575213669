import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Dialog, DialogContent, CircularProgress } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import SettingsIcon from '@mui/icons-material/Settings';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from 'js-cookie';
import { fetchWithAuth } from "../../auth";

const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => {
        setSelected(title);
        if (onClick) onClick();
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      {to && <Link to={to} />}
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
        method: "POST",
      });

      Cookies.remove('accessToken', { domain: 'localhost' });
      Cookies.remove('refreshToken', { domain: 'localhost' });
      window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/signin`;
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLogoutDialogOpen(false);
    }
  };

  const initiateLogout = () => {
    setLogoutDialogOpen(true);
    setTimeout(handleLogout, 2000);
  };

  return (
    <div style={{ display: "flex" }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          overflowY: 'auto',
          width: isCollapsed ? '80px' : '275px',
          transition: 'width 0.3s ease',
          zIndex: 1000, // Ensure the sidebar stays above other content
          backgroundColor: colors.primary[400],
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: "#868dfb !important",
          },
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
          },
        }}               
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            {/* LOGO AND MENU ICON */}
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                {!isCollapsed && (
                  <Typography variant="h3" fontWeight="bold" color={colors.grey[100]}>
                    Carren.
                  </Typography>
                )}
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            </MenuItem>

            {!isCollapsed && (
              <Box mb="25px">
                {/* Your user profile section */}
              </Box>
            )}

            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Client
              </Typography>
              <Item
                title="Credit Appraisal"
                to="/NewAppraisal"
                icon={<AddCardIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Customers"
                to="/Customers"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Reports"
                to="/CompletedAppraisals"
                icon={<CreditScoreIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Business
              </Typography>
              <Item
                title="Loans"
                to="/loans"
                icon={<FormatListBulletedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Settings"
                to="/settings"
                icon={<SettingsIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                User
              </Typography>
              <Item
                title="Profile"
                to="/Profile"
                icon={<AccountBoxIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Log Out"
                to="#"
                icon={<LogoutIcon />}
                selected={selected}
                setSelected={setSelected}
                onClick={initiateLogout}
              />
            </Box>
          </Menu>
        </ProSidebar>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: isCollapsed ? '80px' : '275px',
        }}
      >
        {/* Your main content goes here */}
      </Box>
      <Dialog
        open={logoutDialogOpen}
        PaperProps={{ sx: { backgroundColor: colors.primary[600] } }}
      >
        <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={20} sx={{ marginRight: 2 }} />
          <Typography>Logging out...</Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Sidebar;
