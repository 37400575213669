import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import axios from "axios";
import Header from "../../../../components/Header";

const RecreationSummary = ({ customerId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [recreationSummary, setRecreationSummary] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      valueGetter: (params) => {
        switch (params.value) {
          case "Gambling Spend":
            return "Gambling Spend";
          case "Total Alcohol Spend":
            return "Total Alcohol Spend";
          case "Total Restaurant Spend":
            return "Total Restaurant Spend";
          default:
            return params.value;
        }
      },
    },
    { field: "transaction_counts", headerName: "No. of Transactions", flex: 1 },
    {
        field: "average_amount",
        headerName: "Average Transaction Amount",
        flex: 1,
        valueFormatter: (params) => {
          if (typeof params.value === 'number') {
            return `Ksh ${params.value.toFixed(2).toLocaleString()}`;
          }
          return "na";
        }      
      },
      {
        field: "total_amount",
        headerName: "Total Transaction Amount",
        flex: 1,
        valueFormatter: (params) => {
          if (typeof params.value === 'number') {
            return `Ksh ${params.value.toFixed(2).toLocaleString()}`;
          }
          return "na";
        }      
      },
  ];

  useEffect(() => {
    // Fetch recreation summary data from backend when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/recreation-spend-summary/${customerId}`);
        const categoryNames = ['Total Alcohol Spend', 'Total Restaurant Spend', 'Gambling Spend'];

        const transformedData = response.data.category_totals.map((total, index) => {
            const categoryName = categoryNames[index];
            return {
              id: index + 1,
              category: categoryName,
              transaction_counts: response.data.category_transaction_counts[index],
              average_amount: parseFloat(response.data.category_average_amounts[categoryName]),
              total_amount: total,
            };
          });
          
  
        setRecreationSummary(transformedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching recreation summary data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);  
  
  return (
    <Box m="10px">
      <Header subtitle="Lifestyle:" />
      <Box
        m="10px 0 0 0"
        height="63vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={recreationSummary}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default RecreationSummary;

