import React from 'react';
import { CardContent, Divider, Grid, TextField, Typography, useTheme} from "@mui/material";
import { tokens } from '../../../theme';

const CustomerBankAccount = ({ bankDetails }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <CardContent>
      <Typography variant="h6" color={colors.grey[100]}>
        Bank Account information
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Bank Name"
            type="text"
            fullWidth
            variant="outlined"
            value={bankDetails ? bankDetails.bankName : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Account Number"
            type="text"
            fullWidth
            variant="outlined"
            value={bankDetails ? bankDetails.branch : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Account Name"
            type="text"
            fullWidth
            variant="outlined"
            value={bankDetails ? bankDetails.accountName : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Account Number"
            type="text"
            fullWidth
            variant="outlined"
            value={bankDetails ? bankDetails.accountNumber : ""}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default CustomerBankAccount;
