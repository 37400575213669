import * as React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ArrowLeft as ArrowLeftIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom'; // Assuming you're using React Router

const NotFoundPage = () => {
  return (
    <Box component="main" sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', minHeight: '100vh' }}>
      <Stack spacing={3} sx={{ alignItems: 'center', maxWidth: 'md' }}>
        <Box>
          <img
            alt="Under development"
            src="/assets/error-404.png"
            style={{ display: 'inline-block', height: 'auto', maxWidth: '100%', width: '400px' }}
          />
        </Box>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          404: The page you are looking for isn't here
        </Typography>
        <Typography color="text.secondary" variant="body1" sx={{ textAlign: 'center' }}>
          You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation
        </Typography>
        <Button
          component={RouterLink}
          to="/" // Adjust the path as needed
          startIcon={<ArrowLeftIcon />}
          variant="contained"
          color="secondary"
        >
          Go back to home
        </Button>
      </Stack>
    </Box>
  );
}

export default NotFoundPage;
