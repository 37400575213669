import React from 'react';
import Chart from "react-apexcharts";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

const DepositsLineChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  // Check if data is defined
  if (!data) {
    return <div>No data available</div>;
  }

  // Check if data is an array
  if (!Array.isArray(data)) {
    return <div>Error: Data is not in the expected format</div>;
  }

  // Check if data array is empty
  if (data.length === 0) {
    return <div>No data to display</div>;
  }

  return (
    <div>
      {data.map((recipient, index) => (
        <div key={index} style={{ marginTop: '35px' }}>
          <Typography variant="h6" color={colors.greenAccent[400]}>
            {recipient.recipient.toUpperCase()}
          </Typography>
          <Chart
            options={{
              chart: {
                height: '100%',
                type: 'line',
                zoom: {
                  enabled: false
                }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth',
                width: 2,
              },
              grid: {
                row: {
                  colors: [colors.primary[400], 'transparent'],
                },
              },
              xaxis: {
                categories: recipient.transactions_by_month.map(monthData => monthData.month).reverse(),
                labels: {
                  style: {
                    colors: colors.grey[100],
                  }
                }
              },
              yaxis: {
                labels: {
                  style: {
                    colors: colors.grey[100],
                  }
                }
              },
              plotOptions: {
                line: {
                  colors: [colors.greenAccent[600]],
                  background: colors.primary[400], 
                }
              },
              menu: {
                background: colors.primary[400],
                color: colors.greenAccent[400],
              }, 
              tooltip: {
                style: {
                  fontSize: '10px', // Adjust font size of tooltip text
                  background: colors.primary[400], // Set background color of tooltip
                  color: colors.grey[100], // Set text color of tooltip
                }
              }
            }}
            series={[{
              name: recipient.recipient,
              data: recipient.transactions_by_month.map(monthData => monthData.total_amount)
            }]}
            type="line"
            height={215}
          />
        </div>
      ))}
    </div>
  );
};

export default DepositsLineChart;
