import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { AccountBalanceWallet as FinancialSummaryIcon, BarChart as InsightsIcon, CreditScore as CreditScoreIcon, Description as ReportIcon } from '@mui/icons-material';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header';
import FinancialSummary from './FinancialSummary';
import Insights from './Insights';
import CreditScore from './CreditScore';
import Report from './Report';

function CreditAppraisal({ customerId }) {
  const [value, setValue] = useState(0);
  const [customerDetails, setCustomerDetails] = useState(null);
  const theme = useTheme();
  const classes = tokens(theme);
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    // Fetch customer details from the API when the component mounts
    const fetchCustomerDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/customer-details/${customerId}`);
        const data = await response.json();
        setCustomerDetails(data);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      }
    };
  
    fetchCustomerDetails();
  }, [customerId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="20px">
      <Header
        title="CREDIT APPRAISALS."
        subtitle={customerDetails ? `Credit Appraisal for ${customerDetails.name} (${customerDetails.phone_number})` : "Loading..."}
      />
      {customerDetails && (
        <Box sx={{ marginTop: '-25px' }}>
          <Typography variant="subtitle1" gutterBottom>
            Statement Period: {customerDetails.start_date} to {customerDetails.end_date}
          </Typography>
        </Box>
      )}
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          textTransform="none"
          aria-label="secondary tabs example"
          sx={{ gap: '10px' }} // Add space between tabs
        >
          <Tab
            value={0}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FinancialSummaryIcon sx={{ marginRight: '5px' }} /> {/* Add space between icon and label */}
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  Financial Summary
                </Typography>
              </Box>
            }
          />
          <Tab
            value={1}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InsightsIcon sx={{ marginRight: '5px' }} /> {/* Add space between icon and label */}
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  Insights
                </Typography>
              </Box>
            }
          />
          <Tab
            value={2}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CreditScoreIcon sx={{ marginRight: '5px' }} /> {/* Add space between icon and label */}
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  Credit Score
                </Typography>
              </Box>
            }
          />
          <Tab
            value={3}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ReportIcon sx={{ marginRight: '5px' }} /> {/* Add space between icon and label */}
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  Report
                </Typography>
              </Box>
            }
          />
        </Tabs>
      </Box>
      {/* Render the corresponding component based on the selected tab */}
      {value === 0 && <FinancialSummary customerId={customerId} />}
      {value === 1 && <Insights customerId={customerId} />}
      {value === 2 && <CreditScore customerId={customerId} />}
      {value === 3 && <Report customerId={customerId} />}
    </Box>
  );
}

export default CreditAppraisal;
