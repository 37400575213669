import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import './ShoppingChart.css';

const ShoppingChart = ({ monthlyShopping }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!monthlyShopping) {
    return null; // or render a loading indicator
  }

  // Extracting data for the chart
  const months = monthlyShopping.map(item => item.month);
  const retail = monthlyShopping.map(item => item.retail);
  const health = monthlyShopping.map(item => item.health);
  const fmcg = monthlyShopping.map(item => item.fmcg);
  const food = monthlyShopping.map(item => item.food);

  // Chart options
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          colors: colors.grey[100],
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: colors.grey[100],
        }
      }
    },
    legend: {
      position: 'top',
      labels: {
        colors: colors.grey[100],
      }
    },
    fill: {
      opacity: 1,
    },
  };

  // Chart series data
  const series = [
    {
      name: 'Retail',
      data: retail,
    },
    {
      name: 'Health',
      data: health,
    },
    {
      name: 'Fmcg',
      data: fmcg,
    },
    {
      name: 'Food',
      data: food,
    },
  ];

  return (
    <div style={{ width: '95%', paddingTop: '15px' }}> 
      <Chart options={options} series={series} type="bar" height={245} />
    </div>
  );
};

export default ShoppingChart;
