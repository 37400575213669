import React, { useState, useEffect } from "react";
import { Box, Button, Checkbox } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { fetchWithAuth } from "../../auth";

import Header from "../../components/Header";
import CreditAppraisal from "../Credit Appraisal";

const ViewAppraisal = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [viewAppraisal, setViewAppraisal] = useState(false); 
  const [showHeader, setShowHeader] = useState(true);

  const columns = [
    {
      field: "selected",
      headerName: (
        <Checkbox
          checked={selectAll}
          onChange={(event) => handleSelectAll(event.target.checked)}
        />
      ),
      flex: 0.5,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(event) =>
            handleSelectCustomer(params.row.id, event.target.checked)
          }
        />
      ),
    },
    { field: "first_name", headerName: "First Name", flex: 1 },
    { field: "last_name", headerName: "Last Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone_number", headerName: "Phone Number", flex: 1 },
    { field: "id_number", headerName: "ID Number", flex: 1 },
    {
      field: "appraisal_date",
      headerName: "Date of Appraisal",
      flex: 1,
      renderCell: (params) => (
        params.value ? new Date(params.value).toLocaleDateString('en-GB') : null
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          sx={{
            backgroundColor: colors.blueAccent[700],
            "&:hover": {
              backgroundColor: colors.myAccent[800],
            },
            color: colors.grey[100],
          }}
          onClick={() => handleViewAppraisal(params.row)}
        >
          View Appraisal
        </Button>
      ),
    },
  ];

  useEffect(() => {
    // Fetch customer data from backend when the component mounts
    const fetchData = async () => {
      try {
        const data = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/appraised-customers`);
        // Add 'selected' property to each customer object for checkbox selection
        const customersWithSelection = data.map((customer) => ({
          ...customer,
          selected: false,
        }));
        setCustomerData(customersWithSelection);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    const updatedCustomers = customerData.map((customer) => ({
      ...customer,
      selected: checked,
    }));
    setCustomerData(updatedCustomers);
  };

  const handleSelectCustomer = (id, checked) => {
    const updatedCustomers = customerData.map((customer) =>
      customer.id === id ? { ...customer, selected: checked } : customer
    );
    setCustomerData(updatedCustomers);
    setSelectAll(updatedCustomers.every((customer) => customer.selected));
  };

  const handleViewAppraisal = (customer) => {
    // Implement logic to view the appraisal for the selected customer
    // console.log("View Appraisal for:", customer);
    const customerId = customer.id; // Extract customer ID
    setViewAppraisal(true); // Set state to view the appraisal
    setShowHeader(false); // Hide the header
    setSelectedCustomerId(customerId); // Set the selected customer ID state
  };

  return (
    <Box m="20px">
      {showHeader && ( // Render header only if showHeader is true
        <Header
          title="CREDIT APPRAISALS."
          subtitle="List of Completed Appraisals per Registered Customer."
        />
      )}
      {!viewAppraisal && ( // Render Contacts component only if viewAppraisal is false
        <Box
          m="20px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            loading={loading}
            rows={customerData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      )}
      {viewAppraisal && <CreditAppraisal customerId={selectedCustomerId} />} {/* Render CreditAppraisal component when viewAppraisal is true */}
    </Box>
  );
};

export default ViewAppraisal;
