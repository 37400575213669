import React from 'react';
import { CardContent, Typography, Grid, Divider } from '@mui/material';

const LoanDetails = ({ loanDetails, loanRecommendation, colors }) => {
  return (
    <CardContent>
      <Typography variant="h5" component="div" fontWeight="600" gutterBottom>
        Loan Applied for vs Unsecured Loan Recommendation
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <CardContent>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Loan Amount Applied:
              <Typography component="span" fontWeight="555" color="#868dfb">
                ksh {Number(loanDetails.amount).toLocaleString()}
              </Typography>
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Loan Product:
              <Typography component="span" fontWeight="555" color="#868dfb">
                {loanDetails.product_name}
              </Typography>
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Loan Tenor (in months):
              <Typography component="span" fontWeight="555" color="#868dfb">
                {loanDetails.loan_tenor}
              </Typography>
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Interest Rate:
              <Typography component="span" fontWeight="555" color="#868dfb">
                {loanDetails.interest_rate}%
              </Typography>
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Processing Fees:
              <Typography component="span" fontWeight="555" color="#868dfb">
                ksh {Number(loanDetails.processing_fees).toLocaleString()}
              </Typography>
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Loan Application Date:
              <Typography component="span" fontWeight="555" color="#868dfb">
                {loanDetails.loan_application_date}
              </Typography>
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={1}>
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid item xs={6}>
          <CardContent>
            <Typography variant="h5" component="div" fontWeight="600" gutterBottom>
              Unsecured Loan Recommendation
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Unsecured Loan Amount:
              <Typography component="span" fontWeight="555" color={colors.greenAccent[400]}>
                ksh {Number(loanRecommendation.unsecured_loan_amount / 12).toLocaleString()}
              </Typography>
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Equated Monthly Installments (EMI):
              <Typography component="span" fontWeight="555" color={colors.greenAccent[400]}>
                ksh {Number(loanRecommendation.unsecured_loan_amount / loanDetails.loan_tenor).toLocaleString()}
              </Typography>
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Interest Rate:
              <Typography component="span" fontWeight="555" color={colors.greenAccent[400]}>
                {loanDetails.interest_rate}%
              </Typography>
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Loan Tenor (in months):
              <Typography component="span" fontWeight="555" color={colors.greenAccent[400]}>
                3
              </Typography>
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default LoanDetails;
