import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, Box , Tabs, Tab, Slide } from "@mui/material";
import { tokens } from "../../../../theme";
import MerchantPayments from './merchant payments';
import PaybillPayments from './paybill payments';

const useStyles = makeStyles((theme) => ({
  tab: {
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : 'inherit',
  },
  indicator: {
    backgroundColor: theme.palette.mode === 'dark' ? tokens('dark').myAccent[200] : tokens('light').myAccent[200],
  },
  appBarWrapper: {
    marginTop: theme.spacing(2), // Add margin top
  },
}));

const CustomerPayments = ({ customerId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); 

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); 
  };

  return (
    <Box className={classes.appBarWrapper}> {/* Added wrapper with margin */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          classes={{ indicator: classes.indicator }}
        >
          <Tab
            label="Merchant Payments"
            sx={{fontWeight: '600'}}
            className={classes.tab}
          />
          <Tab
            label="Paybill Payments"
            sx={{fontWeight: '600'}}
            className={classes.tab}
          />
        </Tabs>

      {/* Render MerchantPayments or PaybillPayments component based on selected tab */}
      <Slide direction="left" in={activeTab === 0} mountOnEnter unmountOnExit>
        <div>
          <MerchantPayments customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={activeTab === 1} mountOnEnter unmountOnExit>
        <div>
          <PaybillPayments customerId={customerId} />
        </div>
      </Slide>
    </Box>
  );
};

export default CustomerPayments;
