import React, { useState, useEffect } from 'react';
import { Box, Switch, FormControlLabel, Typography, Checkbox, Accordion, AccordionSummary, AccordionDetails, Button, CircularProgress, IconButton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import CreditScoreTree from "./CreditScoreTree";
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import { useTheme } from "@mui/material";

const ModelSettings = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    const subcategories = [
      {
        label: 'Utilities',
        key: 'utilities',
        subItems: [
          { label: 'Rent', key: 'utilities-rent' }, // Unique key for each subItem
          { label: 'Power and Water', key: 'utilities-powerAndWater' },
          { label: 'Fuel/Gas', key: 'utilities-fuelAndGas' },
          { label: 'Airtime, Data & TV', key: 'utilities-internetAndTV' },
        ],
      },
      { label: 'Injections',
        key: 'injections',
        subItems: [
            { label: 'Bank Accounts', key: 'banks' },
            { label: 'From Individuals', key: 'individuals' },
            { label: 'From PayBills', key: 'paybills' },
            { label: 'Agent Deposits', key: 'agent-deposits' },
          ],
      },
      { label: 'Recreation',
        key: 'recreation',
        subItems: [
            { label: 'Alcohol Spend', key: 'alcohol' },
            { label: 'Restaurant Spend', key: 'restaurant' },
            { label: 'Betting Spend', key: 'gambling' },
            { label: 'Betting Earnings', key: 'betting' },
          ],
      },
      { label: 'Shopping',
        key: 'shopping',
        subItems: [
            { label: 'Retail Spend', key: 'retail' },
            { label: 'FMCG Spend', key: 'fmcg' },
            { label: 'Health Spend', key: 'health' },
            { label: 'Food and Grocery', key: 'food' },
          ],
      },
      {
        label: 'Personal Finance',
        key: 'personalFinance',
        subItems: [
          { label: 'Loans', key: 'personalFinance-loans' },
          { label: 'Loan Repayments', key: 'personalFinance-repayments' },
          { label: 'Savings', key: 'personalFinance-savings' },
          { label: 'Mshwari', key: 'personalFinance-mshwari' },
        ],
      },
      { label: 'Dependants',
        key: 'dependants',
        subItems: [
            { label: 'Dependants Spend', key: 'dependants' },
        ],
      },
    ];  
  
    const [switchStates, setSwitchStates] = useState({
      utilities: true,
      dependants: true,
      recreation: true,
      shopping: true,
      personalFinance: true,
      injections: true,
    });
  
    const [checkboxStates, setCheckboxStates] = useState({
      'utilities-rent': true,
      'utilities-powerAndWater': true,
      'utilities-fuelAndGas': true,
      'utilities-internetAndTV': true,
      banks: true,
      individuals: true,
      paybills: true,
      "agent-deposits": true,
      alcohol: true,
      restaurant: true,
      gambling: true,
      betting: true,
      retail: true,
      fmcg: true,
      health: true,
      food: true,
      'personalFinance-loans': true,
      'personalFinance-repayments': true,
      'personalFinance-savings': true,
      'personalFinance-mshwari': true,
      dependants: true,
    });

    const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const newSwitchStates = {};
    for (const categoryKey in switchStates) {
      const subItems = subcategories.find(subcategory => subcategory.key === categoryKey)?.subItems;
      const allUnchecked = subItems && subItems.every(subItem => !checkboxStates[subItem.key]);
      newSwitchStates[categoryKey] = !allUnchecked;
    }
    setSwitchStates(newSwitchStates);
  }, [checkboxStates, subcategories, switchStates]);

  const handleSwitchChange = (key) => {
    setSwitchStates(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    if (!switchStates[key]) {
      const newCheckboxStates = { ...checkboxStates };
      const subItems = subcategories.find(subcategory => subcategory.key === key)?.subItems;
      subItems?.forEach(subItem => {
        newCheckboxStates[subItem.key] = false;
      });
      setCheckboxStates(newCheckboxStates);
    } else {
      const newCheckboxStates = { ...checkboxStates };
      const subItems = subcategories.find(subcategory => subcategory.key === key)?.subItems;
      subItems?.forEach(subItem => {
        newCheckboxStates[subItem.key] = true;
      });
      setCheckboxStates(newCheckboxStates);
    }
    setSaved(false);
  };

  const handleCheckboxChange = (key) => {
    setCheckboxStates(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    setSaved(false);
  };

  const handleSaveChanges = () => {
    setSaving(true);
    // Simulate saving process
    setTimeout(() => {
      setSaving(false);
      setSaved(true);
      // Reset button text after 2 seconds
      setTimeout(() => {
        setSaved(false);
      }, 2000);
    }, 2000);
  };

  return (
    <Box>
     <Box height={400}>
        <CreditScoreTree />
      </Box>
      <Box>
        <Typography
          variant="h6"
          fontWeight="600"
        >
          Customize Credit Scoring Model:
        </Typography>
        {subcategories.map(subcategory => (
          <Accordion 
            key={subcategory.key} 
            sx={{ backgroundColor: colors.primary[500] }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${subcategory.key}-content`}
              id={`${subcategory.key}-header`}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={switchStates[subcategory.key]}
                    onChange={() => handleSwitchChange(subcategory.key)}
                    color="success"
                  />
                }
                label={subcategory.label}
              />
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                {subcategory.subItems && subcategory.subItems.map(subItem => (
                  <li key={subItem.key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxStates[subItem.key]}
                          onChange={() => handleCheckboxChange(subItem.key)}
                          color="success"
                        />
                      }
                      label={subItem.label}
                    />
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box display="flex" justifyContent="flex-end" mt={2}>
            {saved ? (
                <Box display="flex" alignItems="center">
                <Typography variant="body2" color="success" mr={1}>Saved</Typography>
                <CheckCircleOutlineIcon color="success" />
                </Box>
            ) : (
                <Button
                variant="contained"
                color="secondary"
                onClick={handleSaveChanges}
                disabled={saving}
                >
                {saving ? <CircularProgress size={16} /> : "Apply Changes"}
                </Button>
            )}
        </Box>
      </Box>
      {/* <Box height={400} mt={3}>
        <CreditScoreTree />
      </Box> */}
    </Box>
  );
};

export default ModelSettings;