import { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme, Select, MenuItem, Tooltip } from "@mui/material";
import { tokens } from "../../theme";
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import TaskIcon from '@mui/icons-material/Task';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import StatBox from "../../components/StatBox";
import LoanedAmount from "../../components/LoanedAmount";
import ProgressCircle from "../../components/ProgressCircle";
import WeeklyCustomerCount from "../../components/WeeklyCustomerCount";
import AnnualCustomerChart from "../../components/AnnualCustomerChart";
import MonthlyCustomerChart from "../../components/MonthlyCustomerChart";
import { Link } from "react-router-dom";
import { fetchWithAuth } from "../../auth";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const [selectedPeriod, setSelectedPeriod] = useState('this year');
  const [selectedTimeline, setSelectedTimeline] = useState('this week');
  const [selectedCustomerPeriod, setSelectedCustomerPeriod] = useState('this week');
  const [mostRecentReports, setMostRecentReports] = useState([]);
  const navigate = useNavigate();

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const handleTimelineChange = (event) => {
    setSelectedTimeline(event.target.value);
  };

  const handleCustomerPeriodChange = (event) => {
    setSelectedCustomerPeriod(event.target.value);
  };

  const [customerStats, setCustomerStats] = useState({
    total_customers: 0,
    customers_appraisal_completed: 0,
    customers_appraisal_not_completed: 0
  });

  useEffect(() => {     
    fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/customersCount`)
      .then(data => {
        setCustomerStats({
          total_customers: data.total_customers,
          customers_appraisal_completed: data.customers_appraisal_completed,
          customers_appraisal_not_completed: data.customers_appraisal_not_completed
        });
      })
      .catch(error => console.error("Error fetching customer counts:", error));
  }, [location.search]);

  useEffect(() => {
    fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/most-recent-reports`)
      .then(data => {
        setMostRecentReports(data);
      })
      .catch(error => console.error("Error fetching most recent reports:", error));
  }, []);

  // Calculate percentages for progress
  const completedProgress = customerStats.total_customers > 0
    ? customerStats.customers_appraisal_completed / customerStats.total_customers
    : 0;

  const pendingProgress = customerStats.total_customers > 0
    ? customerStats.customers_appraisal_not_completed / customerStats.total_customers
    : 0;

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your Dashboard." />
        <Box>
          <Link to="/NewAppraisal" style={{ textDecoration: "none" }}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                "&:hover": {
                  backgroundColor: colors.myAccent[800],
                },
                fontSize: "14px",
                fontWeight: "545",
                padding: "10px 20px",
              }}
            >
              <AddCardRoundedIcon sx={{ mr: "10px" }} />
              New Appraisal
            </Button>
          </Link>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={customerStats.customers_appraisal_completed.toString()}
            subtitle={
              <span style={{ whiteSpace: 'pre-line' }}>
                Completed {'\n'} Appraisals
              </span>
            }
            progress={completedProgress.toFixed(2)}
            increase={`${(completedProgress * 100).toFixed(0)}%`}
            icon={
              <TaskIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={customerStats.customers_appraisal_not_completed.toString()}
            subtitle={
              <span style={{ whiteSpace: 'pre-line' }}>
                Pending {'\n'} Appraisals
              </span>
            }
            progress={pendingProgress.toFixed(2)}
            increase={`${(pendingProgress * 100).toFixed(0)}%`}
            icon={
              <PendingActionsIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={customerStats.total_customers.toString()}
            subtitle={
              <span style={{ whiteSpace: 'pre-line' }}>
                Total {'\n'} Customers
              </span>
            }
            progress="1.00"
            icon={
              <PeopleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={customerStats.total_customers.toString()}
            subtitle={
              <span style={{ whiteSpace: 'pre-line' }}>
                Total {'\n'} Projects
              </span>
            }
            progress="1.00"
            increase="100%"
            icon={
              <CalendarMonthIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="15px"
            p="0 15px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Total Loaned Amount:
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                ksh 0.00
              </Typography>
            </Box>
            <Box>
              <Select
                value={selectedPeriod}
                onChange={handlePeriodChange}
                sx={{
                  color: colors.greenAccent[500],
                  height: '30px',
                  width: '120px',
                }}
                variant="outlined"
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="this week">This Week</MenuItem>
                <MenuItem value="this month">This Month</MenuItem>
                <MenuItem value="this year">This Year</MenuItem>
              </Select>
            </Box>
          </Box>
          <LoanedAmount selectedPeriod={selectedPeriod} />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            position="sticky"
            top="0"
            zIndex="999"
            bgcolor={colors.primary[400]}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Most Recent Reports:
            </Typography>
            <Box>
              <Select
                value={selectedTimeline}
                onChange={handleTimelineChange}
                sx={{
                  color: colors.greenAccent[500],
                  height: '30px',
                  width: '85px',
                }}
                variant="outlined"
              >
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="this week">This Week</MenuItem>
                <MenuItem value="this month">This Month</MenuItem>
                <MenuItem value="this year">This Year</MenuItem>
              </Select>
            </Box>
          </Box>
          {mostRecentReports.map((report, i) => (
            <Box
              key={`${report.Id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {report.phone_number}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {`${report.first_name} ${report.last_name}`}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{report.appraisal_date}</Box>
              <Box>
                <Tooltip title="View" arrow>
                  <IconButton
                    onClick={() => navigate('/CreditAppraisal', { state: { customerId: report.id } })}
                  >
                    <NavigateNextOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))}
        </Box>
        {/* ROW 3 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="15px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="600"
              color={colors.grey[100]}
            >
              Customer Onboarding Trend:
            </Typography>

            {/* Select box for customer onboarding trend */}
            <Select
              value={selectedCustomerPeriod}
              onChange={handleCustomerPeriodChange}
              sx={{
                color: colors.greenAccent[500],
                height: '30px',
                width: '120px',
              }}
              variant="outlined"
            >
              <MenuItem value="this week">This Week</MenuItem>
              <MenuItem value="this month">This Month</MenuItem>
              <MenuItem value="this year">This Year</MenuItem>
            </Select>
          </Box>

          {/* Conditional rendering based on selectedCustomerPeriod */}
          {selectedCustomerPeriod === 'this year' ? (
            <AnnualCustomerChart />
          ) : selectedCustomerPeriod === 'this month' ? (
            <MonthlyCustomerChart />
          ) : (
            <WeeklyCustomerCount isDashboard={true} selectedPeriod={selectedCustomerPeriod} />
          )}
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="20px"
        >
          <Typography variant="h6" fontWeight="600">
            Approval Rates:
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
            <ProgressCircle size="125" />
            <Typography
              variant="h5"
              color={colors.greenAccent[500]}
              sx={{ mt: "15px" }}
            >
              0%
            </Typography>
            <Typography>Risk Approval rate for all statements.</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
