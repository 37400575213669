import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const AddMessageDialog = ({ open, handleClose, newSubject, newMessage, handleSave, setNewSubject, setNewMessage }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle  variant="h5" fontWeight="600">
        Add Message:
        <Tooltip title="Close">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Message Subject"
          type="text"
          fullWidth
          value={newSubject}
          onChange={(e) => setNewSubject(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Message Content"
          type="text"
          fullWidth
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSave} color="secondary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditMessageDialog = ({ open, handleClose, selectedMessage, handleEditSave, setSelectedMessage }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle  variant="h5" fontWeight="600">
        Edit Message:
        <Tooltip title="Close">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Message Subject"
          type="text"
          fullWidth
          value={selectedMessage?.subject || ''}
          onChange={(e) => setSelectedMessage({ ...selectedMessage, subject: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Message Content"
          type="text"
          fullWidth
          value={selectedMessage?.content || ''}
          onChange={(e) => setSelectedMessage({ ...selectedMessage, content: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleEditSave} color="secondary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteMessageDialog = ({ open, handleClose, handleDelete }) => (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle variant="h5" fontWeight="600">
        Delete Message?
        <Tooltip title="Close">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        Proceed to delete this message?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );