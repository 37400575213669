import React from 'react';
import Chart from 'react-apexcharts';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../theme';

const LoanedAmount = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Dummy data for the months with loan amounts set to zero
  const loanedAmountData = [
    { month: 'January', amount: 0 },
    { month: 'February', amount: 0 },
    { month: 'March', amount: 0 },
    { month: 'April', amount: 0 },
    { month: 'May', amount: 0 },
    { month: 'June', amount: 0 },
    { month: 'July', amount: 0 },
    { month: 'August', amount: 0 },
    { month: 'September', amount: 0 },
    { month: 'October', amount: 0 },
    { month: 'November', amount: 0 },
    { month: 'December', amount: 0 },
  ];

  const series = [{
    name: 'Total Loaned Amount',
    data: loanedAmountData.map(item => item.amount)
  }];

  const options = {
    chart: {
      type: 'bar',
      height: 250,
      toolbar: {
        show: false
      },
      background: colors.primary[400]
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: loanedAmountData.map(item => item.month),
      labels: {
        style: {
          colors: colors.grey[100]
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: colors.grey[100]
        }
      }
    },
    fill: {
      colors: [colors.greenAccent[500]]
    },
    tooltip: {
      theme: 'light'
    }
  };

  return (
      <Chart options={options} series={series} type="bar" height={240} />
  );
};

export default LoanedAmount;
