import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import axios from "axios";

import Header from "../../../../components/Header";

const InjectionsTable = ({ customerId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { 
      field: "category", 
      headerName: "Category", 
      flex: 1,
      valueGetter: (params) => {
        switch(params.value) {
          case 'paybill_total':
            return 'Total Paybill Injections';
          case 'total_bank_account':
            return 'Total Bank Injections';
          case 'total_deposit':
            return 'Agent Deposits';
          case 'total_money_received':
            return 'Money Received from Individuals';
          default:
            return params.value;
        }
      }
    },
    { 
      field: "transactions", 
      headerName: "No. of Transactions", 
      flex: 1 
    },
    {
      field: "averageAmount",
      headerName: "Average Amount",
      flex: 1,
      valueFormatter: (params) =>
        `Ksh ${Number(params.value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      flex: 1,
      valueFormatter: (params) =>
        `Ksh ${Number(params.value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`
    },
  ];

  const [injectionsData, setInjectionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/injections/${customerId}/summary`);
        
        if (
          response.data &&
          response.data.categories &&
          response.data.transaction_counts &&
          response.data.average_amounts &&
          response.data.total_amounts
        ) {
          const { categories, transaction_counts, average_amounts, total_amounts } = response.data;
          const rows = categories.map((category, index) => ({
            id: index,
            category,
            transactions: transaction_counts[index],
            averageAmount: average_amounts[index],
            totalAmount: total_amounts[index]
          }));
          setInjectionsData(rows);
        } else {
          throw new Error('Response data is missing required properties.');
        }
  
        setLoading(false);
      } catch (error) {
        console.error('Error fetching injections data:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  return (
    <Box m="10px">
      <Header subtitle="Injections Summary:" />
      <Box
        m="10px 0 0 0"
        height="63vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={injectionsData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default InjectionsTable;
