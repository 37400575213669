import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTheme, Typography } from "@mui/material";
import { tokens } from '../../theme';

const CustomizeScore = ({ height, capabilityScore, characterScore }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [fontSize, setFontSize] = useState(48);

  useEffect(() => {
    // Adjust font size based on the height of the chart
    if (height < 200) {
      setFontSize(24);
    } else if (height < 300) {
      setFontSize(36);
    } else {
      setFontSize(48);
    }
  }, [height]);

  const options = {
    chart: {
      height: height,
      type: 'radialBar',
    },
    series: [capabilityScore * 100, characterScore * 100], // Convert to percentage
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '60%', // Adjust this to control the hollow area
          background: 'transparent',
        },
        track: {
          show: true,
          background: '#f2f2f2', // Background color of the track
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: true,
            fontSize: `${fontSize}px`,
            formatter: (val) => `${val.toFixed(0)}%`, // Show as percentage
            color: colors.myAccent[600],
          },
          total: {
            show: true,
            label: 'Total',
            formatter: () =>
              `${((capabilityScore + characterScore) * 50).toFixed(0)}%`, // Total percentage display
            fontSize: `${fontSize}px`,
            color: colors.myAccent[600],
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: [colors.myAccent[200], colors.myAccent[105]], // Updated gradient colors
        stops: [0, 50, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    colors: [colors.myAccent[200], colors.myAccent[105]], // Updated radial colors
    labels: ['Capability', 'Character'],
  };  

  return (
    <div style={{ position: 'relative', textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom>Customize Scores</Typography>
      <Chart
        options={options}
        series={options.series}
        type='radialBar'
        width='100%'
      />
      <Typography 
        variant="body1" 
        color="textSecondary" 
        gutterBottom 
        sx={{ fontWeight: 600, fontSize: '0.85rem' }}
      >
        <span style={{ color: colors.myAccent[200] }}>Capability Score</span> |{' '}
        <span style={{ color: colors.myAccent[105] }}>Character Score</span>
      </Typography>
    </div>
  );
};

export default CustomizeScore;
