import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const EmploymentDetails = React.memo(
  ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isNonMobile,
  }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isEmployed, setIsEmployed] = useState(false);
  const [isSelfEmployed, setIsSelfEmployed] = useState(false);

  const handleEmploymentChange = (event) => {
    setIsEmployed(event.target.value === "yes");
    if (event.target.value === "no") {
      setIsSelfEmployed(false);
    }
  };

  const handleSelfEmployedChange = (event) => {
    setIsSelfEmployed(event.target.value === "yes");
  };

    return (
      <Box>
        <Box
          display="grid"
          gap="30px"
          gridTemplateColumns="repeat(4, 1fr)"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Typography variant="h6" mb={-1} mt={3.5} gutterBottom sx={{ gridColumn: "span 4" }}>
            Employment Details:
          </Typography>
          <FormControl component="fieldset" sx={{ gridColumn: "span 4" }}>
            <Typography variant="body1">Employed?</Typography>
            <RadioGroup
              aria-label="employment-status"
              name="employmentStatus"
              value={isEmployed ? "yes" : "no"}
              onChange={handleEmploymentChange}
              row
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="secondary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="secondary" />} 
                label="No"
              />
              </RadioGroup>
              </FormControl>

          {isEmployed && (
            <>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Employer Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.employerName}
                name="employerName"
                error={!!touched.employerName && !!errors.employerName}
                helperText={touched.employerName && errors.employerName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Employer Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.employerAddress}
                name="employerAddress"
                error={!!touched.employerAddress && !!errors.employerAddress}
                helperText={touched.employerAddress && errors.employerAddress}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Employer Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.employerPhoneNumber}
                name="employerPhoneNumber"
                error={
                  !!touched.employerPhoneNumber &&
                  !!errors.employerPhoneNumber
                }
                helperText={
                  touched.employerPhoneNumber && errors.employerPhoneNumber
                }
                sx={{ gridColumn: "span 2" }}
              />
            </>
          )}

          {!isEmployed && (
            <FormControl component="fieldset" sx={{ gridColumn: "span 4" }}>
              <Typography variant="body1">Self Employed?</Typography>
              <RadioGroup
                aria-label="self-employment-status"
                name="selfEmploymentStatus"
                value={isSelfEmployed ? "yes" : "no"}
                onChange={handleSelfEmployedChange}
                row
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="secondary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="secondary" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          )}

          {!isEmployed && isSelfEmployed && (
            <>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nature of Business"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.natureOfBusiness}
                name="natureOfBusiness"
                error={!!touched.natureOfBusiness && !!errors.natureOfBusiness}
                helperText={touched.natureOfBusiness && errors.natureOfBusiness}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Business Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.businessAddress}
                name="businessAddress"
                error={!!touched.businessAddress && !!errors.businessAddress}
                helperText={touched.businessAddress && errors.businessAddress}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Business Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.businessPhoneNumber}
                name="businessPhoneNumber"
                error={
                  !!touched.businessPhoneNumber &&
                  !!errors.businessPhoneNumber
                }
                helperText={
                  touched.businessPhoneNumber && errors.businessPhoneNumber
                }
                sx={{ gridColumn: "span 2" }}
              />
            </>
          )}
        </Box>
      </Box>
    );
  }
);

export default EmploymentDetails;
