// TeamsSalesBarChart.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../../theme';

const TeamsSalesBarChart = ({ selectedMonth, selectedYear }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Define a pool of colors to choose from
    const colorPool = [
        colors.orangeAccent[500],
        colors.myAccent[300],
        colors.blueAccent[800],
        colors.redAccent[200],
        colors.redAccent[700],
        colors.myAccent[103],
        colors.greenAccent[600],
        colors.myAccent[105],
        colors.grey[700],
        colors.myAccent[201],
    ];

    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: false,
                    endingShape: 'rounded',
                },
            },
            xaxis: {
                categories: [],
                title: {
                    text: 'Teams',
                    style: {
                        color: colors.grey[100],
                    },
                },
                labels: {
                    style: {
                        colors: colors.grey[100],
                    },
                },
            },
            yaxis: {
                title: {
                    text: 'Combined Net Sales',
                    style: {
                        color: colors.grey[100],
                    },
                },
                labels: {
                    style: {
                        colors: colors.grey[100],
                    },
                },
            },
            colors: [], // Will be populated dynamically
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/teams-combined-net-sales`, {
                    params: { month: selectedMonth, year: selectedYear },
                });

                const teamsData = response.data.teams_combined_net_sales;

                if (teamsData.length > 0) {
                    const categories = teamsData.map(team => team.team_name);
                    const salesData = teamsData.map(team => team.combined_net_sales);

                    // Randomly select colors from the pool for each team
                    const teamColors = categories.map(() => 
                        colorPool[Math.floor(Math.random() * colorPool.length)]
                    );

                    setChartData(prevState => ({
                        ...prevState,
                        series: [{ name: 'Combined Net Sales', data: salesData }],
                        options: {
                            ...prevState.options,
                            xaxis: { categories },
                            colors: teamColors,
                        },
                    }));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (selectedMonth && selectedYear) {
            fetchData();
        }
    }, [selectedMonth, selectedYear]);

    return (
        <Box sx={{ width: '100%', padding: 2 }}>
            <Typography color={colors.grey[100]} variant="h6" fontWeight="600" gutterBottom>
                Combined Net Sales for Teams in {selectedMonth}/{selectedYear}:
            </Typography>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={350}
            />
        </Box>
    );
};

export default TeamsSalesBarChart;
