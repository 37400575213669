import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
  Snackbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import { fetchWithAuth } from '../../../auth'; // Replace with your auth utility
import { tokens } from '../../../theme'; // Replace with your theme configuration

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SettingsComponent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [settings, setSettings] = useState({
    can_verify_customer_id: false,
    can_verify_nextofkin_id: false,
    can_verify_bankaccount: false,
    can_verify_motorvehicle_record: false,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [updatedSettings, setUpdatedSettings] = useState(settings);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const fetchSettings = async () => {
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/account-settings`);
      
      if (!response || typeof response !== 'object' || !response.settings) {
        throw new Error('Invalid response');
      }

      setSettings(response.settings);
      setUpdatedSettings(response.settings);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setUpdatedSettings(settings);
  };

  const handleCheckboxChange = (event) => {
    setUpdatedSettings({
      ...updatedSettings,
      [event.target.name]: event.target.checked,
    });
  };

  const handleEditSettings = async () => {
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/account-settings/update`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: updatedSettings,
      });

      if (response) {
        setSettings(updatedSettings);
        setSnackbarMessage('Settings Updated Successfully!');
        setUpdatedSettings(updatedSettings);
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        setOpenDialog(false);
      } else {
        throw new Error('Failed to save settings');
      }
    } catch (error) {
      setSnackbarMessage('You are not authorized to perform this action.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setOpenDialog(false);
    }
  };

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h6" sx={{ color: colors.grey[100] }}>
        Account Settings
      </Typography>

      <Box>
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.can_verify_customer_id}
                onChange={handleCheckboxChange}
                sx={{
                  color: colors.greenAccent[200],
                  '&.Mui-checked': {
                    color: colors.greenAccent[600],
                  },
                }}
                disabled
              />
            }
            label="Can verify customer ID"
          />
          <Typography variant="body2" sx={{ color: colors.grey[400], ml: 4 }}>
            This setting enables you to verify the customer ID during KYC.
          </Typography>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.can_verify_nextofkin_id}
                onChange={handleCheckboxChange}
                sx={{
                  color: colors.greenAccent[200],
                  '&.Mui-checked': {
                    color: colors.greenAccent[600],
                  },
                }}
                disabled
              />
            }
            label="Can verify next of kin ID"
          />
          <Typography variant="body2" sx={{ color: colors.grey[400], ml: 4 }}>
            This setting enables you to verify the next of kin ID during KYC.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.can_verify_bankaccount}
                onChange={handleCheckboxChange}
                sx={{
                  color: colors.greenAccent[200],
                  '&.Mui-checked': {
                    color: colors.greenAccent[600],
                  },
                }}
                disabled
              />
            }
            label="Can verify bank account"
          />
          <Typography variant="body2" sx={{ color: colors.grey[400], ml: 4 }}>
            This setting allows the user to verify bank account information.
          </Typography>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings.can_verify_motorvehicle_record}
                onChange={handleCheckboxChange}
                sx={{
                  color: colors.greenAccent[200],
                  '&.Mui-checked': {
                    color: colors.greenAccent[600],
                  },
                }}
                disabled
              />
            }
            label="Can verify motor vehicle record"
          />
          <Typography variant="body2" sx={{ color: colors.grey[400], ml: 4 }}>
            This setting enables the user to verify motor vehicle records.
          </Typography>
        </Box>
      </Box>

      <Button variant="contained" color="secondary" onClick={handleOpenDialog}>
        Modify Settings
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>Modify Account Settings</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="can_verify_customer_id"
                  checked={updatedSettings.can_verify_customer_id}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: colors.greenAccent[200],
                    '&.Mui-checked': {
                      color: colors.greenAccent[600],
                    },
                  }}
                />
              }
              label="Can verify customer ID"
            />
            <Typography variant="body2" sx={{ color: colors.grey[400], ml: 4 }}>
              Allow user to verify customer ID.
            </Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  name="can_verify_nextofkin_id"
                  checked={updatedSettings.can_verify_nextofkin_id}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: colors.greenAccent[200],
                    '&.Mui-checked': {
                      color: colors.greenAccent[600],
                    },
                  }}
                />
              }
              label="Can verify next of kin ID"
            />
            <Typography variant="body2" sx={{ color: colors.grey[400], ml: 4 }}>
              Allow user to verify next of kin ID.
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="can_verify_bankaccount"
                  checked={updatedSettings.can_verify_bankaccount}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: colors.greenAccent[200],
                    '&.Mui-checked': {
                      color: colors.greenAccent[600],
                    },
                  }}
                />
              }
              label="Can verify bank account"
            />
            <Typography variant="body2" sx={{ color: colors.grey[400], ml: 4 }}>
              Allow user to verify bank account information.
            </Typography>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  name="can_verify_motorvehicle_record"
                  checked={updatedSettings.can_verify_motorvehicle_record}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: colors.greenAccent[200],
                    '&.Mui-checked': {
                      color: colors.greenAccent[600],
                    },
                  }}
                />
              }
              label="Can verify motor vehicle record"
            />
            <Typography variant="body2" sx={{ color: colors.grey[400], ml: 4 }}>
              Allow user to verify motor vehicle records.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={handleEditSettings}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SettingsComponent;
