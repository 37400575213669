import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, Tabs, Tab, Slide, AppBar} from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingChart from './shoppingChart';
import { tokens } from "../../../../theme";
import { useTheme } from "@mui/material";
import ShoppingProgressCircle from './shoppingprogresscircle';
import ShoppingSummary from './shoppingSummary';
import Retail from './retailSpend';
import FMCG from './fmcgSpend';
import HealthSpend from './healthSpend';
import FoodSpend from './foodSpend';

const Shopping = ({customerId}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [ShoppingData, setShoppingData] = useState(null);
  const [monthlyShopping, setMonthlyShopping] = useState(null);
  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState(0); 

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); 
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/shopping-spend/${customerId}`);
        const data = await response.json();
        setShoppingData(data);
        setMonthlyShopping(data.monthly_shopping);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Shopping Spend data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [customerId]);

  return (
    <Box mt="3px">
      <Box mb="1px" mt={1}>         
          <Typography variant="h6" color={colors.grey[100]}>
            Shopping Spending Habits: 
          </Typography>
        </Box>
      <Box m={1}>
        <Box mt={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <ShoppingCartOutlinedIcon />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Shopping:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold">
                    Total: {ShoppingData ? `ksh ${numberWithCommas(ShoppingData.total_shopping.toFixed(2))}` : ""}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                position: 'relative', // Adding position relative to create a positioning context for absolute positioning
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Retail Spend:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    {ShoppingData ? `ksh ${numberWithCommas(ShoppingData.category_totals["Retail Spend"].toFixed(2))}` : ""}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <ShoppingProgressCircle progress={ShoppingData ? ShoppingData.category_percentages["Retail Percentage"] / 100 : 0} size={25} />
                  <Typography variant="h6" ml={1}>
                    {ShoppingData ? `${ShoppingData.category_percentages["Retail Percentage"]} %` : ""}
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Card sx={{
              width: '100%',
              position: 'relative',
              backgroundColor: colors.primary[400],
            }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Total Health Spend:
                </Typography>
                <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                  {ShoppingData ? `ksh ${numberWithCommas(ShoppingData.category_totals["Health Spend"].toFixed(2))}` : ""}
                </Typography>
              </CardContent>
              <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                <ShoppingProgressCircle progress={ShoppingData ? ShoppingData.category_percentages["Health Percentage"] / 100 : 0} size={25} />
                <Typography variant="h6" ml={1}>
                  {ShoppingData ? `${ShoppingData.category_percentages["Health Percentage"]} %` : ""}
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>
        </Box>
        {/* Row 2 */}
        <Box  mt={-2.5}>
          <Grid container spacing={1}>
          <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                position: 'relative',
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total FMCG Spend:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    {ShoppingData ? `ksh ${numberWithCommas(ShoppingData.category_totals["FMCG Spend"].toFixed(2))}` : ""}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <ShoppingProgressCircle progress={ShoppingData ? ShoppingData.category_percentages["FMCG Percentage"] / 100 : 0} size={25} />
                  <Typography variant="h6" ml={1}>
                    {ShoppingData ? `${ShoppingData.category_percentages["FMCG Percentage"]} %` : ""}
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Card sx={{
                width: '100%',
                position: 'relative', 
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Food Spend:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    {ShoppingData ? `ksh ${numberWithCommas(ShoppingData.category_totals["Food Spend"].toFixed(2))}` : ""}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <ShoppingProgressCircle progress={ShoppingData ? ShoppingData.category_percentages["Food Percentage"] / 100 : 0} size={25} />
                  <Typography variant="h6" ml={1}>
                    {ShoppingData ? `${ShoppingData.category_percentages["Food Percentage"]} %` : ""}
                  </Typography>
                </div>
              </Card>
            </Grid>
          </Grid>
          <br/>
          <Typography
            variant="h6"
            color={colors.grey[100]}
          >
            Spending Habits:
          </Typography>
          <br/>
          <div sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop:3,
          }}>
            <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                width='100%'
                height="250px"
                m="-20px 0 0 0"
                padding={2}
                sx={{
                  width: '95%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
            >
              <ShoppingChart monthlyShopping={monthlyShopping} />
            </Box>
          </div>
          <br/>
          <AppBar position="static" sx={{backgroundColor: colors.primary[500], borderRadius: 2}}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              variant="scrollable"
              sx={{ indicator: {
                backgroundColor: colors.mode === 'dark' ? tokens('dark').myAccent[200] : tokens('light').myAccent[200],
              }}}
            >
              <Tab
                label="Shopping Summary"
                sx={{ fontWeight: '600'}}
              />
              <Tab
                label="Retail Spend"
                sx={{ fontWeight: '600'}}
              />
              <Tab
                label="FMCG Spend"
                sx={{ fontWeight: '600'}}
              />
              <Tab
                label="Health Spend"
                sx={{ fontWeight: '600'}}
              />
              <Tab
                label="Food Spend"
                sx={{ fontWeight: '600'}}
              />
              
            </Tabs>
          </AppBar>

          <Slide direction="left" in={activeTab === 0} mountOnEnter unmountOnExit>
            <div>
              <ShoppingSummary customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 1} mountOnEnter unmountOnExit>
            <div>
              <Retail customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 2} mountOnEnter unmountOnExit>
            <div>
              <FMCG customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 3} mountOnEnter unmountOnExit>
            <div>
              <HealthSpend customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 4} mountOnEnter unmountOnExit>
            <div>
              <FoodSpend customerId={customerId}/>
            </div>
          </Slide>
        </Box>
      </Box>        
    </Box>
  );
};

export default Shopping;
