import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import axios from "axios";
import Header from "../../../../components/Header";

const ShoppingSummary = ({ customerId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [shoppingSummary, setShoppingSummary] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "category", headerName: "Category", flex: 1 },
    { field: "transaction_counts", headerName: "No. of Transactions", flex: 1 },
    {
      field: "average_amount",
      headerName: "Average Transaction Amount",
      flex: 1,
      valueFormatter: (params) => {
        if (typeof params.value === 'number') {
          return `Ksh ${params.value.toFixed(2).toLocaleString()}`;
        }
        return "na";
      }
    },
    {
      field: "total_amount",
      headerName: "Total Transaction Amount",
      flex: 1,
      valueFormatter: (params) => {
        if (typeof params.value === 'number') {
          return `Ksh ${params.value.toFixed(2).toLocaleString()}`;
        }
        return "na";
      }
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shopping-spend-summary/${customerId}`);
        const shoppingData = response.data;

        const transformedData = Object.keys(shoppingData.category_totals).map((category, index) => ({
            id: index + 1,
            category: category,
            transaction_counts: shoppingData.category_transaction_counts[category.replace(' Spend', ' Transaction Count')] || 0,
            average_amount: parseFloat(shoppingData.category_average_amounts[category.replace(' Spend', ' Average')]) || 0,
            total_amount: shoppingData.category_totals[category],
          }));                    
          
        setShoppingSummary(transformedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Shopping summary data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  return (
    <Box m="10px">
      <Header subtitle="Shopping:" />
      <Box
        m="10px 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={shoppingSummary}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default ShoppingSummary;
