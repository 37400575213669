import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ListBullets as ListBulletsIcon } from '@phosphor-icons/react';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';

export default function MonthlyCompanyLBFTargetPercentage({ selectedMonth, selectedYear }) {
  const [targetAchieved, setTargetAchieved] = useState(null);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const fetchSalesData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/lbf-sales-summary`, {
        params: {
          month: selectedMonth,
          year: selectedYear,
        },
      });
      console.log('Sales Data:', response.data.sales_data); // Log the sales data

      const achieved = response.data.sales_data?.[0]?.lbf_target_achieved || 0;
      // Multiply by 100 and round off to 2 decimal places
      const formattedAchieved = Math.round(achieved * 100 * 100) / 100;
      setTargetAchieved(formattedAchieved);
      setError(null);
    } catch (err) {
      console.error('Error fetching sales data:', err.response?.data?.message || err); // Log the error for debugging
      setError(err.response?.data?.message || 'Failed to fetch data.');
      setTargetAchieved(null);
    }
  };

  useEffect(() => {
    fetchSalesData();
  }, [selectedMonth, selectedYear]);

  return (
    <Card sx={{ padding: '10px', backgroundColor: colors.primary[400] }}>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
            <Stack spacing={1}>
              <Typography color={colors.grey[100]} fontWeight="600" variant="h6">
                LBF Target %
              </Typography>
              <Typography variant="h4" color={colors.greenAccent[500]} fontWeight="bold">
                {targetAchieved !== null ? `${targetAchieved}%` : '0%'}
              </Typography>
            </Stack>
            <Avatar sx={{ backgroundColor: '#FFC107', height: '56px', width: '56px' }}>
              <ListBulletsIcon fontSize="var(--icon-fontSize-lg)" />
            </Avatar>
          </Stack>
          <div>
            <LinearProgress
              value={targetAchieved || 0}
              variant="determinate"
              sx={{
                height: 6, // Optional: Adjust the height of the progress bar
                '& .MuiLinearProgress-bar': {
                  backgroundColor: colors.greenAccent[500], // Color of the progress bar
                },
                '& .MuiLinearProgress-root': {
                  backgroundColor: colors.primary[500], // Color of the background track
                },
              }}
            />
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
}
