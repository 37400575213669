import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../theme';
import Cookies from 'js-cookie';

const AnnualCustomerChart = () => {
  const [data, setData] = useState({ categories: [], series: [], tooltips: [] });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const accessToken = Cookies.get('accessToken'); // Get the token from cookies

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/annualcustomers`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        
        // Transform the response data
        const categories = response.data.map(item => new Date(item.date).toLocaleString('default', { month: 'long' }));
        const series = response.data.map(item => item.count);
        const tooltips = response.data.map(item => item.date);

        setData({ categories, series, tooltips });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 250,
      toolbar: {
        show: false
      },
      background: colors.primary[400]
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: data.categories,
      labels: {
        style: {
          colors: colors.grey[100]
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: colors.grey[100]
        }
      }
    },
    fill: {
      colors: [colors.greenAccent[500]]
    },
    tooltip: {
      theme: 'light',
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        return `<div style="padding: 10px;">
                  <strong>${data.categories[dataPointIndex]} (${data.tooltips[dataPointIndex]})</strong><br/>
                  Customers: ${series[seriesIndex][dataPointIndex]}
                </div>`;
      }
    },
    series: [{
      name: 'Customers',
      data: data.series
    }]
  };

  return (
    <div>
      <ApexCharts
        options={chartOptions}
        series={chartOptions.series}
        type="bar"
        height={255}
      />
    </div>
  );
};

export default AnnualCustomerChart;
