import React from 'react';
import { CardContent, Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from '../../../theme';

const PersonalInformation = ({ selectedCustomer }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            margin="dense"
            name="first_name"
            label="First Name"
            type="text"
            fullWidth
            variant="outlined"
            value={selectedCustomer ? selectedCustomer.first_name : ""}
            // disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="dense"
            name="last_name"
            label="Last Name"
            type="text"
            fullWidth
            variant="outlined"
            value={selectedCustomer ? selectedCustomer.last_name : ""}
            // disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={selectedCustomer ? selectedCustomer.email : ""}
            // disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            name="id_number"
            label="ID Number"
            type="text"
            fullWidth
            variant="outlined"
            value={selectedCustomer ? selectedCustomer.id_number : ""}
            // disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            name="phone_number"
            label="Phone Number"
            type="text"
            fullWidth
            variant="outlined"
            value={selectedCustomer ? selectedCustomer.phone_number : ""}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default PersonalInformation;
