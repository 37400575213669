import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  Select,
  MenuItem,
  useMediaQuery,
  FormControl,
  InputLabel,
  FormHelperText,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  Typography,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { tokens } from '../../../theme';
import { ArrowDropDown } from '@mui/icons-material';

const validationSchema = Yup.object({
  productName: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  loanTenor: Yup.number().required('Required').positive('Must be positive'),
  interestRate: Yup.number().required('Required').positive('Must be positive'),
  processingFees: Yup.number().required('Required').positive('Must be positive'),
  penaltyRate: Yup.number().required('Required').positive('Must be positive'),
  modeOfInterest: Yup.string().required('Required'),
  conditions: Yup.string().nullable(),
});

const preDisbursementFees = [
  { name: 'NTSA fees', amount: '0.00' },
  { name: 'Tracking Installation Fees', amount: '0.00' },
  { name: 'Chattels', amount: '0.00' },
  { name: 'VALUATION (Variable based on M/V)', amount: '0.00' },
  { name: 'TAPE LIFT (Variable based on Motor-vehicle)', amount: '0.00' },
  { name: 'INSURANCE AMOUNT (capitalized, deducted, Self or IPF (Deposit)', amount: '0.00' },
  { name: 'Others (Deducted)', amount: '0.00' },
];

const NewLoanProduct = ({ initialValues, handleSubmit, isEditing, handleClose }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const [fees, setFees] = useState(preDisbursementFees);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const initialFormValues = isEditing
    ? initialValues
    : {
        productName: '',
        description: '',
        loanTenor: '',
        interestRate: '',
        processingFees: '0.00',
        penaltyRate: '',
        modeOfInterest: 'yearly',
        conditions: '',
      };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleFeeChange = (index, value) => {
    const newFees = [...fees];
    newFees[index].amount = value;
    setFees(newFees);
  };

  const calculateTotalFees = () => {
    return fees.reduce((acc, fee) => acc + parseFloat(fee.amount || 0), 0).toFixed(2);
  };

  const open = Boolean(anchorEl);

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
            >
              <TextField
                fullWidth
                variant="filled"
                label="Product Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.productName}
                name="productName"
                error={!!touched.productName && !!errors.productName}
                helperText={touched.productName && errors.productName}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Interest Rate (%)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.interestRate}
                name="interestRate"
                error={!!touched.interestRate && !!errors.interestRate}
                helperText={touched.interestRate && errors.interestRate}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl
                fullWidth
                variant="filled"
                error={!!touched.modeOfInterest && !!errors.modeOfInterest}
                sx={{ gridColumn: "span 2" }}
              >
                <InputLabel>Mode of Interest</InputLabel>
                <Select
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.modeOfInterest}
                  name="modeOfInterest"
                >
                  <MenuItem value="yearly">Yearly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
                <FormHelperText>
                  {touched.modeOfInterest && errors.modeOfInterest}
                </FormHelperText>
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Maximum Loan Tenor (in months)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.loanTenor}
                name="loanTenor"
                error={!!touched.loanTenor && !!errors.loanTenor}
                helperText={touched.loanTenor && errors.loanTenor}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Penalty Rate (%)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.penaltyRate}
                name="penaltyRate"
                error={!!touched.penaltyRate && !!errors.penaltyRate}
                helperText={touched.penaltyRate && errors.penaltyRate}
                sx={{ gridColumn: "span 2" }}
              />
              <Box sx={{ gridColumn: "span 4", position: "relative" }}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Processing Fees"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.processingFees}
                  name="processingFees"
                  error={!!touched.processingFees && !!errors.processingFees}
                  helperText={touched.processingFees && errors.processingFees}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handlePopoverOpen}>
                        <ArrowDropDown />
                      </IconButton>
                    )
                  }}
                />
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => {
                    handlePopoverClose();
                    setFieldValue('processingFees', calculateTotalFees());
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: colors.primary[600],
                    },
                  }}
                >
                  <Box
                    p={1}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6" component="div" fontWeight="600" color="textPrimary">
                              Pre-Disbursement Fee
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6" component="div" fontWeight="600" color="textPrimary">
                              Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fees.map((fee, index) => (
                          <TableRow key={index}>
                            <TableCell>{fee.name}</TableCell>
                            <TableCell>
                              <TextField
                                type="number"
                                variant="outlined"
                                size="small"
                                value={fee.amount}
                                onChange={(e) => handleFeeChange(index, e.target.value)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell><strong>Total</strong></TableCell>
                          <TableCell><strong>{calculateTotalFees()}</strong></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Popover>
              </Box>
              <TextField
                fullWidth
                variant="filled"
                label="Loan Conditions (If any)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.conditions}
                name="conditions"
                error={!!touched.conditions && !!errors.conditions}
                helperText={touched.conditions && errors.conditions}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error" variant="contained">
              Cancel
            </Button>
            <Button type="submit" color="secondary" variant="contained">
              {isEditing ? 'Save Changes' : 'Add Product'}
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default NewLoanProduct;

