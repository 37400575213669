import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import './InjectionChart.css';

const InjectionChart = ({ monthlyInjections }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!monthlyInjections) {
    return null; // or render a loading indicator
  }

  // Extracting data for the chart
  const months = monthlyInjections.map(item => item.month);
  const totalMoneyReceived = monthlyInjections.map(item => item.total_money_received);
  const totalDeposit = monthlyInjections.map(item => item.total_deposit);
  const totalBankAccount = monthlyInjections.map(item => item.total_bank_account);
  const paybillTotal = monthlyInjections.map(item => item.paybill_total);

  // Chart options
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          colors: colors.grey[100],
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: colors.grey[100],
        }
      }
    },
    legend: {
      position: 'top',
      labels: {
        colors: colors.grey[100],
      }
    },
    fill: {
      opacity: 1,
    },
  };

  // Chart series data
  const series = [
    {
      name: 'From Individuals',
      data: totalMoneyReceived,
    },
    {
      name: 'Agent Deposits',
      data: totalDeposit,
    },
    {
      name: 'Bank Injections',
      data: totalBankAccount,
    },
    {
      name: 'From Paybill',
      data: paybillTotal,
    },
  ];

  return (
    <div style={{ width: '95%', paddingTop: '15px' }}> 
      <Chart options={options} series={series} type="bar" height={245} />
    </div>
  );
};

export default InjectionChart;

