


import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions,Typography,Divider } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { fetchWithAuth } from "../../auth";
import CustomerProfile from "./customerprofile";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [emergencyContacts, setEmergencyContacts] = useState([]);

  const columns = [
    { field: "first_name", headerName: "First Name", flex: 1 },
    { field: "last_name", headerName: "Last Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone_number", headerName: "Phone Number", flex: 1 },
    { field: "id_number", headerName: "ID Number", flex: 1 },
    {
      field: "loan_status",
      headerName: "Loan Status",
      flex: 1,
      renderCell: (params) => (
        params.value === 'active' ? (
          <Box
            sx={{
              backgroundColor: 'green',
              color: 'white',
              borderRadius: '10%',
              width: 70,
              height: 25,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Typography variant="body2" sx={{ color: 'white' }}>
              {params.value}
            </Typography>
          </Box>
        ) : (
          <Typography>{params.value}</Typography>
        )
      ),
    },
    {
      field: "view",
      headerName: "View Customer",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.blueAccent[700],
            "&:hover": {
              backgroundColor: colors.myAccent[800],
            },
            color: colors.grey[100],
          }}
          onClick={() => handleViewClick(params.row)}
        >
          View
        </Button>
      ),
    },
  ];  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/customers`);
        setCustomerData(data);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleViewClick = async (customer) => {
    if (customer && customer.id) {
      setSelectedCustomer(customer);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/next-of-kins/${customer.id}`);
        const data = await response.json();
        setEmergencyContacts(data);
      } catch (error) {
        console.error("Error fetching emergency contacts:", error);
      }
      setOpenDialog(true);
    } else {
      console.error("Invalid customer data:", customer);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCustomer(null);
    setEmergencyContacts(null);
  };

  const fetchEmergencyContacts = async (customerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/next-of-kin/${customerId}`);
      // const data = await response.json();
      // setEmergencyContacts(data);
      const data = await response.json(); // Parse the JSON from the response
      setEmergencyContacts(data); 
    } catch (error) {
      console.error("Error fetching emergency contacts:", error);
    }
  };

  return (
    <Box m="10px">
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={customerData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.id_number} // Use ID number as the row ID
        />
      </Box>
      <CustomerProfile
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        open={openDialog}
        onClose={handleCloseDialog}
  
      />
    </Box>
  );
};

export default Contacts;
