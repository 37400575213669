import React, { useState } from "react";
import { CardContent, Divider, Grid, Typography, useTheme, Card, Button } from "@mui/material";
import { tokens } from "../../../theme";
import { fetchWithAuth } from "../../../auth";

const CustomerDocuments = ({ documents }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false); // For handling button states

  const handleViewDocument = async (file) => {
    try {
      setLoading(true);
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/uploads/${file}`, { method: "GET", responseType: "blob" });

      if (response) {
        // Create a blob URL for the file and open it in a new tab
        const blob = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "noopener,noreferrer");
      } else {
        console.error("Failed to fetch document.");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardContent>
      <Typography variant="h6" color={colors.grey[100]} gutterBottom>
        Collateral Documents
      </Typography>
      <Divider sx={{ my: 1 }} />

      {documents && documents.length > 0 ? (
        <Grid container spacing={2}>
          {documents.map((doc) => (
            <Grid item xs={12} key={doc.id}>
              <Card sx={{ p: 2, backgroundColor: colors.primary[600] }}>
                <Typography variant="body1" color={colors.grey[100]} fontWeight="600">
                  {doc.description || "No Description Provided"}
                </Typography>
                <Typography variant="body2" color={colors.grey[200]}>
                  File: {doc.file || "N/A"}
                </Typography>
                <Typography variant="body2" color={colors.grey[200]}>
                  Uploaded On: {doc.created_at ? new Date(doc.created_at).toLocaleString() : "Unknown"}
                </Typography>
                {doc.file && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleViewDocument(doc.file)}
                    disabled={loading} // Disable button while loading
                    sx={{ mt: 1 }}
                  >
                    {loading ? "Loading..." : "View Document"}
                  </Button>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body2" color={colors.grey[200]}>
          No collateral documents available.
        </Typography>
      )}
    </CardContent>
  );
};

export default CustomerDocuments;





// import React, { useState } from "react";
// import { CardContent, Divider, Grid, Typography, useTheme, Card, Button, Modal, Box } from "@mui/material";
// import { tokens } from "../../../theme";

// const CustomerDocuments = ({ documents }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [selectedFile, setSelectedFile] = useState(null); // Track the currently selected file

//   const handleViewDocument = (file) => {
//     const fileUrl = `${process.env.REACT_APP_API_BASE_URL}/uploads/${file}`;
//     console.log("File URL: ", fileUrl);
//     setSelectedFile(fileUrl);
//   };

//   const handleCloseModal = () => {
//     setSelectedFile(null); // Close the modal by clearing the selected file
//   };

//   return (
//     <CardContent>
//       <Typography variant="h6" color={colors.grey[100]} gutterBottom>
//         Collateral Documents
//       </Typography>
//       <Divider sx={{ my: 1 }} />

//       {documents && documents.length > 0 ? (
//         <Grid container spacing={2}>
//           {documents.map((doc) => (
//             <Grid item xs={12} key={doc.id}>
//               <Card sx={{ p: 2, backgroundColor: colors.primary[600] }}>
//                 <Typography variant="body1" color={colors.grey[100]} fontWeight="600">
//                   {doc.description || "No Description Provided"}
//                 </Typography>
//                 <Typography variant="body2" color={colors.grey[200]}>
//                   File: {doc.file || "N/A"}
//                 </Typography>
//                 <Typography variant="body2" color={colors.grey[200]}>
//                   Uploaded On: {doc.created_at ? new Date(doc.created_at).toLocaleString() : "Unknown"}
//                 </Typography>
//                 {doc.file && (
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={() => handleViewDocument(doc.file)}
//                     sx={{ mt: 1 }}
//                   >
//                     View Document
//                   </Button>
//                 )}
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       ) : (
//         <Typography variant="body2" color={colors.grey[200]}>
//           No collateral documents available.
//         </Typography>
//       )}

//       {/* Modal for displaying the document */}
//       <Modal open={Boolean(selectedFile)} onClose={handleCloseModal}>
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "80%",
//             height: "80%",
//             bgcolor: "background.paper",
//             boxShadow: 24,
//             p: 2,
//           }}
//         >
//           {selectedFile && (
//             <iframe
//               src={selectedFile}
//               title="Document Viewer"
//               width="100%"
//               height="100%"
//               style={{ border: "none" }}
//             />
//           )}
//         </Box>
//       </Modal>
//     </CardContent>
//   );
// };

// export default CustomerDocuments;
