import React, { useState } from "react";
import { Box, TextField, Typography, IconButton, InputAdornment, Divider } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const UploadStatement = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <label htmlFor="mpesaStatement" className="file-input-label">
          <CloudUploadIcon sx={{ fontSize: 50, mb: 1 }} />
          <Typography variant="body2">Upload Mpesa Statement (PDF)</Typography>
          <input
            id="mpesaStatement"
            type="file"
            accept=".pdf"
            onChange={(event) => {
              setFieldValue("mpesaStatement", event.currentTarget.files[0]);
            }}
          />
        </label>
        {touched.mpesaStatement && errors.mpesaStatement && (
          <Typography color="error">{errors.mpesaStatement}</Typography>
        )}
        <TextField
          variant="filled"
          type={showPassword ? "text" : "password"}
          label="MPESA Statement Password (Input Password if Statement is Encrypted)"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.statementPassword}
          name="statementPassword"
          error={!!touched.statementPassword && !!errors.statementPassword}
          helperText={touched.statementPassword && errors.statementPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default UploadStatement;