import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import axios from "axios";

const TeamLBFMonthlySalesPerformance = ({ selectedMonth, selectedYear }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOptionalPAR, setShowOptionalPAR] = useState(false);

  const parColumns = [
    { field: "par_7", headerName: "PAR 7", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
    { field: "par_45", headerName: "PAR 45", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
    { field: "par_60", headerName: "PAR 60", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
    { field: "par_90", headerName: "PAR 90", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
    { field: "par_120", headerName: "PAR 120", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
    { field: "par_150", headerName: "PAR 150", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
  ];

  const baseColumns = [
    { field: "team_name", headerName: "Team", flex: 1 },
    { field: "lbf_target", headerName: "LBF Target", flex: 1, valueFormatter: ({ value }) => formatNumber(value) },
    { field: "lbf_net_sales", headerName: "LBF Net Sales", flex: 1, valueFormatter: ({ value }) => formatNumber(value) },
    { field: "lbf_target_achieved", headerName: "LBF % Target Achievement", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
    { field: "lbf_olb", headerName: "LBF OLB", flex: 1, valueFormatter: ({ value }) => formatNumber(value) },
    { field: "par_1", headerName: "PAR 1", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
    { field: "par_15", headerName: "PAR 15", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
    { field: "par_30", headerName: "PAR 30", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
    { field: "par_180", headerName: "PAR 180", flex: 1, valueFormatter: ({ value }) => formatPercentage(value) },
  ];

  const columns = showOptionalPAR ? [...baseColumns, ...parColumns] : baseColumns;

  const fetchTeams = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/teams-summary`,
        { params: { month: selectedMonth, year: selectedYear } }
      );
      setTeams(response.data.teams || []);
    } catch (error) {
      console.error("Error fetching team data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      fetchTeams();
    }
  }, [selectedMonth, selectedYear]);

  const generateRows = (team) => {
    const teamSummaryRow = {
      id: `summary-${team.team_name}`,
      team_name: team.team_name,
      isSummaryRow: true,
      ...team.performance,
      ...team.portfolio,
    };

    const memberRows = team.team_members.map((member, index) => ({
      id: `${team.team_name}-${index}`,
      team_name: member.name,
      isSummaryRow: false,
      ...member.performance,
      ...member.portfolio,
    }));

    return [teamSummaryRow, ...memberRows];
  };

  const formatNumber = (value) =>
    value !== null && value !== undefined ? new Intl.NumberFormat().format(value) : value;

  const formatPercentage = (value) =>
    value !== null && value !== undefined ? `${(value * 100).toFixed(2)}%` : value;

  return (
    <Box m="20px">
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setShowOptionalPAR(!showOptionalPAR)}
        sx={{ mb: 2 }}
      >
        {showOptionalPAR ? "Hide Optional PAR Values" : "Show Optional PAR Values"}
      </Button>

      {teams.map((team) => (
        <Box key={team.team_name} mb={4}>
          <Typography variant="h6" color="secondary" mb={2}>
            {team.team_name}
          </Typography>

          <Box height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              // Adding background color to team-summary-row
              "& .team-summary-row": {
                backgroundColor: colors.myAccent[104],
                fontWeight: "bold",
              },
            }}
            >
            <DataGrid
              loading={loading}
              rows={generateRows(team)}
              columns={columns}
              getRowId={(row) => row.id}
              components={{ Toolbar: GridToolbar }}
              getRowClassName={(params) =>
                params.row.isSummaryRow ? "team-summary-row" : ""
              }
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TeamLBFMonthlySalesPerformance;
