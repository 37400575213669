import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header';
import EditCustomers from '../EditCustomers';
import ReferralTree from './customerReferralTree'
import Contacts from '../contacts';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function Customers() {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const classes = tokens(theme);
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="10px">
      <Header title="CUSTOMERS" subtitle="List of Registered Customers." />
      <Box sx={{ width: '100%', marginTop: '-4px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          textTransform="none"
          aria-label="secondary tabs example"
          sx={{ gap: '10px' }} // Add space between tabs
        >
          <Tab
            value={0}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PeopleAltOutlinedIcon sx={{ marginRight: '5px' }} /> 
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  All Customers
                </Typography> 
              </Box>
            }
          />
          <Tab
            value={1}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EditOutlinedIcon sx={{ marginRight: '5px' }} />
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  Manage Customers
                </Typography>
              </Box>
            }
          />
            {/* <Tab
            value={2}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EditOutlinedIcon sx={{ marginRight: '5px' }} />
                <Typography variant="h6" fontWeight="600" textTransform="none">
                  Customer Referral Tree
                </Typography>
              </Box>
            }
          /> */}
        </Tabs>
      </Box>
      {/* Render the corresponding component based on the selected tab */}
      {value === 0 && <Contacts />}
      {value === 1 && <EditCustomers />}
      {/* {value === 2 && <ReferralTree />} */}
    </Box>
  );
}

export default Customers;