import React, { useState, useEffect, useContext } from 'react';
import { Box, IconButton, useTheme, Tooltip, Popover, MenuItem, ListItemIcon, Divider, Dialog, DialogContent, Typography, CircularProgress } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import NotificationDropdown from './NotificationDropdown';
import { fetchWithAuth } from '../../auth';

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const isDarkMode = theme.palette.mode === "dark";
  const tooltipTitle = isDarkMode ? "Light mode" : "Dark mode";
  
  const [appraisalStats, setAppraisalStats] = useState({ total_appraisals: 0, remaining_appraisals: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAppraisalStats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company-trial-appraisal-stats`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('accessToken')}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
  
        setAppraisalStats({
          total_appraisals: data.total_appraisals,
          remaining_appraisals: data.remaining_appraisals
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching appraisal stats:', error);
        setError('Failed to fetch test appraisal stats');
        setLoading(false);
      }
    };
  
    fetchAppraisalStats();
  }, []);
  
  const handleAccountIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
        method: "POST",
      });

      Cookies.remove('accessToken', { domain: 'localhost' });
      Cookies.remove('refreshToken', { domain: 'localhost' });
      window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/signin`;
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLogoutDialogOpen(false);
    }
  };

  const initiateLogout = () => {
    setLogoutDialogOpen(true);
    setTimeout(handleLogout, 2000);
  };

  return (
    <Box position="sticky" top={0} bgcolor={colors.primary[500]} zIndex={999}>
      <Box display="flex" justifyContent="space-between" p={2}>
        <Box display="flex" bgcolor={colors.primary[400]} borderRadius="3px">
          <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>

        {/* New Box component for Appraisal Stats */}
        <Box display="flex" alignItems="center" mx={2}>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <>
              {/* Appraisals done text */}
              <Typography color={colors.greenAccent[400]} variant="h6" fontWeight="600">
                Appraisals done:&nbsp;
              </Typography>
              
              {/* Total appraisals number */}
              <Typography color={colors.grey[100]} variant="h6" fontWeight="600">
                {appraisalStats.total_appraisals},&nbsp;
              </Typography>

              {/* Trial tokens remaining text */}
              <Typography color={colors.greenAccent[400]} variant="h6" fontWeight="600">
                Trial tokens remaining:&nbsp;
              </Typography>

              {/* Remaining appraisals number */}
              <Typography color={colors.grey[100]} variant="h6" fontWeight="600">
                {appraisalStats.remaining_appraisals}
              </Typography>
            </>
          )}
        </Box>

        <Box display="flex">
          <Tooltip title={tooltipTitle}>
            <IconButton onClick={colorMode.toggleColorMode}>
              {isDarkMode ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings">
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Account">
            <IconButton onClick={handleAccountIconClick}>
              <PersonOutlinedIcon />
            </IconButton>
          </Tooltip>
          <NotificationDropdown />
        </Box>

        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: colors.primary[500],
            }
          }}
        >
          <MenuItem component={Link} to="/profile" onClick={handleClose}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem component={Link} to="/profile" onClick={handleClose}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAddIcon fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={initiateLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Popover>
      </Box>

      <Dialog open={logoutDialogOpen} PaperProps={{ sx: { backgroundColor: colors.primary[600] } }}>
        <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={20} sx={{ marginRight: 2 }} />
          <Typography>Logging out...</Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Topbar;