// ** React Imports
// import { useState } from 'react'
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

// ** MUI Imports
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import RadioGroup from '@mui/material/RadioGroup'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControlLabel from '@mui/material/FormControlLabel'

const TabInfo = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box mt="10px" mb="20px" backgroundColor={colors.primary[400]} >
      <CardContent>
        <form>
          <Grid container spacing={7}>
            <Grid item xs={12} sx={{ marginTop: 2.8 }}>
              <TextField
                fullWidth
                multiline
                label='Bio'
                minRows={2}
                placeholder='Bio'
                defaultValue='The name’s John Doe 😎😀😍.'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth type='number' label='Phone' placeholder='(123) 456-7890' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <FormLabel sx={{ fontSize: '0.875rem' }}>Gender</FormLabel>
                <RadioGroup row defaultValue='male' aria-label='gender' name='account-settings-info-radio' color='error'>
                  <FormControlLabel value='male' label='Male' control={<Radio sx={{ '& .MuiSvgIcon-root': { color: colors.greenAccent[500] }}} />} />
                  <FormControlLabel value='female' label='Female' control={<Radio sx={{ '& .MuiSvgIcon-root': { color: colors.greenAccent[500] }}} />} />
                  <FormControlLabel value='other' label='Other' control={<Radio sx={{ '& .MuiSvgIcon-root': { color: colors.greenAccent[500] }}} />} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select label='Country' defaultValue='Kenya'>
                  <MenuItem value='Kenya'>Kenya</MenuItem>
                  <MenuItem value='Other'>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id='form-layouts-separator-multiple-select-label'>Languages</InputLabel>
                <Select
                  multiple
                  defaultValue={['English']}
                  id='account-settings-multiple-select'
                  labelId='account-settings-multiple-select-label'
                  input={<OutlinedInput label='Languages' id='select-multiple-language' />}
                >
                  <MenuItem value='English'>English</MenuItem>
                  <MenuItem value='French'>French</MenuItem>
                  <MenuItem value='Spanish'>Spanish</MenuItem>
                  <MenuItem value='Portuguese'>Portuguese</MenuItem>
                  <MenuItem value='Italian'>Italian</MenuItem>
                  <MenuItem value='German'>German</MenuItem>
                  <MenuItem value='Arabic'>Arabic</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' sx={{ marginRight: 3.5, backgroundColor: colors.orangeAccent[911] }}>
                Save Changes
              </Button>
              {/* Remove Reset button onClick handler */}
              <Button type='reset' variant='outlined' color='secondary'>
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Box>
  )
}

export default TabInfo
