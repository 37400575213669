import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, AppBar, Tabs, Tab, Slide } from "@mui/material";
import { tokens } from "../../../../theme";
import DepositValue from './transactionvalue';
import DepositCount from './transaction count';
import DepositThirtyDays from './30 days/30days';
import DepositSixtyDays from './60 days';
import DepositNinetyDays from './90 days';
import AllDeposits from './all transactions';
import DepositsLineChart from './DepositsLineChart';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2), 
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2), 
  },
}));

const Deposits = ({ customerId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [averageDeposit, setAverageDeposit] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [mostFrequentTransactions, setMostFrequentTransactions] = useState([]);
  const [recipientData, setRecipientData] = useState([]);

  useEffect(() => {
    // Fetch data from backend route to get transaction details
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/deposits-transaction-summary/${customerId}`)
      .then(response => response.json())
      .then(data => {
        setTransactionCount(data.transaction_count);
        setAverageDeposit(parseFloat(data.average_deposit));
        setTotalDeposit(parseFloat(data.total_deposit));
      })
      .catch(error => console.error('Error fetching deposit transactions:', error));
  
    // Fetch data from backend route to get most frequent transactions
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/deposit-frequent-transactions/${customerId}`)
      .then(response => response.json())
      .then(data => {
        setMostFrequentTransactions(data.most_frequent_transactions);
        setRecipientData(data.recipient_data); // Set recipient data directly from the response
      })
      .catch(error => console.error('Error fetching most frequent deposits:', error));
  }, [customerId]);

  // Function to format numbers with commas
  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); 
  };

  return (
    <Box m="5px" mt={2}>
      <Grid container justifyContent="center" spacing={1}>
        {/* Grid items for cards */}
        {[1, 2, 3].map((index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: colors.primary[400],
              marginTop: theme.spacing(1),
            }}>
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight="600"
                  color={colors.grey[100]}
                  gutterBottom
                >
                  {index === 1 ? 'Total Amount Deposited:' : index === 2 ? 'Average Customer Deposit:' : 'Total No. of Transactions:'}
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  {index === 1 ? `ksh ${typeof totalDeposit === 'number' ? numberWithCommas(totalDeposit.toFixed(2)) : ''}` : index === 2 ? `ksh ${typeof averageDeposit === 'number' ? numberWithCommas(averageDeposit.toFixed(2)) : ''}` : transactionCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br/>
      <Typography
        variant="h5"
        fontWeight="600"
        mt={1}
        color={colors.grey[100]}
        >
          Top 3 Deposit Agents:
        </Typography>
        {/* Modified container div */}
        <div className={classes.chartContainer}>
        {mostFrequentTransactions.map((recipient, index) => (
            <Box key={index} sx={{
              backgroundColor: colors.primary[400],
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '250px',
              width: '32%',
              padding: '5px',
            }}>
              {/* <Typography variant="h6" color={colors.greenAccent[400]}>
                {recipient.toUpperCase()}
              </Typography> */}
              {/* Pass recipient's data to TransferLineChart component */}
              <DepositsLineChart data={recipientData.filter(data => data.recipient === recipient)} />
            </Box>
          ))}
        </div>
        <br/>
       {/* Tabs */}
       <AppBar position="static" sx={{backgroundColor: colors.primary[500], borderRadius: 2}}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
          >
            {['Transaction Value', 'Transaction Count', '30 Days', '60 Days', '90 Days', 'All Transactions'].map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={{fontWeight: '600'}}
              />
            ))}
          </Tabs>
        </AppBar>
        {/* Slide for tab content */}
        <Slide direction="left" in={activeTab === 0} mountOnEnter unmountOnExit>
          <div>
          <DepositValue customerId={customerId} />
          </div>
        </Slide>
        <Slide direction="left" in={activeTab === 1} mountOnEnter unmountOnExit>
          <div>
          <DepositCount customerId={customerId} />
          </div>
        </Slide>
        <Slide direction="left" in={activeTab === 2} mountOnEnter unmountOnExit>
          <div>
          <DepositThirtyDays customerId={customerId} />
          </div>
        </Slide>
        <Slide direction="left" in={activeTab === 3} mountOnEnter unmountOnExit>
          <div>
          <DepositSixtyDays customerId={customerId} />
          </div>
        </Slide>
        <Slide direction="left" in={activeTab === 4} mountOnEnter unmountOnExit>
          <div>
          <DepositNinetyDays customerId={customerId} />
          </div>
        </Slide>
        <Slide direction="left" in={activeTab === 5} mountOnEnter unmountOnExit>
          <div>
          <AllDeposits customerId={customerId} />
          </div>
        </Slide>
      </Box>
    );
};

export default Deposits;

