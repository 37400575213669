import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography, useTheme } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import CompanyMonthlyLBFTarget from './Company Summary Sales/LbfTarget';
import CompanyMonthlyIPFTarget from './Company Summary Sales/IpfTarget';
import CompanyMonthlyLBFNetSales from './Company Summary Sales/LbfNetSales';
import CompanyMonthlyIPFNetSales from './Company Summary Sales/IpfNetSales';
import MonthlyCompanyLBFTargetPercentage from './Company Summary Sales/TargetPercentage';
import MonthlyCompanyIPFTargetPercentage from './Company Summary Sales/IpfTargetPercentage';
import CompanyMonthlyIPFOLB from './Company Summary Sales/IpfOlb';
import CompanyMonthlyLBFOLB from './Company Summary Sales/LbfOlb';
import TeamLBFMonthlySalesPerformance from './Team Sales/lbfTeamPerfomance';
import TeamIPFMonthlySalesPerformance from './Team Sales/IpfTeamSales';
import TeamGlobalMonthlySalesPerformance from './Team Sales/teamSales';
import TeamLBFBarChart from './Team Sales/TeamLBFBarChart';
import TeamIPFBarChart from './Team Sales/TeamIPFBarChart';
import LBFChartPerformance from './Team Sales/LBFChartPerformance';
import IPFChartPerformance from './Team Sales/IPFTeamPerfomance';
import TeamsSalesBarChart from './Team Sales/TeamsSalesBarChart';
import PortfolioVisualization from './Team Sales/PortfolioVisualization';
import { tokens } from '../../../theme';

export default function AslPortfolio() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().toLocaleString('default', { month: 'long' });

  const [selectedProduct, setSelectedProduct] = useState('LBF');
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const renderTeamPerformance = () => {
    switch (selectedProduct) {
      case 'LBF':
        return <TeamLBFMonthlySalesPerformance selectedMonth={selectedMonth} selectedYear={selectedYear} />;
      case 'IPF':
        return <TeamIPFMonthlySalesPerformance selectedMonth={selectedMonth} selectedYear={selectedYear} />;
      case 'Global':
      default:
        return <TeamGlobalMonthlySalesPerformance selectedMonth={selectedMonth} selectedYear={selectedYear} />;
    }
  };

  return (
    <Box sx={{ marginTop: 1 }}>
      {/* Product, Month, and Year Select Boxes */}
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
          <Grid item xs={6} md={3}>
            <InputLabel htmlFor="product-select">Product</InputLabel>
            <Select
              id="product-select"
              value={selectedProduct}
              onChange={handleProductChange}
              sx={{ minWidth: 120 }}
            >
              <MenuItem value="LBF">LBF</MenuItem>
              <MenuItem value="IPF">IPF</MenuItem>
              <MenuItem value="Global">Global</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={6} md={3}>
            <InputLabel htmlFor="month-select">Month</InputLabel>
            <Select
              id="month-select"
              value={selectedMonth}
              onChange={handleMonthChange}
              sx={{ minWidth: 120 }}
            >
              {[
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December',
              ].map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6} md={3}>
            <InputLabel htmlFor="year-select">Year</InputLabel>
            <Select
              id="year-select"
              value={selectedYear}
              onChange={handleYearChange}
              sx={{ minWidth: 120 }}
            >
              {[...Array(10).keys()].map((i) => {
                const year = currentYear - i;
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </Box>

      {/* Conditionally Rendered Grid Layout for LBF or IPF Components */}
      <Box sx={{ marginTop: 3 }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {selectedProduct === 'LBF' ? (
            <>
              <Grid item xs={12} md={3}>
                <CompanyMonthlyLBFTarget selectedMonth={selectedMonth} selectedYear={selectedYear} />
              </Grid>
              <Grid item xs={12} md={3}>
                <CompanyMonthlyLBFNetSales selectedMonth={selectedMonth} selectedYear={selectedYear} />
              </Grid>
              <Grid item xs={12} md={3}>
                <MonthlyCompanyLBFTargetPercentage selectedMonth={selectedMonth} selectedYear={selectedYear} />
              </Grid>
              <Grid item xs={12} md={3}>
                <CompanyMonthlyLBFOLB selectedMonth={selectedMonth} selectedYear={selectedYear} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={3}>
                <CompanyMonthlyIPFTarget selectedMonth={selectedMonth} selectedYear={selectedYear} />
              </Grid>
              <Grid item xs={12} md={3}>
                <CompanyMonthlyIPFNetSales selectedMonth={selectedMonth} selectedYear={selectedYear} />
              </Grid>
              <Grid item xs={12} md={3}>
                <MonthlyCompanyIPFTargetPercentage selectedMonth={selectedMonth} selectedYear={selectedYear} />
              </Grid>
              <Grid item xs={12} md={3}>
                <CompanyMonthlyIPFOLB selectedMonth={selectedMonth} selectedYear={selectedYear} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      {/* Team Monthly Sales Performance */}
      <Box sx={{ marginTop: 3 }}>
        {selectedProduct === 'Global' ? (
          <>
            <TeamsSalesBarChart selectedMonth={selectedMonth} selectedYear={selectedYear} />
            <PortfolioVisualization/>
          </>
        ) : (
          <Grid container spacing={2}>
            {selectedProduct === 'LBF' ? (
              <>
                <Grid item xs={12} md={6}>
                  <TeamLBFBarChart selectedMonth={selectedMonth} selectedYear={selectedYear} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <LBFChartPerformance selectedMonth={selectedMonth} selectedYear={selectedYear} />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <TeamIPFBarChart selectedMonth={selectedMonth} selectedYear={selectedYear} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <IPFChartPerformance selectedMonth={selectedMonth} selectedYear={selectedYear} />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Box>

      {/* Team Members Monthly Sales Performance */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Team Sales Performance:
        </Typography>
        {renderTeamPerformance()}
      </Box>
    </Box>
  );
}
