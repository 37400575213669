import React, { useState } from 'react';
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  Button,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import ModelSettings from './modelsettings';
import { useTheme } from "@mui/material";
import { tokens } from '../../theme';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import MuiAlert from '@mui/material/Alert';
import CustomizeScore from './CustomizeScore';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value.toFixed(2)}>
      {children}
    </Tooltip>
  );
}

const CreditMix = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [capabilityScore, setCapabilityScore] = useState(0.5);
  const [characterScore, setCharacterScore] = useState(0.5);
  const [isApplying, setIsApplying] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleScoreChange = (event, newValue) => {
    setCapabilityScore(newValue);
    setCharacterScore(1 - newValue);
  };

  const handleApplyChanges = () => {
    setIsApplying(true); 
    setTimeout(() => {
      setIsApplying(false); 
      setShowSnackbar(true);
    }, 3000);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Box mt={2}>
      <Box mb={3}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={5}>
            <Card
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                textAlign: 'center',
                backgroundColor: colors.primary[201],
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Overall Score:
                </Typography>
                <CustomizeScore height={150} capabilityScore={capabilityScore} characterScore={characterScore} />
                <Divider />
                <Divider />
                <Box mt={3}>
                  <Typography gutterBottom>Adjust Scores:</Typography>
                  <Slider
                    value={capabilityScore}
                    onChange={handleScoreChange}
                    aria-labelledby="score-slider"
                    valueLabelDisplay="auto"
                    slots={{
                      valueLabel: ValueLabelComponent,
                    }}
                    min={0}
                    max={1}
                    step={0.01}
                    sx={{
                      color: colors.grey[100],
                      '& .MuiSlider-thumb': {
                        backgroundColor: colors.grey[100],
                      },
                      '& .MuiSlider-track': {
                        backgroundColor: colors.myAccent[200],
                      },
                      '& .MuiSlider-rail': {
                        backgroundColor: colors.grey[300],
                      },
                    }}
                  />
                  <Box mt={3} mb={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleApplyChanges}
                      disabled={isApplying}
                      sx={{
                        ...(isApplying && {
                          backgroundColor: "secondary.main",
                          color: "white",
                          opacity: 1, 
                          cursor: "default",
                        }),
                      }}
                    >
                      {isApplying ? (
                        <>
                          Applying
                          <CircularProgress size={20} sx={{ ml: 1, color: colors.grey[100] }} />
                        </>
                      ) : (
                        "Apply Changes"
                      )}
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box mt={3} display="flex">
                  <Box flex={1} mr={1}>
                    <Typography
                      variant="h6"
                      align="center"
                      fontWeight="600"
                      sx={{ textDecoration: 'underline' }}
                    >
                      Carren Model
                    </Typography>
                    <Box mt={1}>
                      <Box display="flex" alignItems="center" mt={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#EF4444" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>0.01 - 0.40:</strong> Poor
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#F97316" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>0.41 - 0.55:</strong> Fair
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#BEF264" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>0.56 - 0.65:</strong> Good
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#22C55E" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>0.66 - 0.80:</strong> Very Good
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1} mb={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#4F46E5" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>0.81 - 1.00:</strong> Excellent
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box flex={1} ml={1}>
                    <Typography
                      variant="h6"
                      align="center"
                      fontWeight="600"
                      sx={{ textDecoration: 'underline' }}
                    >
                      FICO Model
                    </Typography>
                    <Box mt={1}>
                      <Box display="flex" alignItems="center" mt={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#EF4444" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>300 - 579:</strong> Poor
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#F97316" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>580 - 669:</strong> Fair
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#BEF264" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>670 - 739:</strong> Good
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#22C55E" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>740 - 799:</strong> Very Good
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1} mb={1}>
                        <svg width={20} height={8}>
                          <rect width={20} height={8} rx={4} fill="#4F46E5" />
                        </svg>
                        <Typography variant="body1" ml={1}>
                          <strong>800 - 850:</strong> Excellent
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={7}>
            <Card
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                backgroundColor: colors.primary[400],
              }}
            >
              <CardContent>
                <ModelSettings />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          "Settings Applied!"
        </Alert>
       </Snackbar>
    </Box>
  );
};

export default CreditMix;

