import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { refreshAccessToken } from './auth';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      let token = Cookies.get('accessToken');
      if (!token) {
        token = await refreshAccessToken();
      }

      setIsAuthenticated(!!token);
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // Use environment variable for redirect URL
    const signInUrl = `${process.env.REACT_APP_FRONTEND_URL}/signin`;
    window.location.href = signInUrl;
    return null;
  }

  return children;
};

export default ProtectedRoute;
