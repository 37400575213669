import React, { useState,  useEffect } from 'react';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import axios from 'axios';
import { Box, Card, CardContent, Typography, Grid, Tabs, Tab, Slide, AppBar } from '@mui/material';


import CustomerTransfer from './customertransfer';
import CustomerPayments from './customerpayments';
import MoneyReceived from './money received';
import Withdrawals from './withdrawals';
import Deposits from './deposits';
import BankInjections from '../Insights/injections/bankInjections';

const FinancialSummary = ({ customerId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [transactionData, setTransactionData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0); // State to manage selected tab index

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); // Update selected tab index
  };

  useEffect(() => {
    // Function to fetch transaction data for a specific transaction type
    const fetchTransactionData = async (transactionType) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/customer-transactions/${customerId}`);
        const data = response.data;
        const transaction = data.find(item => item.transaction_type === transactionType);
        return transaction;
      } catch (error) {
        console.error('Error fetching transaction data:', error);
        return null;
      }
    };
  
    // Fetch transaction data for each transaction type
    const fetchTransactionDataForAllTypes = async () => {
      const types = ["TOTAL", "AGENT DEPOSIT", "RECEIVED MONEY", "AGENT WITHDRAWAL", "SEND MONEY", "LIPA NA M-PESA (PAYBILL)", "LIPA NA M-PESA (BUY GOODS)"];
      const transactions = {};
      for (const type of types) {
        const transaction = await fetchTransactionData(type);
        transactions[type] = transaction;
      }
      setTransactionData(transactions);
    };
  
    fetchTransactionDataForAllTypes();
  }, [customerId]);

  // Function to format numbers with commas
  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Box m="5px" mt={2}>
      <Grid container justifyContent="center" spacing={1}>
        {/* First Row */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              backgroundColor: colors.primary[400],
            }}>
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={colors.grey[100]}
                gutterBottom
              >
                Total Money In:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {transactionData && transactionData['TOTAL'] ? `ksh ${numberWithCommas(transactionData['TOTAL'].paid_in.toFixed(2))}` : 'Loading...'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              backgroundColor: colors.primary[400],
            }}>
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={colors.grey[100]}
                gutterBottom
              >
                Total Money out:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {transactionData && transactionData['TOTAL'] ? `ksh ${numberWithCommas(transactionData['TOTAL'].paid_out.toFixed(2))}` : 'Loading...'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              backgroundColor: colors.primary[400],
            }}>
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={colors.grey[100]}
                gutterBottom
              >
                Agent Deposits:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {transactionData && transactionData['AGENT DEPOSIT'] ? `ksh ${numberWithCommas(transactionData['AGENT DEPOSIT'].paid_in.toFixed(2))}` : 'Loading...'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              backgroundColor: colors.primary[400],
            }}>
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={colors.grey[100]}
                gutterBottom
              >
                Money Received:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {transactionData && transactionData['RECEIVED MONEY'] ? `ksh ${numberWithCommas(transactionData['RECEIVED MONEY'].paid_in.toFixed(2))}` : 'Loading...'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              backgroundColor: colors.primary[400],
            }}>
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={colors.grey[100]}
                gutterBottom
              >
                Agent Withdrawals:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {transactionData && transactionData['AGENT WITHDRAWAL'] ? `ksh ${numberWithCommas(transactionData['AGENT WITHDRAWAL'].paid_out.toFixed(2))}` : 'Loading...'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Second Row */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            backgroundColor: colors.primary[400],
          }}>
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={colors.grey[100]}
                gutterBottom
              >
                Customer Transfers:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {transactionData && transactionData['SEND MONEY'] ? `ksh ${numberWithCommas(transactionData['SEND MONEY'].paid_out.toFixed(2))}` : 'Loading...'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            backgroundColor: colors.primary[400],
          }}>
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={colors.grey[100]}
                gutterBottom
              >
                Paybill Payments:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {transactionData && transactionData['LIPA NA M-PESA (PAYBILL)'] ? `ksh ${numberWithCommas(transactionData['LIPA NA M-PESA (PAYBILL)'].paid_out.toFixed(2))}` : 'Loading...'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            backgroundColor: colors.primary[400],
          }}>
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="600"
                color={colors.grey[100]}
                gutterBottom
              >
                Merchant Payments:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {transactionData && transactionData['LIPA NA M-PESA (BUY GOODS)'] ? `ksh ${numberWithCommas(transactionData['LIPA NA M-PESA (BUY GOODS)'].paid_out.toFixed(2))}` : 'Loading...'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <br/>
      {/* Tabs */}
      <Box sx={{ width: '100%' }}>
        <AppBar position="static" sx={{backgroundColor: colors.primary[500], borderRadius: 2}}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor='secondary'
            variant="scrollable"
            scrollButtons="auto"
            fontWeight="bold"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="Customer Transfer"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Customer Payments"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Money Received"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Withdrawals"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Deposits"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Bank Transfers"
              sx={{fontWeight: '600'}}
            />
          </Tabs>
        </AppBar>
      </Box>

      {/* Render CustomerTransfer or CustomerPayments component based on selected tab */}
      <Slide direction="left" in={selectedTab === 0} mountOnEnter unmountOnExit>
        <div>
          <CustomerTransfer customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 1} mountOnEnter unmountOnExit>
        <div>
          <CustomerPayments customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 2} mountOnEnter unmountOnExit>
        <div>
          <MoneyReceived customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 3} mountOnEnter unmountOnExit>
        <div>
          <Withdrawals customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 4} mountOnEnter unmountOnExit>
        <div>
          <Deposits customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 5} mountOnEnter unmountOnExit>
        <div>
          <BankInjections customerId={customerId} />
        </div>
      </Slide>
    </Box>
  );
};

export default FinancialSummary;

