// LoanSettings.js
import React from 'react';
import { Box, Card, CardContent, Select, MenuItem, Typography, Switch, Button, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const LoanSettings = ({ interestType, setInterestType, multipleBorrowings, setMultipleBorrowings }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Card sx={{ backgroundColor: colors.primary[400] }}>
      <CardContent
        sx={{
          p: 2,
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h6" fontWeight="600" sx={{ color: colors.grey[100] }}>Settings:</Typography>
        
        <Box mt={2}>
          <Typography sx={{ color: colors.grey[100] }}>Interest Type</Typography>
          <Select
            value={interestType}
            onChange={(e) => setInterestType(e.target.value)}
            fullWidth
          >
            <MenuItem value="flat rate">Flat Rate</MenuItem>
            <MenuItem value="reducing balance">Reducing Balance</MenuItem>
          </Select>
        </Box>
        
        <Box mt={2} display="flex" alignItems="center">
          <Typography>Multiple Borrowings:</Typography>
          <Switch
            checked={multipleBorrowings}
            onChange={(e) => setMultipleBorrowings(e.target.checked)}
            style={{ marginLeft: 'auto' }}
            color="secondary"
          />
        </Box>
        
        <Button
          variant="contained"
          onClick={() => alert('Settings applied!')}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            "&:hover": {
              backgroundColor: colors.myAccent[800],
            },
          }}
          style={{ marginTop: '1rem' }}
        >
          Apply
        </Button>
      </CardContent>
    </Card>
  );
};

export default LoanSettings;
