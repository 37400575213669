import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../../../theme";
import axios from "axios"; // Import Axios for HTTP requests

import Header from "../../../../../../components/Header";

const MerchantTransactionCount = ({ customerId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [recipientSummary, setRecipientSummary] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "recipient_name",
      headerName: "Merchant Name",
      flex: 1,
      valueFormatter: (params) => params.value.toUpperCase() // Convert recipient names to uppercase
    },
    { field: "transaction_count", headerName: "Transaction Count", flex: 1 },
    {
      field: "total_amount_received",
      headerName: "Total Transaction Amount",
      flex: 1,
      valueFormatter: (params) =>
        `ksh ${Number(params.value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`
    },
    {
      field: "average_transaction_amount",
      headerName: "Average Transaction Amount",
      flex: 1,
      valueFormatter: (params) =>
        `ksh ${Number(params.value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`
    },
  ];

  useEffect(() => {
    // Fetch recipient summary data from backend when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/merchant-transaction-count/${customerId}`);
        setRecipientSummary(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching merchant transaction count data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  return (
    <Box m="20px">
      <Header subtitle="Top 10 Merchant Payments by Frequency Count:" />
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={recipientSummary}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default MerchantTransactionCount;
