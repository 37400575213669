import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, Tabs, Tab, Slide, AppBar} from '@mui/material';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import InjectionProgressCircle from './injectionprogresscircle';
import InjectionsBarChart from './InjectionsBarChart';
import { tokens } from "../../../../theme";
import Link from '@mui/material/Link';
import { useTheme } from "@mui/material";
import axios from 'axios';
import InjectionsTable from './injectionsTable';
import BankInjections from './bankInjections';

function preventDefault(event) {
  event.preventDefault();
}

const Injections = ({ customerId, monthlyInjections }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [injectionsData, setInjectionsData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/injections/${customerId}`);
        setInjectionsData(response.data);
      } catch (error) {
        console.error('Error fetching injections data:', error);
        setError(error.message);
      }
    };
    fetchData();
  }, [customerId]);

  const bankAccountPercentage = injectionsData ? parseFloat(injectionsData.bank_account_percentage) : 0;
  const moneyReceivedPercentage = injectionsData ? parseFloat(injectionsData.money_received_percentage) : 0;
  const depositPercentage = injectionsData ? parseFloat(injectionsData.deposit_percentage) : 0;
  const paybillPercentage = injectionsData ? parseFloat(injectionsData.paybill_percentage) : 0;

  // Function to format numbers with commas
  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [activeTab, setActiveTab] = useState(0); 

  const sortMonthlyInjections = (monthlyInjections) => {
    return monthlyInjections.sort((a, b) => {
      // Extracting the month and year from the strings and converting them to Date objects
      const dateA = new Date(a.month);
      const dateB = new Date(b.month);
      // Comparing the Date objects
      return dateA - dateB;
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); 
  };

  if (error) {
    return <Typography variant="body1">Error: {error}</Typography>;
  }

  return (
    <Box mt="3px">
      <Box mb="1px" mt={1}>         
          <Typography variant="h6" color={colors.grey[100]}>
            Cashflow Injection Habits: 
          </Typography>
        </Box>
      <Box m={1}>
        <Box mt={1}>
          <Grid container spacing={1}>
            {/* Injections Totals */}
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <AddCardOutlinedIcon />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Total Cash Injections:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold">
                    ksh {injectionsData ? numberWithCommas(injectionsData.total_injections) : 'Loading...'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                position: 'relative', // Adding position relative to create a positioning context for absolute positioning
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    From Bank Accounts:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                      ksh {injectionsData ? numberWithCommas(injectionsData.total_bank_account) : 'Loading...'}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <InjectionProgressCircle progress={bankAccountPercentage / 100} size={25} />
                  <Typography variant="h6" ml={1}>
                    {bankAccountPercentage}%
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Card sx={{
              width: '100%',
              position: 'relative', // Adding position relative to create a positioning context for absolute positioning
              backgroundColor: colors.primary[400],
            }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  From Individuals:
                </Typography>
                <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                  ksh {injectionsData ? numberWithCommas(injectionsData.total_money_received) : 'Loading...'}
                </Typography>
              </CardContent>
              <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                <InjectionProgressCircle progress={moneyReceivedPercentage / 100} size={25} />
                <Typography variant="h6" ml={1}>
                  {moneyReceivedPercentage}%
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>
        </Box>
        {/* Row 2 */}
        <Box mt={-2.5}>
          <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
          </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                position: 'relative',
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    From Paybills:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    ksh {injectionsData ? numberWithCommas(injectionsData.paybill_total) : 'Loading...'}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <InjectionProgressCircle progress={paybillPercentage / 100} size={25} />
                  <Typography variant="h6" ml={1}>
                    {paybillPercentage}%
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Card sx={{
                width: '100%',
                position: 'relative', // Adding position relative to create a positioning context for absolute positioning
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    From Agent Deposits:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    ksh {injectionsData ? numberWithCommas(injectionsData.total_deposit) : 'Loading...'}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <InjectionProgressCircle progress={depositPercentage / 100} size={25} />
                  <Typography variant="h6" ml={1}>
                    {depositPercentage}%
                  </Typography>
                </div>
              </Card>
            </Grid>
          </Grid>
          <br/>
          <Typography
            variant="h6"
            color={colors.grey[100]}
          >
            Cashflow Injection Trend:
          </Typography>
          <br/>
          <div sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop:3,
          }}>
            <Box
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              width='100%'
              height="250px"
              m="-20px 0 0 0"
              padding={2}
              sx={{
                width: '95%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {injectionsData && injectionsData.monthly_injections ? (
                <InjectionsBarChart monthlyInjections={sortMonthlyInjections(injectionsData.monthly_injections)} />
              ) : (
                <Typography variant="body1">No data available</Typography>
              )}
            </Box>
          </div>
          <br/>
          <AppBar position="static" sx={{backgroundColor: colors.primary[500], borderRadius: 2}}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor='secondary'
              variant="scrollable"
              scrollButtons="auto"
              fontWeight="bold"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                label="Injections Summary"
                sx={{fontWeight: '600'}}
              />
              <Tab
                label="Bank Injections"
                sx={{fontWeight: '600'}}
              />
            </Tabs>
          </AppBar>
          <Slide direction="left" in={activeTab === 0} mountOnEnter unmountOnExit>
            <div>
              <InjectionsTable customerId={customerId}  />
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 1} mountOnEnter unmountOnExit>
            <div>
              <BankInjections customerId={customerId}  />
            </div>
          </Slide>
          <br />
        </Box>
      </Box>        
    </Box>
  );
};

export default Injections;
