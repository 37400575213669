import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  grey: {
    100: mode === "dark" ? "#e0e0e0" : "#141414",
    200: mode === "dark" ? "#c2c2c2" : "#292929",
    300: mode === "dark" ? "#a3a3a3" : "#3d3d3d",
    400: mode === "dark" ? "#858585" : "#525252",
    500: mode === "dark" ? "#666666" : "#666666",
    600: mode === "dark" ? "#525252" : "#858585",
    700: mode === "dark" ? "#3d3d3d" : "#a3a3a3",
    800: mode === "dark" ? "#292929" : "#c2c2c2",
    900: mode === "dark" ? "#141414" : "#e0e0e0",
  },
  primary: {
    100: mode === "dark" ? "#d0d1d5" : "#040509",
    200: mode === "dark" ? "#a1a4ab" : "#080b12",
    201: mode === "dark" ? "#1F2A40" : "#ffffff",
    300: mode === "dark" ? "#727681" : "#fafafa",
    400: mode === "dark" ? "#1F2A40" : "#f2f0f0",
    500: mode === "dark" ? "#141b2d" : "#ffffff",
    600: mode === "dark" ? "#101624" : "#f2f0f0",
    700: mode === "dark" ? "#0c101b" : "#727681",
    800: mode === "dark" ? "#080b12" : "#a1a4ab",
    900: mode === "dark" ? "#141b2d" : "#d0d1d5",
  },
  greenAccent: {
    0: mode === "dark" ? "#065E49" : "#FFF5CC",
    100: mode === "dark" ? "#dbf5ee" : "#0f2922",
    200: mode === "dark" ? "#b7ebde" : "#1e5245",
    300: mode === "dark" ? "#94e2cd" : "#004B50",
    400: mode === "dark" ? "#70d8bd" : "#3da58a",
    500: mode === "dark" ? "#4cceac" : "#118D57",
    600: mode === "dark" ? "#3da58a" : "#007867",
    700: mode === "dark" ? "#2e7c67" : "#94e2cd",
    800: mode === "dark" ? "#1e5245" : "#b7ebde",
    900: mode === "dark" ? "#0f2922" : "#dbf5ee",
  },
  redAccent: {
    100: mode === "dark" ? "#f8dcdb" : "#2c100f",
    200: mode === "dark" ? "#f1b9b7" : "#58201e",
    300: mode === "dark" ? "#e99592" : "#832f2c",
    400: mode === "dark" ? "#e2726e" : "#af3f3b",
    500: mode === "dark" ? "#db4f4a" : "#db4f4a",
    600: mode === "dark" ? "#af3f3b" : "#e2726e",
    700: mode === "dark" ? "#832f2c" : "#e99592",
    800: mode === "dark" ? "#58201e" : "#f1b9b7",
    900: mode === "dark" ? "#2c100f" : "#f8dcdb",
  },
  blueAccent: {
    100: mode === "dark" ? "#e1e2fe" : "#151632",
    200: mode === "dark" ? "#c3c6fd" : "#2a2d64",
    300: mode === "dark" ? "#a4a9fc" : "#3e4396",
    400: mode === "dark" ? "#868dfb" : "#535ac8",
    500: mode === "dark" ? "#6870fa" : "#6870fa",
    600: mode === "dark" ? "#535ac8" : "#868dfb",
    700: mode === "dark" ? "#186049" : "#5BE49B",
    800: mode === "dark" ? "#2a2d64" : "#c3c6fd",
    900: mode === "dark" ? "#151632" : "#e1e2fe",
  },
  orangeAccent: {
    100: mode === "dark" ? "#3e4396" : "#e6eef9",
    200: mode === "dark" ? "#3e4396" : "#A2DAAD",
    300: mode === "dark" ? "#a4a9fc" : "#DFE3E8",
    400: mode === "dark" ? "#868dfb" : "#535ac8",
    500: mode === "dark" ? "#FFAB00" : "#FFAC82",
    600: mode === "dark" ? "#535ac8" : "#868dfb",
    700: mode === "dark" ? "#3e4396" : "#5BE49B",
    800: mode === "dark" ? "#3e4396" : "#00695c",
    900: mode === "dark" ? "#151632" : "#e1e2fe",
    911: mode === "dark" ? "#118D57" : "#118D57",
  },
  myAccent: {
    100: mode === "dark" ? "#042440" : "#00695c",
    101: mode === "dark" ? "#006C9C" : "#77ED8B",
    102: mode === "dark" ? "#5119B7" : "#EFD6FF",
    103: mode === "dark" ? "#065E49" : "#D3FCD2",
    104: mode === "dark" ? "#006C9C" : "#FFD666",
    105: mode === "dark" ? "#8E33FF" : "#C684FF",
    106: mode === "dark" ? "#E3651D" : "#FFD666",
    200: mode === "dark" ? "#118D57" : "#065E49",
    201: mode === "dark" ? "#5119B7" : "#065E49",
    300: mode === "dark" ? "#a3a3a3" : "#f2f0f0",
    400: mode === "dark" ? "#454F5B" : "#DFE3E8",
    500: mode === "dark" ? "#6A65FE" : "#000000",
    600: mode === "dark" ? "#ffffff" : "#000000",
    700: mode === "dark" ? "#3e4396" : "#5BE49B",
    800: mode === "dark" ? "#00A76F" : "#C8FAD6",
    900: mode === "dark" ? "#151632" : "#e1e2fe",
  },
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
