import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import axios from "axios"; // Import Axios for HTTP requests

import Header from "../../../../components/Header";

const RestaurantSpend = ({ customerId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [RestaurantTransactions, setRestaurantTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueFormatter: (params) =>
        `ksh ${Number(params.value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`
    },
    { field: "details", headerName: "Description", flex: 2 },
    { field: "receipt_no", headerName: "Mpesa Receipt No.", flex: 1 },
    { field: "completion_time", headerName: "Transaction Date Time", flex: 1 },
  ];

  useEffect(() => {
    // Fetch transactions from the last 30 days from the backend when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/restaurant-transactions/${customerId}`);
        setRestaurantTransactions(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching restaurant transactions:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  return (
    <Box m="10px">
      <Header subtitle="Restaurant Transactions:" />
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={RestaurantTransactions}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default RestaurantSpend;
