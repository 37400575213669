import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, Tabs, Tab, Slide, AppBar } from '@mui/material';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import Repayments from './repayments';
import PersonalFinanceChart from './financeChart';
import { tokens } from "../../../../theme";
import { useTheme } from "@mui/material";
import PersonalFinanceProgressCircle from './personalfinanceprogresscircle';
import Loans from './loans';
import Mshwari from './mshwari';
import Savings from './savings';

const PersonalFinance = ({customerId}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [personalFinanceData, setpersonalFinanceData] = useState(null);
  const [monthlyPersonalFinance, setMonthlyPersonalFinance] = useState(null);
  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState(0); 

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); 
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/personal-finance/${customerId}`);
        const data = await response.json();
        setpersonalFinanceData(data);
        setMonthlyPersonalFinance(data.monthly_personal_finance);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Personal Finance data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [customerId]);

  return (
    <Box mt="3px">
      <Box mb="1px" mt={1}>         
          <Typography variant="h6" color={colors.grey[100]}>
            Personal Finance Habits: 
          </Typography>
        </Box>
      <Box m={1}>
        <Box mt={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Personal Finance:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold">
                    Total: {personalFinanceData ? `ksh ${numberWithCommas(personalFinanceData.total_personal_finance.toFixed(2))}` : ""}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                position: 'relative',
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Loans Amount:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    {personalFinanceData ? `ksh ${numberWithCommas(personalFinanceData.category_totals["Loans Spend"].toFixed(2))}` : ""}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <PersonalFinanceProgressCircle progress={personalFinanceData ? personalFinanceData.category_percentages["Loans Percentage"] / 100 : 0} size={25} />
                  <Typography variant="h6" ml={1}>
                    {personalFinanceData ? `${personalFinanceData.category_percentages["Loans Percentage"]} %` : ""}
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Card sx={{
              width: '100%',
              position: 'relative', 
              backgroundColor: colors.primary[400],
            }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Total Loans Repayment:
                </Typography>
                <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                  {personalFinanceData ? `ksh ${numberWithCommas(personalFinanceData.category_totals["Repayment Spend"].toFixed(2))}` : ""}
                </Typography>
              </CardContent>
              <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                <PersonalFinanceProgressCircle progress={personalFinanceData ? personalFinanceData.category_percentages["Repayment Percentage"] / 100 : 0} size={25} />
                <Typography variant="h6" ml={1}>
                  {personalFinanceData ? `${personalFinanceData.category_percentages["Repayment Percentage"]} %` : ""}
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>
        </Box>
        {/* Row 2 */}
        <Box mt={1}>
          <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                position: 'relative',
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Savings:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    {personalFinanceData ? `ksh ${numberWithCommas(personalFinanceData.category_totals["Savings Spend"].toFixed(2))}` : ""}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <PersonalFinanceProgressCircle progress={personalFinanceData ? personalFinanceData.category_percentages["Savings Percentage"] / 100 : 0} size={25} />
                  <Typography variant="h6" ml={1}>
                    {personalFinanceData ? `${personalFinanceData.category_percentages["Savings Percentage"]} %` : ""}
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                position: 'relative', 
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Mshwari Spend:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    {personalFinanceData ? `ksh ${numberWithCommas(personalFinanceData.category_totals["M-Shwari Spend"].toFixed(2))}` : ""}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <PersonalFinanceProgressCircle progress={personalFinanceData ? personalFinanceData.category_percentages["M-Shwari Percentage"] / 100 : 0} size={25} />
                  <Typography variant="h6" ml={1}>
                    {personalFinanceData ? `${personalFinanceData.category_percentages["M-Shwari Percentage"]} %` : ""}
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Card sx={{
                width: '100%',
                position: 'relative', // Adding position relative to create a positioning context for absolute positioning
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Linked Bank Accounts:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    3
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                </div>
              </Card>
            </Grid>
          </Grid>
          <br/>
          <Typography
            variant="h6"
            color={colors.grey[100]}
          >
            Spending Habits:
          </Typography>
          <br/>
          <div sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop:3,
          }}>
            <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                width='100%'
                height="250px"
                m="-20px 0 0 0"
                padding={2}
                sx={{
                  width: '95%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
            >
              <PersonalFinanceChart monthlyPersonalFinance={monthlyPersonalFinance} />
            </Box>
          </div>
          <br/>
          <AppBar position="static" sx={{backgroundColor: colors.primary[500], borderRadius: 2}}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              variant="scrollable"
              sx={{ indicator: {
                backgroundColor: colors.mode === 'dark' ? tokens('dark').myAccent[200] : tokens('light').myAccent[200],
              }}}
            >
              <Tab
                label="Loan History"
                sx={{ fontWeight: '600'}}
              />
              <Tab
                label="Loan Repayments"
                sx={{ fontWeight: '600'}}
              />
              <Tab
                label="M-Shwari"
                sx={{ fontWeight: '600'}}
              />
               <Tab
                label="Savings"
                sx={{ fontWeight: '600'}}
              />
            </Tabs>
          </AppBar>

          <Slide direction="left" in={activeTab === 0} mountOnEnter unmountOnExit>
            <div>
              <Loans customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 1} mountOnEnter unmountOnExit>
            <div>
              <Repayments customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 2} mountOnEnter unmountOnExit>
            <div>
              <Mshwari customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 3} mountOnEnter unmountOnExit>
            <div>
              <Savings customerId={customerId}/>
            </div>
          </Slide>
        </Box>
      </Box>        
    </Box>
  );
};

export default PersonalFinance;
