import React from 'react';
import { Tabs, Tab, Box, Typography, AppBar } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header';

import ConstructionIcon from '@mui/icons-material/Construction';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LockIcon from '@mui/icons-material/Lock';
import SettingsIcon from '@mui/icons-material/Settings';

import CreditMix from './creditmix';
import Team from '../team';
import Billing from '../profile/billing';
import LoanConfiguration from './loan settings/loanConfig';
import SettingsComponent from './account settings/accountSettings';
// import Checkout from './checkout';


function SettingsPage() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const classes = tokens(theme);
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="10px">
      <Header
        title="SETTINGS."
        subtitle="Business Settings."
      />
      <Box sx={{ width: '100%', mt: '-15px'}}>
            <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            textTransform= "none"
            sx={{ gap: '10px' }}
            >
            <Tab
                value={0}
                label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ConstructionIcon sx={{ marginRight: '5px' }} />
                    <Typography variant="h6" fontWeight="600" textTransform="none">
                        Credit Mix
                    </Typography> 
                </Box>
                }
                className={classes.tab}
            />
                 <Tab
                value={1}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <SettingsIcon sx={{ marginRight: '5px' }} /> 
                    <Typography variant="h6" fontWeight="600" textTransform="none">
                        Account Settings
                    </Typography>
                </Box>
                }
                className={classes.tab}
            />
            <Tab
                value={2}
                label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LockIcon sx={{ marginRight: '5px' }} />
                    <Typography variant="h6" fontWeight="600" textTransform="none">
                        Access Control
                    </Typography>
                </Box>
                }
                className={classes.tab}
            />
            <Tab
                value={3}
                label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ReceiptLongIcon sx={{ marginRight: '5px' }} />
                    <Typography variant="h6" fontWeight="600" textTransform="none">
                        Billing
                    </Typography>
                </Box>
                }
                className={classes.tab}
            />
      
            </Tabs>
      </Box>
      {/* Render the corresponding component based on the selected tab */}
      {value === 0 && <CreditMix />}
      {value === 1 && <SettingsComponent />}
      {value === 2 && <Team />}
      {value === 3 && <Billing />}
      
    </Box>
  );
}

export default SettingsPage;

