import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import Cookies from "js-cookie";

const CustomerReferral = React.memo(
  ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isNonMobile,
  }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [team, setTeam] = useState("");
    const [teamsList, setTeamsList] = useState([]);
    const [officersList, setOfficersList] = useState([]);

    useEffect(() => {
      const fetchTeams = async () => {
        try {
          const accessToken = Cookies.get("accessToken");
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/teams`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }); // Set the teams list from the response
          if (response.data && Array.isArray(response.data)) {
            setTeamsList(response.data);
          } else {
            console.error("Unexpected response structure:", response.data);
          }
        } catch (error) {
          console.error("Error fetching teams:", error);
        }
      };

      fetchTeams();
    }, []); // Fetch officers based on the selected team

    const fetchOfficers = async (teamId) => {
      try {
        const accessToken = Cookies.get("accessToken");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/teams/${teamId}/sales_agents`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data && Array.isArray(response.data)) {
          setOfficersList(response.data);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching officers:", error);
      }
    }; 

    const handleTeamChange = (event) => {
      const selectedTeam = event.target.value;
      setTeam(selectedTeam);
      fetchOfficers(selectedTeam);
    };

    return (
      <Box mt={3}>
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, 1fr)"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {/* Team Dropdown */}
            <FormControl
              variant="filled"
              fullWidth
              sx={{ gridColumn: "span 2" }}
            >
              <InputLabel id="team-label">Select Team</InputLabel>
              <Select
                labelId="team-label"
                name="team"
                value={values.team}
                onChange={handleTeamChange}
                onBlur={handleBlur}
                label="Select Team"
              >
                {teamsList.map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Customer Relationship Officer Dropdown */}
            <FormControl
              variant="filled"
              fullWidth
              sx={{ gridColumn: "span 2" }}
            >
              <InputLabel id="officer-label">
                Customer Relationship Officer
              </InputLabel>
              <Select
                labelId="officer-label"
                name="officer"
                value={values.officer || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Customer Relationship Officer"
              >
                {officersList.map((officer) => (
                  <MenuItem key={officer.id} value={officer.id}>
                    {officer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Referral Code Field */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Referral Code"
              value={values.referralCode}
              onChange={handleChange}
              name="referralCode"
              sx={{ gridColumn: "span 2" }}
            />
          </Box>
        </form>
      </Box>
    );
  }
);

export default CustomerReferral;
