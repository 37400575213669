import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Box,
  Typography,
  Card,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

const BankStatement = ({ values, errors, handleBlur, handleChange, setUploadFlag }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showTable, setShowTable] = useState(false);
  const { bankStatements = [] } = values; // Ensure bankStatements exists

  const addRow = () => {
    const newRow = { date: '', credit: '', debit: '', balance: '' };
    const updatedStatements = [...bankStatements, newRow];
    values.bankStatements = updatedStatements; // Update Formik's values directly
    validateBankStatements(updatedStatements); // Validate after adding a row
  };

  const handleToggleTable = () => {
    setShowTable(!showTable);
    if (!showTable) {
      validateBankStatements(bankStatements); // Validate when table is shown
    } else {
      setUploadFlag(false); // Reset upload flag if table is hidden
    }
  };

  const validateBankStatements = (statements) => {
    const isValid = statements.some(
      (row) => row.date && row.credit && row.debit && row.balance
    );
    setUploadFlag(isValid); // Notify parent about validity
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h6" mr={2}>
          Upload Bank Statement information?
        </Typography>
        <Button
          variant="contained"
          color={showTable ? 'error' : 'secondary'}
          onClick={handleToggleTable}
        >
          {showTable ? 'Cancel' : 'Upload'}
        </Button>
      </Box>
      {showTable && (
        <Card style={{ backgroundColor: colors.primary[600], padding: '20px' }}>
          <TableContainer component={Paper}>
            <Table style={{ backgroundColor: colors.primary[500], border: '1' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600, fontSize: '14.5px' }}>
                    Date (MM/YYYY)
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, fontSize: '14.5px' }}>
                    Credit
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, fontSize: '14.5px' }}>
                    Debit
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, fontSize: '14.5px' }}>
                    Balance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bankStatements.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="month"
                        name={`bankStatements[${index}].date`}
                        value={row.date}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          validateBankStatements(bankStatements);
                        }}
                        error={!!errors.bankStatements?.[index]?.date}
                        helperText={errors.bankStatements?.[index]?.date}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        name={`bankStatements[${index}].credit`}
                        value={row.credit}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          validateBankStatements(bankStatements);
                        }}
                        error={!!errors.bankStatements?.[index]?.credit}
                        helperText={errors.bankStatements?.[index]?.credit}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        name={`bankStatements[${index}].debit`}
                        value={row.debit}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          validateBankStatements(bankStatements);
                        }}
                        error={!!errors.bankStatements?.[index]?.debit}
                        helperText={errors.bankStatements?.[index]?.debit}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        name={`bankStatements[${index}].balance`}
                        value={row.balance}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          validateBankStatements(bankStatements);
                        }}
                        error={!!errors.bankStatements?.[index]?.balance}
                        helperText={errors.bankStatements?.[index]?.balance}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2} display="flex" justifyContent="space-between">
            <Button variant="contained" color="secondary" onClick={addRow}>
              Add Row
            </Button>
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default BankStatement;
