import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import axios from "axios";
import Header from "../../../../components/Header";

const Location = ({ customerId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [locationSummary, setLocationSummary] = useState([]);
  const [anomalies, setAnomalies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anomaliesLoading, setAnomaliesLoading] = useState(true);

  const locationColumns = [
    { field: "id", headerName: "ID", hide: true },
    { field: "category", headerName: "Town/City", flex: 1 },
    { field: "transaction_counts", headerName: "No. of times Visited", flex: 1 },
    { field: "most_recent_date", headerName: "Last Visit", flex: 1 },
  ];

  const anomalyColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "details", headerName: "Details", flex: 2 },
    { field: "receipt_no", headerName: "Receipt No", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "completion_time", headerName: "Completion Time", flex: 1 },
  ];

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/location-summary/${customerId}`);
        const locationData = response.data;

        const transformedData = Object.keys(locationData).map((keyword, index) => ({
          id: index + 1,
          category: keyword,
          transaction_counts: locationData[keyword].count,
          most_recent_date: locationData[keyword].most_recent_date,
        }));

        setLocationSummary(transformedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Location summary data:", error);
        setLoading(false);
      }
    };

    const fetchAnomaliesData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/transaction_anomalies/${customerId}`);
        const anomaliesData = response.data.map((transaction, index) => ({
          id: index + 1,
          ...transaction,
        }));
        setAnomalies(anomaliesData);
        setAnomaliesLoading(false);
      } catch (error) {
        console.error("Error fetching transaction anomalies data:", error);
        setAnomaliesLoading(false);
      }
    };

    fetchLocationData();
    fetchAnomaliesData();
  }, [customerId]);

  return (
    <Box m="10px">
      <Header subtitle="Locations/Movements:" />
      <Box
        m="10px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={locationSummary}
          columns={locationColumns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Box mt={4}>
        <Header subtitle="Transaction Anomalies:" />
        <Box
          m="10px 0 0 0"
          height="65vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            loading={anomaliesLoading}
            rows={anomalies}
            columns={anomalyColumns}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Location;
