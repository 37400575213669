import React, { useEffect, useState } from 'react';
import { Card, Stack, Box, Typography, useTheme, SvgIcon } from '@mui/material';
import { tokens } from '../../../theme';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function GradientCircularProgress({ value, size, }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getScoreText = (score) => {
    if (score >= 0.81) return 'Excellent!';
    if (score >= 0.66) return 'Very Good';
    if (score >= 0.56) return 'Good';
    if (score >= 0.41) return 'Fair';
    return 'Poor!';
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <SvgIcon viewBox="0 0 100 100" sx={{ width: size, height: size }}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#22C55E', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#B71D18', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <circle
          cx="50"
          cy="50"
          r="45"
          stroke="url(#gradient)"
          strokeWidth="10"
          fill="none"
          strokeDasharray={`${2 * Math.PI * 45}`}
          strokeDashoffset={`${2 * Math.PI * 45 * (1 - value / 100)}`}
          style={{ transition: 'stroke-dashoffset 0.5s ease-out', transform: 'rotate(-90deg)', transformOrigin: '50% 50%' }}
        />
      </SvgIcon>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
          <Typography color='text' fontWeight="555" variant='button' mb='4px'>
            Overall Score:
          </Typography>
          <Typography
            color={colors.greenAccent[400]}
            variant='h2'
            fontWeight='bold'
            mb='4px'
            sx={({ breakpoints }) => ({
              [breakpoints.only('xl')]: {
                fontSize: '32px'
              }
            })}>
            {value / 100}
          </Typography>
          <Typography color={colors.greenAccent[600]} fontWeight="555" variant='button'>
            {getScoreText(value / 100)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function CreditTracking(customerId) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [scores, setScores] = useState(null);

  useEffect(() => {
    const fetchScores = async () => {
      try {
        const id = customerId.customerId; // Extract the actual id
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/overall-score/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setScores({
          capabilityScore: data.capability_score,
          characterScore: data.character_score,
          overallScore: data.overall_score,
        });
      } catch (error) {
        console.error('Error fetching scores:', error);
      }
    };

    fetchScores();
  }, [customerId]);

  if (!scores) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Card
      sx={{
        height: '100%',
        backgroundColor: colors.primary[500],
        padding: '11px',
      }}>
      <Box sx={{ width: '100%' }}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          sx={{ width: '100%' }}
          mb='3px'>
          <Typography variant="h5" color={colors.greenAccent[500]} fontWeight="600" gutterBottom>
            Comprehensive Score
          </Typography>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            bgColor='#22234B'
            sx={{ width: '37px', height: '37px', cursor: 'pointer', borderRadius: '12px' }}>
            <MoreHorizIcon size='18px' />
          </Box>
        </Box>
        <Box
          display='flex'
          sx={({ breakpoints }) => ({
            [breakpoints.up('xs')]: {
              flexDirection: 'column',
              gap: '16px',
              justifyContent: 'center',
              alignItems: 'center'
            },
            [breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }
          })}>
          <Stack
            direction='column'
            spacing='20px'
            width='500px'
            maxWidth='50%'
            sx={({ breakpoints }) => ({
              mr: 'auto',
              [breakpoints.only('md')]: {
                mr: '75px'
              },
              [breakpoints.only('xl')]: {
                width: '500px',
                maxWidth: '40%'
              }
            })}>
            <Box
              bgcolor={colors.myAccent[103]}
              display='flex'
              width='220px'
              p='20px 22px'
              flexDirection='column'
              sx={({ breakpoints }) => ({
                borderRadius: '20px',
                [breakpoints.up('xl')]: {
                  maxWidth: '110px !important'
                },
                [breakpoints.up('xxl')]: {
                  minWidth: '180px',
                  maxWidth: '100% !important'
                }
              })}>
              <Typography color={colors.grey[100]} variant='button' fontWeight="600" mb='5px'>
                Capability Score:
              </Typography>
              <Typography color={colors.greenAccent[400]} variant='lg' fontWeight="600">
                {scores.capabilityScore}
              </Typography>
            </Box>
            <Box
              bgcolor={colors.myAccent[102]}
              display='flex'
              width='220px'
              p='20px 22px'
              flexDirection='column'
              sx={({ breakpoints }) => ({
                borderRadius: '20px',
                [breakpoints.up('xl')]: {
                  maxWidth: '110px !important'
                },
                [breakpoints.up('xxl')]: {
                  minWidth: '180px',
                  maxWidth: '100% !important'
                }
              })}>
              <Typography color={colors.grey[100]} variant='button' fontWeight="600" mb='5px'>
                Character Score:
              </Typography>
              <Typography color={colors.greenAccent[400]} variant='lg' fontWeight="600">
                {scores.characterScore}
              </Typography>
            </Box>
          </Stack>
          <GradientCircularProgress
            value={(scores.overallScore) * 100}
            size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
          />
        </Box>
      </Box>
    </Card>
  );
}

export default CreditTracking;


