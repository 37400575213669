import React from "react";
import {
  Box,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Header from "../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const VehicleRegistration = ({ values, errors, touched, handleBlur, handleChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box m="20px">
      <Header subtitle="Motor Vehicle Copy of Records." />
      <Typography variant="h6" mb={2} gutterBottom>
        Motor Vehicle Registration:
        <Tooltip title="Fill this section manually to save vehicle details.">
          <IconButton size="small" sx={{ ml: 1 }}>
            <InfoOutlinedIcon fontSize="small" color="info" />
          </IconButton>
        </Tooltip>
      </Typography>
      <Box
        display="grid"
        gap="20px"
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 3" } }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Vehicle Registration Number"
          name="vehicle_registration_number"
          value={values.vehicle_registration_number}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.vehicle_registration_number && !!errors.vehicle_registration_number}
          helperText={touched.vehicle_registration_number && errors.vehicle_registration_number}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Vehicle Chassis Number"
          name="vehicle_chassis_number"
          value={values.vehicle_chassis_number}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.vehicle_chassis_number && !!errors.vehicle_chassis_number}
          helperText={touched.vehicle_chassis_number && errors.vehicle_chassis_number}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Car Make"
          name="car_make"
          value={values.car_make}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.car_make && !!errors.car_make}
          helperText={touched.car_make && errors.car_make}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Car Model"
          name="car_model"
          value={values.car_model}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.car_model && !!errors.car_model}
          helperText={touched.car_model && errors.car_model}
        />
        <TextField
          fullWidth
          variant="filled"
          type="number"
          label="Year of Manufacture"
          name="year_of_manufacture"
          value={values.year_of_manufacture}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.year_of_manufacture && !!errors.year_of_manufacture}
          helperText={touched.year_of_manufacture && errors.year_of_manufacture}
        />
        <TextField
          fullWidth
          variant="filled"
          type="number"
          label="Market Value"
          name="market_value"
          value={values.market_value}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.market_value && !!errors.market_value}
          helperText={touched.market_value && errors.market_value}
        />
        <TextField
          label="Initial Forced Sale Value"
          name="initial_forced_sale_value"
          variant="filled"
          fullWidth
          value={values.initial_forced_sale_value}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.initial_for && Boolean(errors.initial_forced_sale_value)}
          helperText={touched.initial_forced_sale_value && errors.initial_forced_sale_value}
        />
        <TextField
          label="Condition"
          name="condition"
          variant="filled"
          fullWidth
          value={values.condition}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.condition && Boolean(errors.condition)}
          helperText={touched.condition && errors.condition}
        />
        <TextField
          label="Log Book Number"
          name="log_book_number"
          variant="filled"
          fullWidth
          value={values.log_book_number}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.log_book_number && Boolean(errors.log_book_number)}
          helperText={touched.log_book_number && errors.log_book_number}
        />
        <TextField
          label="Registration Date"
          name="registration_date"
          variant="filled"
          fullWidth
          type="date"
          InputLabelProps={{ shrink: true }}
          value={values.registration_date}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.registration_date && Boolean(errors.registration_date)}
          helperText={touched.registration_date && errors.registration_date}
        />
        <TextField
          label="Customs Entry Number"
          name="customs_entry_number"
          variant="filled"
          fullWidth
          value={values.customs_entry_number}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.customs_entry_number && Boolean(errors.customs_entry_number)}
          helperText={touched.customs_entry_number && errors.customs_entry_number}
        />
        <TextField
          label="Body Type"
          name="body_type"
          variant="filled"
          fullWidth
          value={values.body_type}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.body_type && Boolean(errors.body_type)}
          helperText={touched.body_type && errors.body_type}
        />
        <TextField
          label="Body Color"
          name="body_color"
          variant="filled"
          fullWidth
          value={values.body_color}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.body_color && Boolean(errors.body_color)}
          helperText={touched.body_color && errors.body_color}
        />
        <TextField
          label="Engine Number"
          name="engine_number"
          variant="filled"
          fullWidth
          value={values.engine_number}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.engine_number && Boolean(errors.engine_number)}
          helperText={touched.engine_number && errors.engine_number}
        />
        <TextField
          label="Fuel Type"
          name="fuel_type"
          variant="filled"
          fullWidth
          value={values.fuel_type}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.fuel_type && Boolean(errors.fuel_type)}
          helperText={touched.fuel_type && errors.fuel_type}
        />
        <TextField
          label="Rating (cc)"
          name="rating"
          variant="filled"
          fullWidth
          value={values.rating}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.rating && Boolean(errors.rating)}
          helperText={touched.rating && errors.rating}
        />
        <TextField
          label="Log Book Serial Number"
          name="log_book_serial_number"
          variant="filled"
          fullWidth
          value={values.log_book_serial_number}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.log_book_serial_number && Boolean(errors.log_book_serial_number)}
          helperText={touched.log_book_serial_number && errors.log_book_serial_number}
        />
        <TextField
          label="Current Owner"
          name="current_owner"
          variant="filled"
          fullWidth
          value={values.current_owner}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.current_owner && Boolean(errors.current_owner)}
          helperText={touched.current_owner && errors.current_owner}
        />
        <TextField
          label="Owner KRA PIN"
          name="owner_kra_pin"
          variant="filled"
          fullWidth
          value={values.owner_kra_pin}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.owner_kra_pin && Boolean(errors.owner_kra_pin)}
          helperText={touched.owner_kra_pin && errors.owner_kra_pin}
        />
      </Box>
    </Box>
  );
};

export default VehicleRegistration;
