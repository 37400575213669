import Cookies from 'js-cookie';
import axios from 'axios';

const API_BASE_URL = 'https://api.carren.cc';
axios.defaults.baseURL = API_BASE_URL;

// Function to refresh the access token
const refreshAccessToken = async () => {
  try {
    console.log('Attempting to refresh access token...');
    const refreshToken = Cookies.get('refreshToken');
    
    if (!refreshToken) {
      // console.error('No refresh token found. Redirecting to sign-in page.');
      window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/signin`;
      return null;
    }

    const response = await axios.post('/refresh', {}, {
      headers: {
        'Authorization': `Bearer ${refreshToken}`
      }
    });

    if (response.status === 200) {
      const newAccessToken = response.data.access_token;
      Cookies.set('accessToken', newAccessToken);
      console.log('Access token successfully refreshed:', newAccessToken);
      return newAccessToken;
    }
  } catch (error) {
    console.error('Error refreshing access token:', error);
    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/signin`;
    return null;
  }
};

// Function to fetch with authentication
const fetchWithAuth = async (url, options = {}) => {
  let accessToken = Cookies.get('accessToken');

  // Check if access token exists, else refresh it
  if (!accessToken) {
    console.log('No access token found. Trying to refresh...');
    accessToken = await refreshAccessToken();
  }

  if (!accessToken) {
    console.error('Unable to obtain access token. Aborting request.');
    return;
  }

  const headers = {
    ...options.headers,
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios({ url, ...options, headers });
    return response.data;
  } catch (error) {
    console.error('Request failed:', error);

    // If token is invalid or expired, try refreshing it once
    if (error.response && error.response.status === 401) {
      console.log('Access token expired. Refreshing token...');
      accessToken = await refreshAccessToken();

      if (!accessToken) {
        console.error('Failed to refresh access token. Aborting retry.');
        return;
      }

      // Retry request with refreshed access token
      try {
        const retryResponse = await axios({
          url,
          ...options,
          headers: {
            ...options.headers,
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        return retryResponse.data;
      } catch (retryError) {
        console.error('Retry request failed:', retryError);
        throw retryError;
      }
    } else {
      throw error;
    }
  }
};

// Function to logout
const logout = async () => {
  try {
    console.log('Logging out user...');
    await axios.post('/logout', {}, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('accessToken')}`
      }
    });
    console.log('Logout successful.');
  } catch (error) {
    console.error('Error logging out:', error);
  } finally {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/signin`;
  }
};

export { refreshAccessToken, fetchWithAuth, logout };