// App.js
import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/view appraisal";
import Contacts from "./scenes/contacts";
import EditCustomers from "./scenes/EditCustomers";
import Customers from "./scenes/customers/customers";
import CreditAppraisal from "./scenes/Credit Appraisal";
import FinancialSummary from "./scenes/Credit Appraisal/FinancialSummary";
import CreditScore from "./scenes/Credit Appraisal/CreditScore";
import Insights from "./scenes/Credit Appraisal/Insights";
import Report from "./scenes/Credit Appraisal/Report";
import CustomerTransfer from "./scenes/Credit Appraisal/FinancialSummary/customertransfer";
import CustomerPayments from "./scenes/Credit Appraisal/FinancialSummary/customerpayments";
import MoneyReceived from "./scenes/Credit Appraisal/FinancialSummary/money received";
import Withdrawals from "./scenes/Credit Appraisal/FinancialSummary/withdrawals";
import Deposits from "./scenes/Credit Appraisal/FinancialSummary/deposits";
import Others from "./scenes/Credit Appraisal/FinancialSummary/others";
import MerchantPayments from "./scenes/Credit Appraisal/FinancialSummary/customerpayments/merchant payments";
import PaybillPayments from "./scenes/Credit Appraisal/FinancialSummary/customerpayments/paybill payments";
import NewAppraisal from "./scenes/NewAppraisal";
import Profile from "./scenes/profile";
import TabAccount from "./scenes/profile/account";
import TabInfo from "./scenes/profile/info";
import TabSecurity from "./scenes/profile/security";
import FAQ from "./scenes/faq";
import SignIn from "./scenes/authentication/login";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import NotFoundPage from "./scenes/error/NotFoundPage";
import ProtectedRoute from "./ProtectedRoute";
import SettingsPage from "./scenes/settings";
import Loans from "./scenes/loans";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const showSidebarAndTopbar = location.pathname !== '/login';

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {showSidebarAndTopbar && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {showSidebarAndTopbar && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/login" element={<SignIn />} />
              <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/team" element={<ProtectedRoute><Team /></ProtectedRoute>} />
              <Route path="/contacts" element={<ProtectedRoute><Contacts /></ProtectedRoute>} />
              <Route path="/CompletedAppraisals" element={<ProtectedRoute><Invoices /></ProtectedRoute>} />
              <Route path="/EditCustomers" element={<ProtectedRoute><EditCustomers /></ProtectedRoute>} />
              <Route path="/Profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path="/NewAppraisal" element={<ProtectedRoute><NewAppraisal /></ProtectedRoute>} />
              <Route path="/CreditAppraisal" element={<ProtectedRoute><CreditAppraisal customerId={location.state?.customerId} /></ProtectedRoute>} />
              <Route path="/FinancialSummary" element={<ProtectedRoute><FinancialSummary /></ProtectedRoute>} />
              <Route path="/Insights" element={<ProtectedRoute><Insights /></ProtectedRoute>} />
              <Route path="/CreditScore" element={<ProtectedRoute><CreditScore /></ProtectedRoute>} />
              <Route path="/Report" element={<ProtectedRoute><Report /></ProtectedRoute>} />
              <Route path="/Customers" element={<ProtectedRoute><Customers /></ProtectedRoute>} />
              <Route path="/CustomerTransfer" element={<ProtectedRoute><CustomerTransfer /></ProtectedRoute>} />
              <Route path="/CustomerPayments" element={<ProtectedRoute><CustomerPayments /></ProtectedRoute>} />
              <Route path="/MoneyReceived" element={<ProtectedRoute><MoneyReceived /></ProtectedRoute>} />
              <Route path="/Withdrawals" element={<ProtectedRoute><Withdrawals /></ProtectedRoute>} />
              <Route path="/Deposits" element={<ProtectedRoute><Deposits /></ProtectedRoute>} />
              <Route path="/Others" element={<ProtectedRoute><Others /></ProtectedRoute>} />
              <Route path="/Account" element={<ProtectedRoute><TabAccount /></ProtectedRoute>} />
              <Route path="/Security" element={<ProtectedRoute><TabSecurity /></ProtectedRoute>} />
              <Route path="/Info" element={<ProtectedRoute><TabInfo /></ProtectedRoute>} />
              <Route path="/Settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
              <Route path="/MerchantPayments" element={<ProtectedRoute><MerchantPayments /></ProtectedRoute>} />
              <Route path="/PaybillPayments" element={<ProtectedRoute><PaybillPayments /></ProtectedRoute>} />
              <Route path="/faq" element={<ProtectedRoute><FAQ /></ProtectedRoute>} />
              <Route path="/loans" element={<ProtectedRoute><Loans /></ProtectedRoute>} />
              {/* <Route path="/test" element={<ProtectedRoute><TestNotifications/></ProtectedRoute>} /> */}
              <Route path="*" element={<ProtectedRoute><NotFoundPage /></ProtectedRoute>} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
