import React from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import LockOpenIcon from '@mui/icons-material/LockOpen';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from '../../components/Header';

import TabAccount from './account';
// import TabInfo from './info';
import TabSecurity from './security';
// import Billing from './billing';

function Profile() {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const classes = tokens(theme);
  const colors = tokens(theme.palette.mode);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="20px">
      <Header
        title="PROFILE."
        subtitle="My User Profile."
      />
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          textTransform= "none"
          sx={{ gap: '10px' }} // Add space between tabs
        >
          <Tab
            value={0}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountBoxRoundedIcon sx={{ marginRight: '5px' }} /> {/* Add space between icon and label */}
                  <Typography variant="h6" fontWeight="600" textTransform="none">
                    Account
                  </Typography> 
              </Box>
            }
            className={classes.tab}
          />
          <Tab
            value={1}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LockOpenIcon sx={{ marginRight: '5px' }} /> {/* Add space between icon and label */}
                  <Typography variant="h6" fontWeight="600" textTransform="none">
                    Security
                  </Typography>
              </Box>
            }
            className={classes.tab}
          />
          {/* <Tab
            value={2}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InfoOutlinedIcon sx={{ marginRight: '5px' }} />
                  <Typography variant="h6" fontWeight="600" textTransform="none">
                    Billing
                  </Typography>
              </Box>
            }
            className={classes.tab}
          /> */}
        </Tabs>
      </Box>
      {/* Render the corresponding component based on the selected tab */}
      {value === 0 && <TabAccount />}
      {value === 1 && <TabSecurity />}
      {/* {value === 2 && <Billing />} */}
    </Box>
  );
}

export default Profile;

