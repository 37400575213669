import React from 'react';
import { Button } from '@mui/material';

const ReportActionButtons = ({ userRole, handleSendToReview, handleSendForApproval, handleDownloadPDF, handleProceedToDisbursement, handleOpenRejectionDialog }) => {
  return (
    <>
      {userRole === 'admin' ? (
        <>
          <Button onClick={handleOpenRejectionDialog} variant="contained" color="error" style={{ marginBottom: '10px' }}>
            Reject Application
          </Button>
          <Button onClick={handleProceedToDisbursement} variant="contained" color="success" style={{ marginLeft: '10px', marginBottom: '10px' }}>
            Proceed to Disbursement
          </Button>
        </>
      ) : userRole === 'reviewer' ? (
        <>
          <Button onClick={handleSendToReview} variant="contained" color="warning" style={{ marginBottom: '10px' }}>
            Send Back to Initiator
          </Button>
          <Button onClick={handleSendForApproval} variant="contained" color="secondary" style={{ marginLeft: '10px', marginBottom: '10px' }}>
            Send for Approval
          </Button>
        </>
      ) : (
        <>
          <Button onClick={handleDownloadPDF} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>
            Download Report
          </Button>
          <Button onClick={handleSendToReview} variant="contained" color="info" style={{ marginLeft: '10px', marginBottom: '10px' }}>
            Send to Review
          </Button>
        </>
      )}
    </>
  );
};

export default ReportActionButtons;
