import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Tooltip, Snackbar, Alert, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../../components/Header';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { tokens } from '../../../theme';
import Cookies from 'js-cookie';
import { AddMessageDialog, EditMessageDialog, DeleteMessageDialog } from './message-dialogs';

const LoanNotification = () => {
  const [messages, setMessages] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete dialog
  const [newMessage, setNewMessage] = useState('');
  const [newSubject, setNewSubject] = useState('');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const accessToken = Cookies.get('accessToken');

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/messages`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const handleAddMessage = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/messages`, 
        { content: newMessage, subject: newSubject },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );
      fetchMessages();
      setNewMessage('');
      setNewSubject('');
      setOpenAddDialog(false);
      setAlertMessage('Message added successfully');
      setAlertSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setAlertMessage('Error saving message');
      setAlertSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditMessage = (message) => {
    setSelectedMessage(message);
    setOpenEditDialog(true);
  };

  const handleEditSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/messages/${selectedMessage.id}`, 
        { content: selectedMessage.content, subject: selectedMessage.subject },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );
      fetchMessages();
      setOpenEditDialog(false);
      setAlertMessage('Message updated successfully');
      setAlertSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setAlertMessage('Error updating message');
      setAlertSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteMessage = (message) => {
    setSelectedMessage(message);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/messages/${selectedMessage.id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      });
      fetchMessages();
      setOpenDeleteDialog(false);
      setAlertMessage('Message deleted successfully');
      setAlertSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setAlertMessage('Error deleting message');
      setAlertSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    { field: 'id', headerName: '#', flex: 0.2 },
    { field: 'subject', headerName: 'Subject', flex: 1.5 },
    { field: 'content', headerName: 'Content', flex: 6.5 },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEditMessage(params.row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => handleDeleteMessage(params.row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header subtitle="Notification Templates" />
      <Box mb={2}>
        <Button variant="contained" color="secondary" onClick={() => setOpenAddDialog(true)}>
          Add Message
        </Button>
      </Box>
      <Box
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .name-column--cell": { color: colors.greenAccent[300] },
          "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
          "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
          "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": { color: `${colors.grey[100]} !important` },
        }}
      >
        <DataGrid
          rows={messages}
          columns={columns}
          pageSize={25}
          autoHeight
          components={{ Toolbar: GridToolbar }}
          density="compact"
        />
      </Box>
      <AddMessageDialog
        open={openAddDialog}
        handleClose={() => setOpenAddDialog(false)}
        newSubject={newSubject}
        newMessage={newMessage}
        setNewSubject={setNewSubject}
        setNewMessage={setNewMessage}
        handleSave={handleAddMessage}
      />
      <EditMessageDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        selectedMessage={selectedMessage}
        setSelectedMessage={setSelectedMessage}
        handleEditSave={handleEditSave}
      />
      <DeleteMessageDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleDelete={handleDeleteConfirm}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity={alertSeverity} onClose={handleCloseSnackbar}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoanNotification;
