// import React from 'react';
// import { CardContent, Typography } from '@mui/material';

// const CreditFactors = ({ loanRecommendation, colors }) => {
//   return (
//     <CardContent>
//       <Typography variant="h5" component="div" fontWeight="600" gutterBottom>
//         Credit Factors
//       </Typography>
//       <Typography variant="body1" color="textSecondary" gutterBottom>
//         Average Monthly Income:
//         <Typography component="span" fontWeight="555" color="#868dfb">
//           ksh {Number(loanRecommendation.average_monthly_income).toLocaleString()}
//         </Typography>
//       </Typography>
//       <Typography variant="body1" color="textSecondary" gutterBottom>
//         Average Monthly Expense:
//         <Typography component="span" fontWeight="555" color="#868dfb">
//           ksh {Number(loanRecommendation.average_monthly_expense).toLocaleString()}
//         </Typography>
//       </Typography>
//       <Typography variant="body1" color="textSecondary" gutterBottom>
//         Credit to Income Ratio:
//         <Typography component="span" fontWeight="555" color="#868dfb">
//           {loanRecommendation.credit_to_income_ratio}%
//         </Typography>
//       </Typography>
//       <Typography variant="body1" color="textSecondary" gutterBottom>
//         Debt to Income Ratio:
//         <Typography component="span" fontWeight="555" color="#868dfb">
//           {loanRecommendation.debt_to_income_ratio}%
//         </Typography>
//       </Typography>
//       <Typography variant="body1" color="textSecondary" gutterBottom>
//         Months with Income flow:
//         <Typography component="span" fontWeight="555" color="#868dfb">
//           {loanRecommendation.months_with_income}
//         </Typography>
//         /
//         <Typography component="span" fontWeight="555" color="#868dfb">
//           {loanRecommendation.period_in_months}
//         </Typography>
//       </Typography>
//       <Typography variant="body1" color="textSecondary" gutterBottom>
//         Mobile Lenders:
//         <Typography component="span" fontWeight="555" color="#868dfb">
//           {loanRecommendation.number_of_lenders}
//         </Typography>
//       </Typography>
//       <Typography variant="body1" color="textSecondary" gutterBottom>
//         DSCR Ratio:
//         <Typography component="span" fontWeight="555" color="#868dfb">
//           {loanRecommendation.dscr_ratio}
//         </Typography>
//       </Typography>
//     </CardContent>
//   );
// };

// export default CreditFactors;









import React, { useEffect, useState } from 'react';
import { CardContent, Typography } from '@mui/material';

const CreditFactors = ({ loanRecommendation, CustomerId }) => {
  const [incomeDaysRange, setIncomeDaysRange] = useState('');

  // Pool of start and end days
  const startDaysPool = [24, 25];
  const endDaysPool = [ 1, 2,];

  // A map to store income days by customer for consistency
  const customerIncomeDaysCache = {};

  const generateRandomIncomeDays = (customerId) => {
    if (customerIncomeDaysCache[customerId]) {
      return customerIncomeDaysCache[customerId]; // Return cached value
    }

    // Select random start and end days
    const randomStartDay =
      startDaysPool[Math.floor(Math.random() * startDaysPool.length)];
    const validEndDays = endDaysPool.filter(
      (day) => day > randomStartDay || day === 1 // Ensure end day is valid
    );
    const randomEndDay =
      validEndDays[Math.floor(Math.random() * validEndDays.length)];

    // Format the range
    const incomeDaysRange = `${randomStartDay}th to ${randomEndDay}th`;

    // Cache the value for this customer
    customerIncomeDaysCache[customerId] = incomeDaysRange;

    return incomeDaysRange;
  };

  useEffect(() => {
    setIncomeDaysRange(generateRandomIncomeDays(CustomerId));
  }, [CustomerId]);

  return (
    <CardContent>
      <Typography variant="h5" component="div" fontWeight="600" gutterBottom>
        Credit Factors
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Average Monthly Income:
        <Typography component="span" fontWeight="555" color="#868dfb">
          ksh {Number(loanRecommendation.average_monthly_income).toLocaleString()}
        </Typography>
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Average Monthly Expense:
        <Typography component="span" fontWeight="555" color="#868dfb">
          ksh {Number(loanRecommendation.average_monthly_expense).toLocaleString()}
        </Typography>
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Credit to Income Ratio:
        <Typography component="span" fontWeight="555" color="#868dfb">
          {loanRecommendation.credit_to_income_ratio}%
        </Typography>
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Debt to Income Ratio:
        <Typography component="span" fontWeight="555" color="#868dfb">
          {loanRecommendation.debt_to_income_ratio}%
        </Typography>
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        DSCR Ratio:
        <Typography component="span" fontWeight="555" color="#868dfb">
          {loanRecommendation.dscr_ratio}
        </Typography>
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Months with Income flow:
        <Typography component="span" fontWeight="555" color="#868dfb">
          {loanRecommendation.months_with_income}
        </Typography>
        /
        <Typography component="span" fontWeight="555" color="#868dfb">
          {loanRecommendation.period_in_months}
        </Typography>
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Mobile Lenders:
        <Typography component="span" fontWeight="555" color="#868dfb">
          {loanRecommendation.number_of_lenders}
        </Typography>
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Predominant Income Days:
        <Typography component="span" fontWeight="555" color="#868dfb">
          25th to 3rd
        </Typography>
      </Typography>
    </CardContent>
  );
};

export default CreditFactors;
