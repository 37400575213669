import React, { useState, useEffect } from 'react';
import {
  Box, Typography, useTheme, Button, IconButton, Grid, Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, TextField, Tooltip, Snackbar, Menu, MenuItem,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Formik, Form, Field } from 'formik';
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlockIcon from '@mui/icons-material/Block';
import { fetchWithAuth } from '../../auth';
import UserNotificationSettings from './UserNotifications';
import AddNewUser from './AddNewUser';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null); 
  const [selectedUser, setSelectedUser] = useState(null); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [freezeDialogOpen, setFreezeDialogOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/users`);
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleBlockUser = () => {
    setFreezeDialogOpen(true); // Open the dialog with Freeze User
    handleMenuClose(); // Close the menu
  };

  const handleCancelFreeze = () => {
    setFreezeDialogOpen(false);
  };
  
  const handleConfirmFreeze = () => {
    // Add logic to freeze the user here
    console.log(`User ${selectedRow?.first_name} ${selectedRow?.last_name} frozen.`);
    setFreezeDialogOpen(false); // Close the dialog after freezing
  };

  const handleEdit = async (id) => {
    try {
      const data = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/user/${id}`);
      setSelectedUser(data); 
      setOpenEdit(true);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      console.error('Error fetching user:', error);
    }
  };

  const handleSaveChanges = async (values, { setSubmitting }) => {
    const updatedUser = { 
      ...selectedUser, 
      email: values.email, 
      phone_number: values.phone_number, 
      role: values.role 
    };
  
    try {
      await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/update_user_details/${selectedUser.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ 
          email: updatedUser.email, 
          phone_number: updatedUser.phone_number, 
          role: updatedUser.role 
        }),
      });
  
      setUsers(users.map(user => user.id === selectedUser.id ? updatedUser : user));
      showSuccessSnackbar('User details updated successfully!');
      setOpenEdit(false); 
    } catch (error) {
      if (error.response && error.response.status === 403) {
        showErrorSnackbar('You are not authorized to perform this action.');
      } else {
        showErrorSnackbar('An error occurred while updating user details.');
      }
      console.error('Error updating user details:', error);
    } finally {
      setSubmitting(false);
    }
  };
 
  const handleOpenDeleteDialog = (user) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const handleOpenSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };
  

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const showSuccessSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const showErrorSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!userToDelete) return;
    const { id } = userToDelete;
  
    try {
      await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/user/${id}`, {
        method: 'DELETE',
      });
  
      // Update the UI by removing the user from the list
      setUsers(users.filter(user => user.id !== id));
  
      // Show success notification
      showSuccessSnackbar('User deleted successfully!');
    } catch (error) {
      if (error.response && error.response.status === 403) {
        showErrorSnackbar('You are not authorized to perform this action.');
      } else {
        showErrorSnackbar('An error occurred while deleting the user.');
      }
      console.error('Error:', error);
    }
  };  

  const columns = [
    { field: "first_name", headerName: "First Name", flex: 0.75 },
    { field: "last_name", headerName: "Last Name", flex: 0.75 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "company", headerName: "Company", flex: 1 },
    {
      field: "role",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              role === "admin"
                ? colors.greenAccent[500]
                : role === "initiator"
                ? colors.myAccent[105]
                : colors.myAccent[106]
            }
            borderRadius="4px"
          >
            {role === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {role === "initiator" && <SecurityOutlinedIcon />}
            {role === "reviewer" && <VerifiedUserIcon />}
            {role === "approver" && <VerifiedUserIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {role}
            </Typography>
          </Box>
        );
      },
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 1,
    //   renderCell: ({ row }) => (
    //     <Box>
    //       <IconButton onClick={() => handleEdit(row.id)}>
    //         <EditIcon />
    //       </IconButton>
    //        <IconButton onClick={() => handleOpenDeleteDialog(row)}>
    //         <DeleteIcon />
    //       </IconButton>
    //     </Box>
    //   ),
    // },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => (
        <Box>
          <IconButton onClick={() => handleEdit(row.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={(event) => handleMenuClick(event, row)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl && selectedRow?.id === row.id)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleOpenDeleteDialog(row)}>
              <DeleteIcon style={{ marginRight: 8 }} /> Delete
            </MenuItem>
            <MenuItem onClick={handleBlockUser}>
              <BlockIcon style={{ marginRight: 8 }} /> Freeze
            </MenuItem>
          </Menu>
        </Box>
      ),
    },
  ];

  return (
    <Box m="10px"> 
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-35px"
      >
        <Box flexGrow={1}>
          <Header subtitle="Managing User Access Levels:" />
        </Box>
        <Box display="flex" gap="16px">
          <Button
            variant="contained"
            color="info"
            startIcon={<NotificationsActiveIcon />}
            onClick={() => setNotificationDialogOpen(!notificationDialogOpen)}
          >
            Notification Settings
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<PersonAddIcon />}
            onClick={() => setOpenAddUserDialog(true)}
          >
            Add User
          </Button>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={users} columns={columns} getRowId={(row) => row.id} />
      </Box>

      {/* Notification Settings Dialog */}
      {notificationDialogOpen && (
        <UserNotificationSettings 
          open={notificationDialogOpen} 
          handleClose={() => setNotificationDialogOpen(false)} 
        />
      )}

      {/* Add New User Dialog */}
      <AddNewUser
        open={openAddUserDialog}
        handleClose={() => setOpenAddUserDialog(false)}
        setUsers={setUsers}
        users={users}
        handleOpenSnackbar={(message) => {
          setSnackbarMessage(message);
          setSnackbarSeverity(message === 'User created successfully!' ? 'success' : 'error');
          setSnackbarOpen(true);
        }}
      />

      {/* Edit User Dialog */}
      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        PaperProps={{ sx: { backgroundColor: colors.primary[600] } }}
        fullWidth
      >
        <Formik
          initialValues={{
            email: selectedUser?.email || '',
            phone_number: selectedUser?.phone_number || '',
            role: selectedUser?.role || '',
          }}
          onSubmit={handleSaveChanges}
          enableReinitialize
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <DialogTitle
                color={colors.grey[100]}
                variant="h5"
                fontWeight="600"
                sx={{ position: 'sticky', top: 0, zIndex: 1, paddingRight: '48px' }}
              >
                User Details:
                <Tooltip title="Close">
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenEdit(false)}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField 
                      value={selectedUser?.first_name || ''} 
                      label="First Name" 
                      fullWidth 
                      margin="normal" 
                      InputProps={{ readOnly: true }} 
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField 
                      value={selectedUser?.last_name || ''} 
                      label="Last Name" 
                      fullWidth 
                      margin="normal" 
                      InputProps={{ readOnly: true }} 
                    />
                  </Grid>
                </Grid>
                <TextField 
                  value={selectedUser?.company || ''} 
                  label="Company" 
                  fullWidth 
                  margin="normal" 
                  InputProps={{ readOnly: true }} 
                />
                <TextField
                  name="email"
                  type="email"
                  label="Email Address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  name="phone_number"
                  type="text"
                  label="Phone Number"
                  value={values.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="normal"
                />
                <Typography variant="subtitle1" color={colors.grey[100]} sx={{ mt: 2 }}>
                  Role
                </Typography>
                <RadioGroup
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                >
                  <FormControlLabel value="initiator" control={<Radio color="secondary" />} label="Initiator" />
                  <FormControlLabel value="reviewer" control={<Radio color="secondary" />} label="Reviewer" />
                  <FormControlLabel value="admin" control={<Radio color="secondary" />} label="Admin" />
                </RadioGroup>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenEdit(false)} color="error" variant="contained">Close</Button>
                <Button type="submit" color="secondary" variant="contained">Save Changes</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        PaperProps={{ sx: { backgroundColor: colors.primary[600] } }}
      >
        <DialogTitle
          color={colors.grey[100]}
          variant="h5"
          fontWeight="600"
          sx={{ position: 'sticky', top: 0, zIndex: 1, paddingRight: '48px' }}
        >
          Delete User?
        </DialogTitle>
        <DialogContent>
          <DialogContentText color={colors.grey[100]}>
            Are you sure you want to delete user{' '}
            <Typography component="span" fontWeight="555" color={colors.grey[100]}>
              {userToDelete ? `${userToDelete.first_name} ${userToDelete.last_name}` : ''}
            </Typography>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="error" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Freeze User Confirmation Dialog */}
      <Dialog
        open={freezeDialogOpen}
        onClose={handleCancelFreeze}
        PaperProps={{ sx: { backgroundColor: colors.primary[600] } }}
      >
        <DialogTitle
          color={colors.grey[100]}
          variant="h5"
          fontWeight="600"
          sx={{ position: 'sticky', top: 0, zIndex: 1 }}
        >
          Freeze User?
        </DialogTitle>
        <DialogContent>
          <DialogContentText color={colors.grey[100]}>
            Are you sure you want to freeze{' '}
            <Typography component="span" fontWeight="bold" color={colors.grey[100]}>
              {selectedUser?.first_name} {selectedUser?.last_name}
            </Typography>
            ? This will suspend all their activities.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelFreeze} color="error" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleConfirmFreeze} color="secondary" variant="contained">
            Freeze
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
      <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
        {snackbarMessage}
      </Alert>
      </Snackbar>
    </Box>
  );
};

export default Team;