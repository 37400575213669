import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Tooltip,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { tokens } from "../../theme";
import Header from "../../components/Header";

const CollateralUpload = ({ setFiles: setCollateralFiles, setDescriptions: setCollateralDescriptions }) => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [customDescription, setCustomDescription] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MAX_COLLATERAL_FILE_SIZE = 10 * 1024 * 1024;

  const documentTypes = [
    "Car Logbook",
    "KRA PIN",
    "Title Deed",
    "Passport",
    "Other",
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "application/pdf" || selectedFile.type.startsWith("image/jpeg"))
    ) {
      setFile(selectedFile);
    } else {
      alert("Only PDF and JPEG files are allowed.");
    }
  };

  const getTotalFileSize = (newFile) => {
    const currentTotalSize = files.reduce((total, file) => total + file.fileData.size, 0);
    return currentTotalSize + (newFile ? newFile.size : 0);
  };

  const handleAddFile = () => {
    if (!file || !documentType) {
      alert("Please select a file and document type.");
      return;
    }

    const newFileSize = getTotalFileSize(file);
    if (newFileSize > MAX_COLLATERAL_FILE_SIZE) {
      alert("The total size of all files cannot exceed 10 MB.");
      return;
    }

    const newFile = {
      id: files.length + 1,
      description: documentType === "Other" ? customDescription : documentType,
      fileName: file.name,
      fileData: file,
    };

    const updatedFiles = [...files, newFile];
    setFiles(updatedFiles);

    // Update parent states
    setCollateralFiles(updatedFiles.map((file) => file.fileData));
    setCollateralDescriptions(updatedFiles.map((file) => file.description));

    // Reset inputs
    setFile(null);
    setDocumentType("");
    setCustomDescription("");
  };

  const handleDeleteFile = (id) => {
    const updatedFiles = files.filter((file) => file.id !== id);
    setFiles(updatedFiles);

    // Update parent states
    setCollateralFiles(updatedFiles.map((file) => file.fileData));
    setCollateralDescriptions(updatedFiles.map((file) => file.description));
  };

  const columns = [
    { field: "id", headerName: "File Number", flex: 1 },
    { field: "description", headerName: "Description", flex: 2 },
    { field: "fileName", headerName: "File Name", flex: 2 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <IconButton
          color="success"
          onClick={() => handleDeleteFile(params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box sx={{ p: 1, backgroundColor: colors.primary[500], borderRadius: 2 }}>
      <Header subtitle="Collateral Upload:" />
      <Tooltip title="Upload collateral documents such as PDFs or JPEGs for your application">
        <Typography variant="body2" mb={3}>
          Please select a file and document type. You can upload multiple files.
        </Typography>
      </Tooltip>

      <Box display="flex" gap={2} mb={2} alignItems="center">
        <TextField
          select
          label="Document Type"
          value={documentType}
          onChange={(e) => setDocumentType(e.target.value)}
          sx={{ minWidth: 200 }}
        >
          {documentTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>

        {documentType === "Other" && (
          <TextField
            label="Custom Description"
            value={customDescription}
            onChange={(e) => setCustomDescription(e.target.value)}
            sx={{ minWidth: 200 }}
          />
        )}

        <Button
          variant="contained"
          component="label"
          color="info"
          sx={{ height: "33px", textTransform: "none" }}
        >
          Choose File
          <input
            type="file"
            hidden
            accept=".pdf, image/jpeg"
            onChange={handleFileChange}
          />
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddFile}
          disabled={!file || !documentType}
        >
          Add File
        </Button>
      </Box>

      {file && (
        <Typography variant="body2" mb={2}>
          Selected File: {file.name}
        </Typography>
      )}

      <DataGrid
        rows={files}
        columns={columns}
        autoHeight
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.primary[201],
            color: colors.primary[100],
          },
          mt: 3,
        }}
      />
    </Box>
  );
};

export default CollateralUpload;
