import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
    TextField,
    Grid,
    FormControlLabel,
    Checkbox,
    useTheme,
  } from '@mui/material';
  import CloseIcon from '@mui/icons-material/Close';
  import { tokens } from '../../theme';
  import { Formik, Form } from 'formik';
  import * as yup from 'yup';
  import React from 'react';
  
  // Validation schema for the form
  const validationSchema = yup.object({
    initiatorTime: yup.number().min(1, 'Must be greater than 0').required('Required'),
    reviewerTime: yup.number().min(1, 'Must be greater than 0').required('Required'),
    approverTime: yup.number().min(1, 'Must be greater than 0').required('Required'),
  });
  
  const UserNotificationSettings = ({ open, handleClose }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { backgroundColor: colors.primary[600] } }}
        fullWidth
      >
        <DialogTitle
          color={colors.grey[100]}
          variant="h5"
          fontWeight="600"
          sx={{ position: 'sticky', top: 0, zIndex: 1, paddingRight: '48px' }}
        >
           User Notification Settings:
          <Tooltip title="Close">
            <IconButton
              onClick={handleClose}
              style={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
  
        <DialogContent>
          <p>Configure the budgeted times for notifications below:</p>
          <Formik
            initialValues={{
              initiatorTime: '',
              reviewerTime: '',
              approverTime: '',
              notifyAdminOnExceed: false,
              forwardOnExceed: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              handleClose();
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Initiator Notification Time (minutes)"
                      name="initiatorTime"
                      type="number"
                      value={values.initiatorTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
    
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Reviewer Notification Time (minutes)"
                      name="reviewerTime"
                      type="number"
                      value={values.reviewerTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
  
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Approver Notification Time (minutes)"
                      name="approverTime"
                      type="number"
                      value={values.approverTime}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
  
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.notifyAdminOnExceed}
                          onChange={handleChange}
                          name="notifyAdminOnExceed"
                          color='secondary'
                        />
                      }
                      label="Notify Admin if budgeted time is exceeded"
                    />
                  </Grid>
  
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.forwardOnExceed}
                          onChange={handleChange}
                          name="forwardOnExceed"
                          color='secondary'
                        />
                      }
                      label="Forward report to next available user if budgeted time is exceeded"
                    />
                  </Grid>
                </Grid>
  
                <DialogActions>
                  <Button onClick={handleClose} color="error" variant="contained">
                    Cancel
                  </Button>
                  <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                    Save
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default UserNotificationSettings;
  