import { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, IconButton, Tooltip, Grid, RadioGroup, Radio, FormControlLabel, Typography, useTheme,
} from "@mui/material";
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { fetchWithAuth } from '../../auth';
import { tokens } from '../../theme';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from "@mui/icons-material/Close";

const AddNewUser = ({ open, handleClose, setUsers, users, handleOpenSnackbar }) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    phoneNumber: yup.string().required('Phone Number is required').matches(/^[0-9]{10}$/, 'Phone number must be 10 digits'),
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().required('Password is required'),
    role: yup.string().required('Role is required')
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const newUser = { ...values };
  
    try {
      const data = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/create_secondary_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(newUser),
      });
  
      setUsers([...users, data]);
      resetForm();
      handleClose();
      handleOpenSnackbar('User created successfully!');
    } catch (error) {
      if (error.response && error.response.status === 403) {
        handleOpenSnackbar('You are not authorized to perform this action!');
      } else {
        console.error('Error:', error);
        handleOpenSnackbar('An error occurred while creating the user!');
      }
    } finally {
      setSubmitting(false);
    }
  };
  
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundColor: colors.primary[600] } }}
      fullWidth
    >
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          password: '',
          role: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <DialogTitle
              color={colors.grey[100]}
              variant="h5"
              fontWeight="600"
              sx={{ position: 'sticky', top: 0, zIndex: 1, paddingRight: '48px' }}
            >
                Manage User Access:
              <Tooltip title="Close">
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field as={TextField} name="firstName" label="First Name" fullWidth margin="normal" error={errors.firstName && touched.firstName} helperText={touched.firstName && errors.firstName} />
                </Grid>
                <Grid item xs={6}>
                  <Field as={TextField} name="lastName" label="Last Name" fullWidth margin="normal" error={errors.lastName && touched.lastName} helperText={touched.lastName && errors.lastName} />
                </Grid>
              </Grid>
              <Field as={TextField} name="email" type="email" label="Email Address" fullWidth margin="normal" error={errors.email && touched.email} helperText={touched.email && errors.email} />
              <Field as={TextField} name="phoneNumber" label="Phone Number" fullWidth margin="normal" error={errors.phoneNumber && touched.phoneNumber} helperText={touched.phoneNumber && errors.phoneNumber} />
              <Field
                as={TextField}
                name="password"
                type={passwordVisibility ? 'text' : 'password'}
                label="Password"
                fullWidth
                margin="normal"
                error={errors.password && touched.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={togglePasswordVisibility}>
                      {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
              <Typography variant="subtitle1" gutterBottom fontWeight={600}>Assign Role:</Typography>
              <Field as={RadioGroup} name="role">
                <FormControlLabel value="initiator" control={<Radio color="secondary" />} label="Initiator" />
                <FormControlLabel value="reviewer" control={<Radio color="secondary" />} label="Reviewer" />
                <FormControlLabel value="admin" control={<Radio color="secondary" />} label="Admin" />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error" variant="contained">Cancel</Button>
              <Button type="submit" variant="contained" color="secondary">
                Add User
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddNewUser;