import { useState } from 'react';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Cookies from 'js-cookie';

const TabSecurity = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // ** States
  const [values, setValues] = useState({
    newPassword: '',
    currentPassword: '',
    confirmNewPassword: '',
    showNewPassword: false,
    showCurrentPassword: false,
    showConfirmNewPassword: false
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // can be 'success' or 'error'

  const handleTogglePasswordVisibility = (prop) => () => {
    setValues((prevValues) => ({
      ...prevValues,
      [prop]: !prevValues[prop]
    }));
  };

  const accessToken = Cookies.get('accessToken'); // Get the token from cookies

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-password`, {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword
      }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.status === 200) {
        setSuccessMessage('Password updated successfully');
        setSnackbarMessage('Password updated successfully');
        setSnackbarSeverity('success');
        setOpen(true);
        resetForm();
      } else {
        setErrorMessage(response.data.error || 'Something went wrong. Please try again.');
        setSnackbarMessage(response.data.error || 'Something went wrong. Please try again.');
        setSnackbarSeverity('error');
        setOpen(true);
      }
    } catch (error) {
      setErrorMessage('Incorrect Current Password');
      setSnackbarMessage('Incorrect Current Password');
      setSnackbarSeverity('error');
      setOpen(true);
    }
  
    setLoading(false);
    setSubmitting(false);
  };

  return (
    <Box mt="10px" mb="20px" backgroundColor={colors.primary[400]} >
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: ''
        }}
        validationSchema={Yup.object({
          currentPassword: Yup.string().required('Current Password is required!'),
          newPassword: Yup.string()
            .required('New Password is required!')
            .min(6, 'Password must be at least 6 characters!'),
          confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match!')
            .required('Confirm New Password is required!')
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CardContent sx={{ paddingBottom: 0 }}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sx={{ marginTop: 2.5 }}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor='account-settings-current-password'>Current Password</InputLabel>
                        <Field name="currentPassword">
                          {({ field }) => (
                            <OutlinedInput
                              {...field}
                              label='Current Password'
                              id='account-settings-current-password'
                              type={values.showCurrentPassword ? 'text' : 'password'}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    edge='end'
                                    aria-label='toggle password visibility'
                                    onClick={handleTogglePasswordVisibility('showCurrentPassword')}
                                    onMouseDown={(e) => e.preventDefault()}
                                  >
                                    {values.showCurrentPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage name="currentPassword" component="div" style={{ color: colors.redAccent[500] }} />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor='account-settings-new-password'>New Password</InputLabel>
                        <Field name="newPassword">
                          {({ field }) => (
                            <OutlinedInput
                              {...field}
                              label='New Password'
                              id='account-settings-new-password'
                              type={values.showNewPassword ? 'text' : 'password'}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    edge='end'
                                    onClick={handleTogglePasswordVisibility('showNewPassword')}
                                    aria-label='toggle password visibility'
                                    onMouseDown={(e) => e.preventDefault()}
                                  >
                                    {values.showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage name="newPassword" component="div" style={{ color: colors.redAccent[500] }}/>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor='account-settings-confirm-new-password'>Confirm New Password</InputLabel>
                        <Field name="confirmNewPassword">
                          {({ field }) => (
                            <OutlinedInput
                              {...field}
                              label='Confirm New Password'
                              id='account-settings-confirm-new-password'
                              type={values.showConfirmNewPassword ? 'text' : 'password'}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    edge='end'
                                    aria-label='toggle password visibility'
                                    onClick={handleTogglePasswordVisibility('showConfirmNewPassword')}
                                    onMouseDown={(e) => e.preventDefault()}
                                  >
                                    {values.showConfirmNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage name="confirmNewPassword" component="div" style={{ color: colors.redAccent[500] }} />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <br/>

            <Divider sx={{ margin: 0 }} />

            <CardContent>
              <Box sx={{ mt: 1.75, display: 'flex', alignItems: 'center' }}>
                <KeyIcon sx={{ marginRight: 3 }} />
                <Typography variant='h5'>Two-factor authentication</Typography>
              </Box>

              <Box sx={{ mt: 5.75, display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    maxWidth: 368,
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Avatar
                    variant='rounded'
                    sx={{ width: 48, height: 48, color: 'common.white', backgroundColor: 'success.main' }}
                  >
                    <LockOpenOutlinedIcon sx={{ fontSize: '1.75rem' }} />
                  </Avatar>
                  <Typography sx={{ fontWeight: 600, marginTop: 3.5, marginBottom: 3.5 }}>
                    Two factor authentication is enabled.
                  </Typography>
                  <Typography variant='body2'>
                    Two-factor authentication adds an additional layer of security to your account by requiring more than just
                    a password to log in. Learn more.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 11 }}>
                <Button type='submit' variant='contained' sx={{ marginRight: 3.5, backgroundColor: colors.orangeAccent[911] }} disabled={isSubmitting || loading}>
                  {loading ? 'Updating...' : 'Update Password'}
                </Button>
              </Box>

              {errorMessage && (
                <Box sx={{ color: 'red', marginTop: 2 }}>
                  {errorMessage}
                </Box>
              )}
              {successMessage && (
                <Box sx={{ color: 'green', marginTop: 2 }}>
                  {successMessage}
                </Box>
              )}
            </CardContent>
          </Form>
        )}
      </Formik>

      <Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default TabSecurity;

