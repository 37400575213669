// IPFChartPerformance.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../../theme';

const IPFChartPerformance = ({ selectedMonth, selectedYear }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                type: 'donut',
                height: 350,
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '60%',
                    },
                    dataLabels: {
                        offset: -10,
                        style: {
                            fontSize: '12px',
                            color: colors.grey[100],
                        },
                    },
                },
            },
            colors: [], // Will be populated dynamically
            labels: [],
            legend: {
                position: 'bottom',
                labels: {
                    colors: colors.grey[100],
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }],
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/top-ipf-sales-members`, {
                    params: { month: selectedMonth, year: selectedYear },
                });

                const membersData = response.data.top_ipf_sales_members;

                if (membersData.length > 0) {
                    const salesData = membersData.map(member => member.ipf_net_sales);
                    const memberNames = membersData.map(member => member.team_member_name);

                    // Generate random colors for each member
                    const memberColors = memberNames.map(() => `#${Math.floor(Math.random()*16777215).toString(16)}`);

                    setChartData(prevState => ({
                        ...prevState,
                        series: salesData,
                        options: {
                            ...prevState.options,
                            labels: memberNames,
                            colors: memberColors,
                        },
                    }));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (selectedMonth && selectedYear) {
            fetchData();
        }
    }, [selectedMonth, selectedYear]);

    return (
        <Box sx={{ width: '100%', padding: 2 }}>
            <Typography color={colors.grey[100]} variant="h6" fontWeight="600" gutterBottom>
                Top IPF Sales Members for {selectedMonth}/{selectedYear}:
            </Typography>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="donut"
                height={350}
            />
        </Box>
    );
};

export default IPFChartPerformance;
