import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  Chip
} from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { tokens } from "../../theme";
import { fetchWithAuth } from "../../auth";

const LoanRequest = ({ values, errors, touched, handleChange, setFieldValue, customerDetails, customerId }) => {
  const [loanProducts, setLoanProducts] = useState([]);
  const [selectedLoanProduct, setSelectedLoanProduct] = useState("");
  const [processingFee, setProcessingFee] = useState(0);
  const [loanManagementFee, setLoanManagementFee] = useState(0);
  const [creditLifeInsurance, setCreditLifeInsurance] = useState(0);
  const [valuationFee, setValuationFee] = useState('');
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmountPayable, setTotalAmountPayable] = useState(0);
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);
  const [open, setOpen] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [maxLoanTenor, setMaxLoanTenor] = useState(0);

  useEffect(() => {
    const fetchLoanProducts = async () => {
      try {
        const loanProductsData = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/loan-products`);   
        if (loanProductsData) {
          setLoanProducts(loanProductsData);
        }
      } catch (error) {
        console.error('Error fetching loan products:', error);
      }
    };

    fetchLoanProducts();
  }, []);

  const handleLoanProductChange = async (productId) => {
    setSelectedLoanProduct(productId);
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/loan-product-details/${productId}`);
      const loanProduct = response.data;
  
      setFieldValue('interest', loanProduct.interest_rate);
      setFieldValue('interestMode', loanProduct.mode_of_interest);
      setFieldValue('additionalFee', loanProduct.processing_fees);
      setMaxLoanTenor(loanProduct.loan_tenor); // Set the max loan tenor
    } catch (error) {
      console.error('Error fetching loan product details:', error);
    }
  };

  useEffect(() => {
    if (selectedLoanProduct === "1" && values.amount) {
      setProcessingFee(Math.round(values.amount * 0.035));
      setLoanManagementFee(Math.round(values.amount * 0.013));
      setCreditLifeInsurance(Math.round(values.amount * 0.012));
    }
  }, [selectedLoanProduct, values.amount]);

  useEffect(() => {
    if (values.amount && values.interest && values.tenure) {
      // Calculate the principal amount including fees
      const principalAmount = 
        parseFloat(values.amount) +
        processingFee +
        loanManagementFee +
        creditLifeInsurance +
        (valuationFee ? parseFloat(valuationFee) : 0) +
        (values.additionalFee ? parseFloat(values.additionalFee) : 0);
        
      const monthlyInterestRate = values.interestMode === "yearly" 
        ? values.interest / 12 / 100 
        : values.interest / 100;
  
      const totalInterestAmount = principalAmount * monthlyInterestRate * values.tenure;
      setTotalInterest(totalInterestAmount.toFixed(2));
  
      // Calculate the total tracking fee for the entire loan duration
      const totalTrackingFee = 2000 * values.tenure;
  
      // Add the total tracking fee to the total amount payable
      const totalPayableAmount = principalAmount + parseFloat(totalInterestAmount) + totalTrackingFee;
      setTotalAmountPayable(totalPayableAmount.toFixed(2));
  
      setFieldValue('totalInterest', totalInterestAmount.toFixed(2));
      setFieldValue('totalAmountPayable', totalPayableAmount.toFixed(2));
    }
  }, [values.amount, values.interest, values.tenure, values.interestMode, setFieldValue, processingFee, loanManagementFee, creditLifeInsurance, valuationFee, values.additionalFee]);

  useEffect(() => {
    setFieldValue('loanApplicationDate', new Date().toISOString().split('T')[0]);
  }, [setFieldValue]);  
  
  const calculateAmortizationSchedule = (principalAmount, tenure, interest, interestMode, deductUpfront, totalPayableAmount) => {
    const monthlyInterestRate = interestMode === "yearly" ? interest / 12 / 100 : interest / 100;
    const trackingFee = selectedLoanProduct === "1" ? 2000 : 0;
  
    // Calculate the original EMI without tracking fee
    const baseEmi = (principalAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, tenure)) /
                    (Math.pow(1 + monthlyInterestRate, tenure) - 1);
  
    // Add tracking fee to EMI
    const emi = baseEmi + trackingFee + 2010;
  
    let balance = principalAmount;
    let payableBalance = totalPayableAmount;
    let schedule = [];
  
    for (let month = 1; month <= tenure; month++) {
      const interestPayment = balance * monthlyInterestRate;
      const principalPayment = emi - interestPayment - trackingFee;
      balance -= principalPayment;
  
      payableBalance -= emi;
  
      if (balance < 0) {
        balance = 0;
      }
      if (payableBalance < 0) {
        payableBalance = 0;
      }
  
      schedule.push({
        id: month,
        month,
        startingLoanAmount: (balance + principalPayment).toFixed(2),
        startingPayableAmount: (payableBalance + emi).toFixed(2),
        paymentOnPrincipal: principalPayment.toFixed(2),
        paymentOnInterest: interestPayment.toFixed(2),
        emi: emi.toFixed(2),
        endingLoanAmount: balance.toFixed(2),
        endingPayableAmount: payableBalance.toFixed(2),
        ...(selectedLoanProduct === "1" && { trackingFee: trackingFee.toFixed(2) }),
      });
    }
  
    setAmortizationSchedule(schedule);
    setOpen(true);
  }; 
  
  const handleCalculateAmortization = () => {
    const { tenure, interest, interestMode, deductInterestUpfront } = values;
  
    // Calculate the principal amount
    const principalAmount =
      values.amount +
      processingFee +
      loanManagementFee +
      creditLifeInsurance +
      (valuationFee ? parseFloat(valuationFee) : 0) +
      (values.additionalFee ? parseFloat(values.additionalFee) : 0);
  
    if (tenure > maxLoanTenor) {
      setFieldValue('tenure', maxLoanTenor);
      alert('Tenor exceeds allowed period for this product');
      return;
    }
  
    calculateAmortizationSchedule(principalAmount, tenure, interest, interestMode, deductInterestUpfront, totalAmountPayable);
  };
  
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <TextField
          fullWidth
          variant="filled"
          type="number"
          label="Loan Amount (Client Request)"
          name="amount"
          value={values.amount}
          onChange={handleChange}
          error={touched.amount && !!errors.amount}
          helperText={touched.amount && errors.amount}
          sx={{ gridColumn: "span 4" }}
        />
        <TextField
          fullWidth
          variant="filled"
          select
          label="Select Loan Product"
          name="loanProduct"
          value={values.loanProduct}
          onChange={(e) => {
            setFieldValue('loanProduct', e.target.value);
            handleLoanProductChange(e.target.value);
          }}
          error={touched.loanProduct && !!errors.loanProduct}
          helperText={touched.loanProduct && errors.loanProduct}
          sx={{ gridColumn: "span 2" }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="" disabled></option>
          {loanProducts.map((product) => (
            <option key={product.id} value={product.id}>
              {product.product_name}
            </option>
          ))}
        </TextField>
        <TextField
          fullWidth
          variant="filled"
          type="number"
          label="Loan Tenor (in months)"
          name="tenure"
          value={values.tenure}
          onChange={(e) => {
            const value = e.target.value;
            if (value <= maxLoanTenor) {
              handleChange(e);
            } else {
              setFieldValue('tenure', maxLoanTenor);
            }
          }}
          error={touched.tenure && !!errors.tenure}
          helperText={touched.tenure && errors.tenure}
          sx={{ gridColumn: "span 2" }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="number"
          label="Interest Rate"
          name="interest"
          value={values.interest}
          onChange={handleChange}
          error={touched.interest && !!errors.interest}
          helperText={touched.interest && errors.interest}
          sx={{ gridColumn: "span 2" }}
        />
        <TextField
          fullWidth
          variant="filled"
          select
          label="Mode of Interest"
          name="interestMode"
          value={values.interestMode}
          onChange={handleChange}
          error={touched.interestMode && !!errors.interestMode}
          helperText={touched.interestMode && errors.interestMode}
          sx={{ gridColumn: "span 2" }}
          SelectProps={{
            native: true,
          }}
        >
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </TextField>
        <TextField
          fullWidth
          variant="filled"
          type="number"
          label="Pre-Disbursement Fixed Fee"
          name="additionalFee"
          value={values.additionalFee}
          onChange={handleChange}
          error={touched.additionalFee && !!errors.additionalFee}
          helperText={touched.additionalFee && errors.additionalFee}
          sx={{ gridColumn: "span 2" }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="date"
          label="Date (Loan Applied)"
          name="loanApplicationDate"
          value={values.loanApplicationDate}
          onChange={handleChange}
          error={touched.loanApplicationDate && !!errors.loanApplicationDate}
          helperText={touched.loanApplicationDate && errors.loanApplicationDate}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ gridColumn: "span 2" }}
        />
        {values.loanProduct === "1" && (
        <>
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Processing Fee"
            name="processingFee"
            value={processingFee}
            onChange={(e) => setProcessingFee(Math.round(e.target.value))}
            sx={{ gridColumn: "span 2" }}
            InputProps={{ readOnly: true }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Loan Management Fee"
            name="loanManagementFee"
            value={loanManagementFee}
            onChange={(e) => setLoanManagementFee(Math.round(e.target.value))}
            sx={{ gridColumn: "span 2" }}
            InputProps={{ readOnly: true }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Credit Life Insurance"
            name="creditLifeInsurance"
            value={creditLifeInsurance}
            onChange={(e) => setCreditLifeInsurance(Math.round(e.target.value))}
            sx={{ gridColumn: "span 2" }}
            InputProps={{ readOnly: true }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Valuation Fee"
            name="valuationFee"
            value={valuationFee}
            onChange={(e) => setValuationFee(e.target.value)}
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Principal Amount"
            name="principalAmount"
            value={
              values.amount +
              processingFee +
              loanManagementFee +
              creditLifeInsurance +
              (valuationFee ? parseFloat(valuationFee) : 0) +
              (values.additionalFee ? parseFloat(values.additionalFee) : 0)
            }
            InputProps={{ readOnly: true }}
            sx={{ gridColumn: "span 4" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Total Interest"
            name="totalInterest"
            value={totalInterest}
            InputProps={{ readOnly: true }}
            sx={{ gridColumn: "span 2" }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Total Amount Payable"
            name="totalAmountPayable"
            value={totalAmountPayable}
            InputProps={{ readOnly: true }}
            sx={{ gridColumn: "span 2" }}
          />
        </>
      )}
        {/* <FormControlLabel
          control={
            <Switch
              name="deductInterestUpfront"
              checked={values.deductInterestUpfront}
              onChange={(e) => setFieldValue('deductInterestUpfront', e.target.checked)}
              color="secondary"
            />
          }
          label="Deduct Interest Upfront"
          sx={{ gridColumn: "span 2" }}
        /> */}
      </Box>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCalculateAmortization}
        sx={{ mt: 3 }}
      >
        View Amortization Schedule
      </Button>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: colors.primary[500],
            maxWidth: '790px',
          },
        }}
      >
        <DialogTitle variant="h5" fontWeight="600">
          Amortization Schedule:
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <div>
            <Typography variant="h6" component="div" fontWeight="600" color="textPrimary">
              Customer: {`${customerDetails.firstName} ${customerDetails.lastName}`}
              <span>&nbsp;</span>
              <Chip
                label={
                  <span>
                    <span style={{ color: 'white' }}>✔</span>
                    <span>&nbsp;</span>
                    <span style={{ color: 'white' }}>verified</span>
                  </span>
                }
                color="secondary"
                size="small"
              />
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Email: {customerDetails.email}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              ID Number: {customerDetails.idNumber}
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ marginBottom: '6px' }}>
              Phone Number: {customerDetails.phoneNumber}
            </Typography>
          </div>
          <Box
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              rows={amortizationSchedule}
              columns={[
                { field: 'month', headerName: 'Month', width: 65 },
                { field: 'startingLoanAmount', headerName: 'Starting Loan Amount', width: 140 },
                { field: 'startingPayableAmount', headerName: 'Starting Payable Amount', width: 140 },
                { field: 'paymentOnPrincipal', headerName: 'Principal Payment', width: 130 },
                { field: 'paymentOnInterest', headerName: 'Interest Payment', width: 130 },
                ...(selectedLoanProduct === "1" ? [{ field: 'trackingFee', headerName: 'Tracking Fee', width: 130 }] : []),
                { field: 'emi', headerName: 'EMI', width: 130, cellClassName: "name-column--cell" },
                { field: 'endingLoanAmount', headerName: 'Ending Loan Amount', width: 140 },
                { field: 'endingPayableAmount', headerName: 'Ending Payable Amount', width: 140 },
              ]}
              pageSize={5}
              rowsPerPageOptions={[5]}
              autoHeight
              components={{
                Toolbar: GridToolbar,
              }}
              density="compact"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LoanRequest;

