import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import { ArrowDown as ArrowDownIcon } from '@phosphor-icons/react/dist/ssr/ArrowDown';
import { ArrowUp as ArrowUpIcon } from '@phosphor-icons/react/dist/ssr/ArrowUp';
import { Target as TargetIcon } from '@phosphor-icons/react';
import axios from 'axios';

export default function CompanyMonthlyLBFTarget({ selectedMonth, selectedYear }) {
  const [lbfTarget, setLbfTarget] = useState(null);
  const [error, setError] = useState(null);

  const TrendIcon = Math.random() > 0.5 ? ArrowUpIcon : ArrowDownIcon; // Randomly choose up or down
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const trendColor = colors.greenAccent[400]; // Randomly choose a trend color
  const diff = Math.floor(Math.random() * 101); // Random percentage between 0 and 100

  const fetchSalesData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/lbf-sales-summary`, {
        params: {
          month: selectedMonth,
          year: selectedYear,
        },
      });
      console.log('Sales Data:', response.data.sales_data); // Debugging log

      const lbfTarget = response.data.sales_data?.[0]?.lbf_target || 0;
      setLbfTarget(lbfTarget);
      setError(null);
    } catch (err) {
      console.error('Error fetching sales data:', err.response?.data?.message || err); // Debugging log
      setError(err.response?.data?.message || 'Failed to fetch data.');
      setLbfTarget(null);
    }
  };

  useEffect(() => {
    fetchSalesData();
  }, [selectedMonth, selectedYear]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'KES', // Currency code for Kenyan Shilling
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <Card sx={{ padding: '10px', backgroundColor: colors.primary[400] }}>
      <CardContent>
        <Stack spacing={0.5}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={1.5}>
            <Stack spacing={1}>
              <Typography color={colors.grey[100]} fontWeight="600" variant="h6">
                LBF Target:
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]} fontWeight="bold">
                {lbfTarget !== null ? formatCurrency(lbfTarget) : 'KES 0.00'}
              </Typography>
            </Stack>
            <Avatar sx={{ backgroundColor: colors.redAccent[400], height: '56px', width: '56px' }}>
              <TargetIcon fontSize="var(--icon-fontSize-lg)" />
            </Avatar>
          </Stack>
          {diff ? (
            <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
              <Stack sx={{ alignItems: 'center' }} direction="row" spacing={0.5}>
                <TrendIcon color={trendColor} fontSize="var(--icon-fontSize-md)" />
                <Typography color={trendColor} variant="body2">
                  0%
                </Typography>
              </Stack>
              <Typography color="text.secondary" variant="caption">
                Since last month
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}




// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Avatar from '@mui/material/Avatar';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
// import { useTheme } from "@mui/material";
// import { tokens } from '../../../../theme';
// import { ArrowUp as ArrowUpIcon } from '@phosphor-icons/react';
// import { Target as TargetIcon } from '@phosphor-icons/react';

// export default function CompanyLBFTarget({ selectedMonth, selectedYear }) {
//   const [salesData, setSalesData] = useState({ current: [], previous: [] });
//   const [error, setError] = useState(null);

//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const formatCurrency = (value) => {
//     return new Intl.NumberFormat('en-US', {
//       style: 'currency',
//       currency: 'KES',
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2,
//     }).format(value);
//   };

//   const getMonthIndex = (monthName) => {
//     const months = [
//       "January", "February", "March", "April", "May", "June", 
//       "July", "August", "September", "October", "November", "December"
//     ];
//     return months.indexOf(monthName) + 1;
//   };

//   const getPreviousMonthAndYear = (monthName, year) => {
//     const monthIndex = getMonthIndex(monthName);
//     if (monthIndex === 1) {
//       return { prevMonth: "December", prevYear: year - 1 };
//     }
//     const months = [
//       "January", "February", "March", "April", "May", "June", 
//       "July", "August", "September", "October", "November", "December"
//     ];
//     return { prevMonth: months[monthIndex - 2], prevYear: year };
//   };

//   const fetchSalesData = async () => {
//     try {
//       const { prevMonth, prevYear } = getPreviousMonthAndYear(selectedMonth, selectedYear);

//       const currentResponse = await axios.get(
//         `${process.env.REACT_APP_API_BASE_URL}/lbf-sales-summary`,
//         { params: { month: selectedMonth, year: selectedYear } }
//       );

//       let previousResponse = { data: { sales_data: [] } };
//       try {
//         previousResponse = await axios.get(
//           `${process.env.REACT_APP_API_BASE_URL}/lbf-sales-summary`,
//           { params: { month: prevMonth, year: prevYear } }
//         );
//       } catch (err) {
//         console.warn(`No sales data found for ${prevMonth} ${prevYear}.`);
//       }

//       setSalesData({
//         current: currentResponse.data.sales_data,
//         previous: previousResponse.data.sales_data,
//       });
//       setError(null);
//     } catch (err) {
//       console.error('Error fetching sales data:', err.response?.data?.message || err);
//       setError('Failed to fetch data for the current month.');
//       setSalesData({ current: [], previous: [] });
//     }
//   };

//   useEffect(() => {
//     fetchSalesData();
//   }, [selectedMonth, selectedYear]);

//   const calculatePercentageDifference = (current, previous) => {
//     if (!current || !previous || previous === 0) return null;
//     return ((current - previous) / previous) * 100;
//   };

//   const currentSales = salesData?.current?.[0]?.lbf_net_sales || null;
//   const previousSales = salesData?.previous?.[0]?.lbf_net_sales || null;
//   const calculatedDiff = calculatePercentageDifference(currentSales, previousSales);

//   return (
//     <Card sx={{ padding: '10px', backgroundColor: colors.primary[400] }}>
//       <CardContent>
//         <Stack spacing={0.5}>
//           <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={1.5}>
//             <Stack spacing={1}>
//               <Typography color={colors.grey[100]} fontWeight="600" variant="h6">
//                 LBF Target
//               </Typography>
//               <Typography variant="h5" color={colors.greenAccent[500]} fontWeight="bold">
//                 {currentSales !== null && !isNaN(Number(currentSales))
//                   ? formatCurrency(Number(currentSales))
//                   : 'KES 0.00'}
//               </Typography>
//             </Stack>
//             <Avatar sx={{ backgroundColor: colors.redAccent[400], height: '56px', width: '56px' }}>
//               <TargetIcon fontSize="var(--icon-fontSize-lg)" />
//             </Avatar>
//           </Stack>
//           {calculatedDiff !== null && (
//             <Stack sx={{ alignItems: 'center' }} direction="row" spacing={2}>
//               <Stack sx={{ alignItems: 'center' }} direction="row" spacing={0.5}>
//                 <ArrowUpIcon 
//                   color={calculatedDiff >= 0 ? colors.greenAccent[400] : colors.redAccent[400]} 
//                   fontSize="var(--icon-fontSize-md)" 
//                 />
//                 <Typography color={calculatedDiff >= 0 ? colors.greenAccent[400] : colors.redAccent[400]} variant="body2">
//                   {Math.abs(calculatedDiff.toFixed(2))}%
//                 </Typography>
//               </Stack>
//               <Typography color="text.secondary" variant="caption">
//                 Since last month
//               </Typography>
//             </Stack>
//           )}
//         </Stack>
//         {error && (
//           <Typography color="error" variant="body2" mt={2}>
//             {error}
//           </Typography>
//         )}
//       </CardContent>
//     </Card>
//   );
// }
