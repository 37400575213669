import React, { useState } from "react";
import { Box, Button, Snackbar, Stepper, Step, StepLabel, useTheme, Divider} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from 'axios';
import Header from "../../components/Header";
import CustomerKYC from "./customerkyc";
import LoanRequest from "./loanrequest";
import UploadStatement from "./uploadstatement";
import BankAddress from "./bankAddress";
import Cookies from 'js-cookie';
import { tokens } from "../../theme";
import { styled } from "@mui/system";
import Check from "@mui/icons-material/Check";
import StepConnector from "@mui/material/StepConnector";
import CollateralUpload from "./collateral_upload";
import VehicleRegistration from "./vehicleregistration";
import EmploymentDetails from "./employmentDetails";
import CustomerReferral from "./customerReferral";
import BankStatement from "./BankStatement";
import { fetchWithAuth } from "../../auth";

const NewAppraisal = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [customerDetails, setCustomerDetails] = useState({});
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isBankStatementValid, setIsBankStatementValid] = useState(false);
  const [conditionalStep, setConditionalStep] = useState(false);
  const [collateralFiles, setCollateralFiles] = useState([]);
  const [collateralDescriptions, setCollateralDescriptions] = useState([]);


  // Custom StepIcon
  const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ownerState.completed ? 'white' : colors.primary[100],
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: ownerState.completed ? colors.greenAccent[500] : 'transparent',
    border: `1.5px solid ${colors.myAccent[200]}`,
  }));

  const StepIcon = (props) => {
    const { active, completed, className } = props;

    return (
      <StepIconRoot ownerState={{ completed, active }} className={className}>
        {completed ? <Check style={{ color: colors.primary[500] }} /> : <div>{props.icon}</div>}
      </StepIconRoot>
    );
  };

  // Styled StepLabel
  const GreenStepLabel = styled(StepLabel)(({ theme }) => ({
    '& .MuiStepLabel-label': {
      color: colors.greenAccent[300],
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: colors.greenAccent[300],
    },
    '& .MuiStepLabel-label.Mui-completed': {
      color: colors.greenAccent[300],
    },
  }));

  const initialSteps = ["Customer KYC", "Loan Request", "Upload Statement"];
  const [steps, setSteps] = useState(initialSteps);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    idNumber: "",
    amount: "",
    loanProduct: "",
    tenure: "",
    interest: "",
    interestMode: "yearly",
    additionalFee: "0.00",
    loanApplicationDate: "",
    bankStatements: Array(6).fill({ date: '', credit: '', debit: '', balance: '' }),
    mpesaStatement: null,
    statementPassword: "",
    vehicle_registration_number: "",
    vehicle_chassis_number: "",
    car_make: "",
    car_model: "",
    year_of_manufacture: "",
    market_value: "",
    initial_forced_sale_value: "",
    condition: "",
    log_book_number: "",
    registration_date: "",
    customs_entry_number: "",
    body_type: "",
    body_color: "",
    engine_number: "",
    fuel_type: "",
    rating: "",
    log_book_serial_number: "",
    current_owner: "",
    owner_kra_pin: "",
  };

  const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    phoneNumber: yup
      .string()
      .matches(/^\d{1,10}$/, "Please enter a valid phone number with a maximum of 10 digits.")
      .required("Required"),
    idNumber: yup.number().required("Required"),
    first_name: yup.string().required("Required"),
    last_name: yup.string().required("Required"),
    next_of_kin_email: yup.string().email("Invalid email").required("Required"),
    phone_number: yup
      .string()
      .matches(/^\d{1,10}$/, "Please enter a valid phone number with a maximum of 10 digits.")
      .required("Required"),
    id_number: yup.number().required("Required"),
    relationship: yup.string().required("Required"),
    specify_relationship: yup.string().when("relationship", {
      is: "other",
      then: yup.string().required("Required"),
    }), 
    postalAddress: yup.string().required('Required'),
    postalCode: yup.string().required('Required'),
    county: yup.string().required('Required'),
    location: yup.string().required('Required'),
    estateBuilding: yup.string().required('Required'),
    cityTown: yup.string().required('Required'),
    description: yup.string().required('Required'),
    bankName: yup.string().required('Required'),
    branch: yup.string().required('Required'),
    accountName: yup.string().required("Required"),
    accountNumber: yup.string().required("Required").matches(/^\d+$/, "Must be a valid account number"),
    amount: yup.number().required("Required").positive("Must be positive"),
    tenure: yup.number().required("Required").positive("Must be positive").integer("Must be an integer"),
    interest: yup.number().required("Required").positive("Must be positive"),
    interestMode: yup.string().required("Required").oneOf(["monthly", "yearly"]),
    additionalFee: yup.number().required("Required").min(0, "Must be at least 0"),
    loanApplicationDate: yup.date().required("Required").nullable(),
    mpesaStatement: yup.mixed().required("Required"),
    statementPassword: yup.string().nullable(),
  });

  // Partial schema for Customer KYC validation
  const kycSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    phoneNumber: yup
      .string()
      .matches(/^\d{1,10}$/, "Please enter a valid phone number with a maximum of 10 digits.")
      .required("Required"),
    idNumber: yup.number().required("Required"),
  });

    // Partial Schema for Next of Kin validation
    const nextOfKinSchema = yup.object().shape({
      first_name: yup.string().required("Required"),
      last_name: yup.string().required("Required"),
      next_of_kin_email: yup.string().email("Invalid email").required("Required"),
      phone_number: yup
        .string()
        .matches(/^\d{1,10}$/, "Please enter a valid phone number with a maximum of 10 digits.")
        .required("Required"),
      id_number: yup.number().required("Required"),
      relationship: yup.string().required("Required"),
      specify_relationship: yup.string().when("relationship", {
        is: "other",
        then: yup.string().required("Required"),
      }),
    });
  
  // Partial schema for Customer Details validation
  const customerDetailsSchema = yup.object().shape({
    postalAddress: yup.string().required('Required'),
    postalCode: yup.string().required('Required'),
    county: yup.string().required('Required'),
    location: yup.string().required('Required'),
    estateBuilding: yup.string().required('Required'),
    cityTown: yup.string().required('Required'),
    description: yup.string().required('Required'),
    bankName: yup.string().required('Required'),
    branch: yup.string().required('Required'),
    accountName: yup.string().required("Required"),
    accountNumber: yup.string().required("Required").matches(/^\d+$/, "Must be a valid account number"),
  });

  // Partial schema for Loan Request validation
  const loanRequestSchema = yup.object().shape({
    amount: yup.number().required("Required").positive("Must be positive"),
    tenure: yup.number().required("Required").positive("Must be positive").integer("Must be an integer"),
    interest: yup.number().required("Required").positive("Must be positive"),
    interestMode: yup.string().required("Required").oneOf(["monthly", "yearly"]),
    additionalFee: yup.number().required("Required").min(0, "Must be at least 0"),
    loanApplicationDate: yup.date().required("Required").nullable(),
    loanProduct: yup.string().required("Required"),
  });

  // Partial schema for Motor Vehicle Records validation
  const motorVehicleSchema = yup.object().shape({
    vehicle_registration_number: yup.string()
      .required("Vehicle registration number is required")
      .max(15, "Max 15 characters"),
    vehicle_chassis_number: yup.string().required("Chassis number is required"),
    car_make: yup.string().required("Car make is required"),
    car_model: yup.string().required("Car model is required"),
    year_of_manufacture: yup.number()
      .required("Year of manufacture is required")
      .min(1900, "Must be a valid year")
      .max(new Date().getFullYear(), "Cannot be in the future"),
    market_value: yup.number()
      .required("Market value is required")
      .positive("Market value must be positive"),
    initial_forced_sale_value: yup.number()
      .required("Initial forced sale value is required")
      .positive("Must be positive"),
    condition: yup.string().required("Condition is required"),
    log_book_number: yup.string().required("Log book number is required"),
    registration_date: yup.date()
      .required("Registration date is required")
      .max(new Date(), "Cannot be in the future"),
    customs_entry_number: yup.string().required("Customs Entry number is required"),
    body_type: yup.string().required("Body Type is required"),
    body_color: yup.string().required("Body Color is required"),
    engine_number: yup.string().required("Engine Number is required"),
    fuel_type: yup.string().required("Fuel Type is required"),
    rating: yup.string().required("Rating is required"),
    log_book_serial_number: yup.string().required("Log Book Serial Number is required"),
    current_owner: yup.string().required("Current Owner is required"),
    owner_kra_pin: yup.string().required("Owner KRA Pin is required"),
  });
  
  // Partial schema for Bank Statement
  const bankStatementSchema = yup.object().shape({
    date: yup.date().required("Date is required"),
    debit: yup.number().required("Debit is required").positive("Debit must be positive"),
    credit: yup.number().required("Credit is required").positive("Credit must be positive"),
    balance: yup.number().required("Balance is required").positive("Balance must be positive"),
  });

  // Partial schema for Upload Statement validation
  const uploadStatementSchema = yup.object().shape({
    mpesaStatement: yup.mixed().required("*Required"),
    statementPassword: yup.string().nullable(),
  });
  
  const handleFormSubmit = async (values, { resetForm, setFieldValue }) => {
    const isBankStatementEmpty = values.bankStatements.every(
      (statement) =>
        !statement.date && !statement.credit && !statement.debit && !statement.balance
    );
  
    if (!isBankStatementEmpty && !isBankStatementValid) {
      setSnackbarMessage("Please ensure the bank statements are valid before submitting.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
  
    let customer_id = null;
  
    try {
      const formData = new FormData();
      const accessToken = Cookies.get("accessToken");
  
      // Append basic form fields
      [
        "firstName",
        "lastName",
        "email",
        "phoneNumber",
        "idNumber",
        "amount",
        "tenure",
        "interest",
        "interestMode",
        "additionalFee",
        "loanApplicationDate",
        "mpesaStatement",
        "statementPassword",
      ].forEach((field) => formData.append(field, values[field]));
  
      // Create customer
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/create_customer`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      if (response.status === 201) {
        customer_id = response.data.customer_id;
        setIsSubmitSuccessful(true);
        setCustomerDetails({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          idNumber: values.idNumber,
        });
  
        const nextOfKinData = {
          first_name: values.first_name,
          last_name: values.last_name,
          next_of_kin_email: values.next_of_kin_email,
          phone_number: values.phone_number,
          id_number: values.id_number,
          relationship: values.relationship,
          specify_relationship: values.specify_relationship,
          customerId: customer_id,
        };
  
        const bankAddressData = {
          postalAddress: values.postalAddress,
          postalCode: values.postalCode,
          county: values.county,
          location: values.location,
          estateBuilding: values.estateBuilding,
          cityTown: values.cityTown,
          customerId: customer_id,
          description: values.description,
          bankName: values.bankName,
          branch: values.branch,
          accountName: values.accountName,
          accountNumber: values.accountNumber,
        };
  
        const employmentDetailsData = {
          isEmployed: values.isEmployed,
          employerName: values.employerName,
          employerAddress: values.employerAddress,
          employerPhoneNumber: values.employerPhoneNumber,
          isSelfEmployed: values.isSelfEmployed,
          natureOfBusiness: values.natureOfBusiness,
          businessAddress: values.businessAddress,
          businessPhoneNumber: values.businessPhoneNumber,
          customerId: customer_id,
        };
  
        const customerAssignmentData = {
          customerId: customer_id,
          officer: values.officer,
          referralCode: values.referralCode,
        };
  
        const loanRequestData = {
          amount: values.amount,
          tenure: values.tenure,
          interest: values.interest,
          interestMode: values.interestMode,
          additionalFee: values.additionalFee,
          loanApplicationDate: values.loanApplicationDate,
          customerId: customer_id,
          productName: values.loanProduct,
        };
  
        const VehicleRecordsData = {
          vehicle_registration_number: values.vehicle_registration_number,
          vehicle_chassis_number: values.vehicle_chassis_number,
          car_make: values.car_make,
          car_model: values.car_model,
          year_of_manufacture: values.year_of_manufacture,
          market_value: values.market_value,
          initial_forced_sale_value: values.initial_forced_sale_value,
          condition: values.condition,
          log_book_number: values.log_book_number,
          registration_date: values.registration_date,
          customs_entry_number: values.customs_entry_number,
          body_type: values.body_type,
          body_color: values.body_color,
          engine_number: values.engine_number,
          fuel_type: values.fuel_type,
          rating: values.rating,
          log_book_serial_number: values.log_book_serial_number,
          current_owner: values.current_owner,
          owner_kra_pin: values.owner_kra_pin,
          customer_id: customer_id,
        };
  
        // Skip motor vehicle API call if any value in VehicleRecordsData is empty
        const hasEmptyVehicleData = Object.values(VehicleRecordsData).some(
          (value) => value === "" || value === null || value === undefined
        );
  
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/next-of-kin`, nextOfKinData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
  
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/bank_and_physical_address`, bankAddressData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
  
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/loan-request`, loanRequestData);
  
        if (!hasEmptyVehicleData) {
          await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/create_motor_vehicle_record`,
            VehicleRecordsData
          );
        }
  
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/employment-details`, employmentDetailsData, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
  
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/customer/assign`, customerAssignmentData);
  
        if (!isBankStatementEmpty) {
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/bank_statement`, {
            customer_id,
            bankStatements: values.bankStatements,
          });
        }
  
        const collateralFormData = new FormData();
        collateralFiles.forEach((file, index) => {
          collateralFormData.append("file", file);
          collateralFormData.append("description", collateralDescriptions[index]);
        });
        collateralFormData.append("customer_id", customer_id);
  
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload_collateral`, collateralFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/trigger-appraisal`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify({ customer_id }),
        });
  
        setActiveStep(steps.length);
        resetForm();
        setFieldValue("mpesaStatement", null);
        setFieldValue("statementPassword", "");
        setCollateralFiles([]);
        setCollateralDescriptions([]);
        document.getElementById("mpesaStatement").value = "";
  
        setSnackbarMessage(
          "Customer created and appraisal started. You'll be notified upon completion!"
        );
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
  
        setTimeout(() => setActiveStep(0), 2000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (customer_id) {
        const accessToken = Cookies.get("accessToken");
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/customers/${customer_id}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      }
      if (error.response?.status === 409) {
        setSnackbarMessage("Customer already exists!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else if (
        error.response?.status === 400 &&
        error.response.data.error?.includes("Incorrect password")
      ) {
        setSnackbarMessage("Could not process PDF, Incorrect Password!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("An error occurred. PDF could not be processed!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
  
      setIsSubmitSuccessful(false);
    }
  };

  const handleNext = async (validateForm, setTouched, values) => {
    let errors = {};
  
    if (activeStep === 0) {
      try {
        await kycSchema.validate(values, { abortEarly: false });
        await nextOfKinSchema.validate(values, { abortEarly: false });
        await customerDetailsSchema.validate(values, { abortEarly: false });
      } catch (validationError) {
        validationError.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
      }
  
      setTouched({
        firstName: true,
        lastName: true,
        email: true,
        phoneNumber: true,
        idNumber: true,
        first_name: true,
        last_name: true,
        next_of_kin_email: true,
        phone_number: true,
        id_number: true,
        relationship: true,
        specify_relationship: true,
        postalAddress: true,
        postalCode: true,
        county: true,
        location: true,
        estateBuilding: true,
        cityTown: true,
        bankName: true,
        branch: true,
        accountName: true,
        accountNumber: true,
      });
    } else if (activeStep === 1) {
      try {
        await loanRequestSchema.validate(values, { abortEarly: false });
      } catch (validationError) {
        validationError.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
      }
  
      setTouched({
        amount: true,
        tenure: true,
        interest: true,
        interestMode: true,
        additionalFee: true,
        loanApplicationDate: true,
        loanProduct: true,
      });
  
      // Conditionally add 'Motor Vehicle Records' step based on loanProduct value
      if (values.loanProduct === "1" && !conditionalStep) {
        setSteps(["Customer KYC", "Loan Request", "Motor Vehicle Records", "Upload Statement"]);
        setConditionalStep(true);
      } else if (values.loanProduct !== "1" && conditionalStep) {
        setSteps(initialSteps);
        setConditionalStep(false);
      }
    } else if (activeStep === 2 && conditionalStep) {
      try {
        await motorVehicleSchema.validate(values, { abortEarly: false });
      } catch (validationError) {
        validationError.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
      }

      setTouched({
        vehicle_registration_number: true,
        vehicle_chassis_number: true,
        car_make: true,
        car_model: true,
        year_of_manufacture: true,
        market_value: true,
        initial_forced_sale_value: true,
        condition: true,
        log_book_number: true,
        registration_date: true,
        customs_entry_number: true,
        body_type: true,
        body_color: true,
        engine_number: true,
        fuel_type: true,
        rating: true,
        log_book_serial_number: true,
        current_owner: true,
        owner_kra_pin: true,
      });      
      
    } else if (activeStep === steps.length - 2 || (!conditionalStep && activeStep === 2)) {
      try {
        await uploadStatementSchema.validate(values, { abortEarly: false });
      } catch (validationError) {
        validationError.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
      }
  
      setTouched({
        mpesaStatement: true,
        statementPassword: true,
      });
    }
  
    if (Object.keys(errors).length === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      validateForm();
    }
  };  

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseSnackbar = () => {
    setIsSubmitSuccessful(false);
  };

  return (
    <Box m="20px">
      <Header title="NEW CUSTOMER APPRAISAL" subtitle="Create New Customer and Upload Statement." />
      <Stepper activeStep={activeStep} alternativeLabel connector={<StepConnector />} sx={{ mb: 4 }} color="secondary">
        {steps.map((label) => (
          <Step key={label}>
            <GreenStepLabel StepIconComponent={StepIcon}>{label}</GreenStepLabel>
          </Step>
        ))}
      </Stepper>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={
          activeStep === 0
            ? checkoutSchema
            : activeStep === 1
            ? loanRequestSchema
            : activeStep === 2 && conditionalStep
            ? motorVehicleSchema
            : uploadStatementSchema
        }
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          validateForm,
          setTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            {activeStep === 0 && (
              <>
                <CustomerKYC
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isNonMobile={isNonMobile}
                />
                <BankAddress
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isNonMobile={isNonMobile}
                />
                <EmploymentDetails
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isNonMobile={isNonMobile}
                />
                <CustomerReferral
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isNonMobile={isNonMobile}
                />
              </>    
            )}
            {activeStep === 1 && (
              <LoanRequest
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                customerDetails={values}
                customerId={values.idNumber}
              />
            )}
             {activeStep === 2 && conditionalStep && (
              <VehicleRegistration
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isNonMobile={isNonMobile}
               />
            )}
            {activeStep === steps.length - 1 && (
              <>
                <UploadStatement
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                <Divider sx={{ width: "100%", my: 2 }} />
                {/* <BankStatement/> */}
                <BankStatement
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isNonMobile={isNonMobile}
                  setUploadFlag={setIsBankStatementValid}
                />
                <Divider sx={{ width: "100%", my: 2 }} />
                <CollateralUpload
                  setFiles={setCollateralFiles}
                  setDescriptions={setCollateralDescriptions}
                />
              </>             
            )}
            <Box display="flex" justifyContent="space-between" mt="20px" mb="20px" paddingBottom="20px">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                color="info"
                variant="contained"
              >
                Previous
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button type="submit" color="secondary" variant="contained">
                  Generate Credit Score
                </Button>
              ) : (
                <Button onClick={() => handleNext(validateForm, setTouched, values)} color="secondary" variant="contained">
                  Next
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewAppraisal;