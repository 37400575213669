import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions" />

      {/* <Accordion defaultExpanded> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[300]} variant="h5">
            What is Carren, and how does it work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            Carren is a financial platform that utilizes alternative financial statements, such as M-Pesa transactions, to generate credit scores. It analyzes your financial behavior to assess creditworthiness beyond traditional credit data.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[300]} variant="h5">
            How do I generate a credit score using Carren?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            To generate a credit score with Carren, simply sign up and link your customer's financial accounts, such as M-Pesa. Our algorithms analyze your transaction history and financial patterns to generate a personalized credit score.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[300]} variant="h5">
            Is my data secure with Carren?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            Yes, we take data security seriously. Carren employs robust encryption and follows industry best practices to ensure the confidentiality and integrity of your financial data.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[300]} variant="h5">
            What alternative financial statements does Carren support?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            Carren currently supports M-Pesa statements, allowing you to leverage your mobile money transactions to build a comprehensive credit profile.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[300]} variant="h5">
            How is Carren different from traditional credit scoring systems?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            Unlike traditional credit scoring, Carren considers alternative financial data, providing a more inclusive and nuanced assessment of your creditworthiness. It's especially beneficial for individuals with limited or no credit history.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[300]} variant="h5">
            How long does it take to receive my credit score from Carren?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            The processing time varies, but you can typically receive your credit score within a short period after linking your financial accounts. We strive to provide quick and accurate assessments.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[300]} variant="h5">
            How do I dispute or correct information on my Carren credit report?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            If you notice inaccuracies, you can contact our customer support. We have a process in place to address disputes and ensure the accuracy of your credit information.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br/>
      <br/>
    </Box>
  );
};

export default FAQ;
