// import * as React from 'react';
// import { tokens } from "../../../theme";
// import { useTheme } from "@mui/material";
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Card from '@mui/material/Card';
// import Chip from '@mui/material/Chip';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import Container from '@mui/material/Container';
// import Divider from '@mui/material/Divider';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

// const tiers = [
//   {
//     title: 'Professional',
//     price: '15',
//     buttonText: 'Start Now',
//     buttonVariant: 'outlined',
//   },
//   {
//     title: 'Free',
//     subheader: 'Current',
//     price: '0',
//   },
//   {
//     title: 'Enterprise',
//     price: '30',
//     buttonText: 'Contact us',
//     buttonVariant: 'outlined',
//   },
// ];

// export default function Billing() {
//     const theme = useTheme();
//     const colors = tokens(theme.palette.mode);

//   return (
//     <Box mt="10px" mb="20px" backgroundColor={colors.primary[400]} >
//       <Container
//         id="pricing"
//         sx={{
//           pt: { xs: 1, sm: 3 }, // Adjusted padding on top
//           pb: { xs: 8, sm: 16 },
//           position: 'relative',
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           gap: { xs: 3, sm: 6 },
//         }}
//       >
//         <Box
//           sx={{
//             width: { sm: '100%', md: '60%' },
//             textAlign: { sm: 'left', md: 'center' },
//           }}
//         >
//           <Typography component="h2" variant="h4" color="text.primary" fontWeight={600}>
//             My Plan
//           </Typography>
//         </Box>
//         <Grid container spacing={3} alignItems="center" justifyContent="center">
//           {tiers.map((tier) => (
//             <Grid
//               item
//               key={tier.title}
//               xs={12}
//               sm={tier.title === 'Enterprise' ? 12 : 6}
//               md={4}
//             >
//               <Card
//                 sx={{
//                   p: 2,
//                   display: 'flex',
//                   flexDirection: 'column',
//                   gap: 4,
//                   border: tier.title === 'Free' ? '1px solid' : undefined,
//                   borderColor:
//                     tier.title === 'Free' ? 'primary.main' : undefined,
//                   background:
//                     tier.title === 'Free'
//                       ? 'linear-gradient(#033363, #021F3B)'
//                       : undefined,
//                 }}
//               >
//                 <CardContent>
//                   <Box
//                     sx={{
//                       mb: 1,
//                       display: 'flex',
//                       justifyContent: 'space-between',
//                       alignItems: 'center',
//                       color: tier.title === 'Free' ? 'grey.100' : '',
//                     }}
//                   >
//                     <Typography component="h3" variant="h6">
//                       {tier.title}
//                     </Typography>
//                     {tier.title === 'Free' && (
//                       <Chip
//                         icon={<AutoAwesomeIcon />}
//                         label={tier.subheader}
//                         size="small"
//                         sx={{
//                           background: (theme) =>
//                             theme.palette.mode === 'light' ? '' : 'none',
//                           backgroundColor: 'primary.contrastText',
//                           '& .MuiChip-label': {
//                             color: 'primary.dark',
//                           },
//                           '& .MuiChip-icon': {
//                             color: 'primary.dark',
//                           },
//                         }}
//                       />
//                     )}
//                   </Box>
//                   <Box
//                     sx={{
//                       display: 'flex',
//                       alignItems: 'baseline',
//                       color: tier.title === 'Free' ? 'grey.50' : undefined,
//                     }}
//                   >
//                     <Typography component="h3" variant="h2">
//                       ${tier.price}
//                     </Typography>
//                     <Typography component="h3" variant="h6">
//                       &nbsp; per month
//                     </Typography>
//                   </Box>
//                 </CardContent>
//                 <CardActions>
//                   <Button
//                     fullWidth
//                     variant={tier.buttonVariant}
//                     component="a"
//                     href="/material-ui/getting-started/templates/checkout/"
//                     target="_blank"
//                   >
//                     {tier.buttonText}
//                   </Button>
//                 </CardActions>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </Container>
//     </Box>
//   );
// }


import * as React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ArrowLeft as ArrowLeftIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom'; // Assuming you're using React Router

const Billing = () => {
  return (
    <Box component="main" sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', minHeight: '100vh' }}>
      <Stack spacing={3} sx={{ alignItems: 'center', maxWidth: 'md' }}>
        <Box>
          <img
            alt="Under development"
            src="/assets/error-404.png"
            style={{ display: 'inline-block', height: 'auto', maxWidth: '100%', width: '400px' }}
          />
        </Box>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          404: The page you are looking for isn't here
        </Typography>
        <Typography color="text.secondary" variant="body1" sx={{ textAlign: 'center' }}>
          Page under maintenance, will be back soon!
        </Typography>
        {/* <Button
          component={RouterLink}
          to="/" // Adjust the path as needed
          startIcon={<ArrowLeftIcon />}
          variant="contained"
          color="secondary"
        >
          Go back to home
        </Button> */}
      </Stack>
    </Box>
  );
}

export default Billing;

