import React from 'react';
import { CardContent, Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from '../../../theme';

const EmergencyContacts = ({ emergencyContacts }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <CardContent>
      <Typography variant="h6" color={colors.grey[100]}>
        Emergency Contacts
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Contact Name"
            type="text"
            fullWidth
            variant="outlined"
            value={emergencyContacts ? emergencyContacts.first_name : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense" 
            label="Contact Phone"
            type="text"
            fullWidth
            variant="outlined"
            value={emergencyContacts ? emergencyContacts.phone_number : ""}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default EmergencyContacts;
