// PortfolioVisualization.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { Box, Typography, Grid, useTheme} from '@mui/material';
import { tokens } from '../../../../theme';

const PortfolioVisualization = () => {
    const [portfolioData, setPortfolioData] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/global-company-portfolios`);
                setPortfolioData(response.data[0]); // Assuming only one company is present
            } catch (error) {
                console.error('Error fetching portfolio data:', error);
            }
        };

        fetchData();
    }, []);

    if (!portfolioData) {
        return <Typography>Loading...</Typography>;
    }

    const parValues = portfolioData.par_values;
    
    // Sort categories in chronological order
    const categories = Object.keys(parValues).sort((a, b) => {
        return parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]);
    });

    const values = categories.map(category => parValues[category] * 100); // Convert to percentage

    const chartOptions = {
        chart: {
            type: 'bar',
            height: 350,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
            },
        },
        xaxis: {
            categories: categories,
            title: {
                text: 'PAR',
                style: {
                    color: colors.grey[100], 
                },
            },
            labels: {
                style: {
                    colors: colors.grey[100],
                },
            },
        },
        yaxis: {
            title: {
                text: 'Percentage (%)',
                style: {
                    color: colors.grey[100],
                },
            },
            labels: {
                style: {
                    colors: colors.grey[100],
                },
            },
        },
        dataLabels: {
            enabled: true,
        },
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography color={colors.grey[100]} variant="h6" fontWeight="600" gutterBottom>
                Portfolio At Risk Values for {portfolioData.company_name}:
            </Typography>
            <Chart options={chartOptions} series={[{ name: 'PAR Values', data: values }]} type="bar" height={350} />
            
            <Grid container spacing={2} sx={{ marginTop: 3 }}>
                {categories.map((category, index) => (
                    <Grid item xs={12} sm={6} md={2} key={category}> {/* Use md={2} to ensure 5 items per row */}
                        <Box sx={{ padding: 2, borderRadius: 1, backgroundColor: colors.primary[400], textAlign: 'center' }}>
                            <Typography color={colors.grey[100]} variant="h6" fontWeight="600">{category.replace('par_', 'PAR ')}</Typography>
                            <Typography color={colors.greenAccent[500]} variant="h4" fontWeight="600">{(values[index]).toFixed(1)}%</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PortfolioVisualization;
