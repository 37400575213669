import React, { useState, useEffect } from "react";
import { Box, IconButton, Tooltip, Snackbar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';
import { fetchWithAuth } from "../../auth";
import CustomerDetails from "./customerdetails";
import Cookies from 'js-cookie';

const EditCustomers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Added this line
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleEdit = (customer) => {
    setSelectedCustomer(customer);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setSelectedCustomer(null);
    setEditDialogOpen(false);
  };

  const accessToken = Cookies.get('accessToken');

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/customers/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setCustomerData(customerData.filter((customer) => customer.id !== id));
      setSnackbarMessage('Customer deleted successfully!');
      setSnackbarSeverity('success'); // Set severity to success
      setSnackbarOpen(true);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting customer:", error);
      if (error.response && error.response.status === 403) {
        setSnackbarMessage('You are not authorized to perform the operation.');
        setSnackbarSeverity('error'); // Set severity to error
      } else {
        setSnackbarMessage('Error deleting customer.');
        setSnackbarSeverity('error'); // Set severity to error
      }
      setSnackbarOpen(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    handleDelete(selectedCustomer.id);
  };

  const handleSaveChanges = async () => {
    if (selectedCustomer) {
      try {
        // Make the PUT request using fetchWithAuth
        const response = await fetchWithAuth(
          `${process.env.REACT_APP_API_BASE_URL}/api/customers/${selectedCustomer.id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json', // Ensure the server knows we're sending JSON
            },
            data: JSON.stringify({
              // Add fields that you want to update
              phone_number: selectedCustomer.phone_number,
              email:selectedCustomer.email
            }),
          }
        );
  
        // Check if the request was successful
        if (response.status === 200) { // Check if response status is 200 OK
          const responseData = await response.json();
  
          // Update the customer data in the state
          setCustomerData(
            customerData.map((customer) =>
              customer.id === selectedCustomer.id ? responseData : customer
            )
          );
  
          // Close the dialog or perform any other necessary actions
          handleCloseEditDialog();
        } else {
          const errorData = await response.json();
          console.error("Error updating customer:", errorData);
        }
      } catch (error) {
        console.error("Error updating customer:", error);
      }
    }
 
    
  };


  

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    { field: "first_name", headerName: "First Name", flex: 1 },
    { field: "last_name", headerName: "Last Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone_number", headerName: "Phone Number", flex: 1, editable: true },
    { field: "id_number", headerName: "ID Number", flex: 1, editable: true },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <IconButton onClick={() => handleEdit(params.row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => {
              setSelectedCustomer(params.row);
              setDeleteDialogOpen(true);
            }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/customers`);
        setCustomerData(data);
      } catch (error) {
        console.error("Error fetching customer data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Box m="10px">
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={customerData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <CustomerDetails
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        onSave={handleSaveChanges}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        PaperProps={{ sx: { backgroundColor: colors.primary[201] } }}
      >
        <DialogTitle color={colors.grey[100]} variant="h5" fontWeight="600">Delete Customer?</DialogTitle>
        <DialogContent>
          <DialogContentText color={colors.grey[100]}>Proceed to delete Customer?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="error" variant="contained">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary" variant="contained">Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditCustomers;
