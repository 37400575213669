import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Tooltip,
  MenuItem,
  Grid,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const ReportDialogs = ({
  openDialog,
  handleDialogClose,
  dialogTitle,
  reviewers,
  admins,
  columns,
  note,
  setNote,
  selectedReviewer,
  setSelectedReviewer,
  selectedAdmin,
  setSelectedAdmin,
  handleSendReport,
  confirmDisbursementDialogOpen,
  handleConfirmDisbursementClose,
  handleConfirmDisbursement,
  customer,
  loanDisbursementDialogOpen,
  setLoanDisbursementDialogOpen,
  loanAmount,
  setLoanAmount,
  loanProduct,
  setLoanProduct,
  loanPeriod,
  setLoanPeriod,
  modeOfDisbursal,
  setModeOfDisbursal,
  bankAccountNumber,
  setBankAccountNumber,
  phoneNumber,
  setPhoneNumber,
  bankName,
  setBankName,
  interestRate,
  setInterestRate,
  emi,
  setEmi,
  firstPayment,
  setFirstPayment,
  handleDisburseFunds,
  openRejectionDialog,
  handleRejectionDialogClose,
  rejectionDialogTitle,
  dialogContent,
  rejectionReason,
  setRejectionReason,
  handleRejectApplication,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Helper function to format date to dd/mm/yyyy
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Calculate the default first payment date range
  useEffect(() => {
    if (!firstPayment) {
      const disbursementDate = new Date();
      const firstPaymentDate = new Date(disbursementDate);
      firstPaymentDate.setDate(disbursementDate.getDate() + 30);
      
      const lastPaymentDate = new Date(firstPaymentDate);
      lastPaymentDate.setDate(firstPaymentDate.getDate() + 7);
      
      const formattedRange = `${formatDate(firstPaymentDate)} - ${formatDate(lastPaymentDate)}`;
      setFirstPayment(formattedRange);
    }
  }, [firstPayment, setFirstPayment]);

  const handleRowClick = (params) => {
    if (dialogTitle.includes('Review')) {
      setSelectedReviewer(params.row);
    } else if (dialogTitle.includes('Approval')) {
      setSelectedAdmin(params.row);
    }
  };

  const getRowClassName = (params) => {
    if (
      (dialogTitle.includes('Review') && params.id === selectedReviewer?.id) ||
      (dialogTitle.includes('Approval') && params.id === selectedAdmin?.id)
    ) {
      return 'selected-row';
    }
    return '';
  };

  return (
    <>
      {/* Existing Send Report Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperProps={{ sx: { backgroundColor: colors.primary[500] } }}
        fullWidth
      >
        <DialogTitle sx={{ color: colors.grey[100], fontWeight: '600', fontSize: '1.25rem' }}>
          {dialogTitle}
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <div style={{ height: 250, width: '100%' }}>
            <DataGrid
              rows={dialogTitle.includes('Review') ? reviewers : admins}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowClick={handleRowClick}
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .selected-row": {
                  backgroundColor: `${colors.myAccent[101]} !important`,
                },
              }}
              getRowClassName={getRowClassName}
            />
          </div>
          <TextField
            autoFocus
            margin="dense"
            label="Note"
            type="text"
            fullWidth
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="error" variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleSendReport}
            color="secondary"
            variant="contained"
            disabled={
              dialogTitle.includes('Review') ? !selectedReviewer : !selectedAdmin
            }
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Existing Confirm Disbursement Dialog */}
      <Dialog
        open={confirmDisbursementDialogOpen}
        onClose={handleConfirmDisbursementClose}
        PaperProps={{ sx: { backgroundColor: colors.primary[500] } }}
        fullWidth
      >
        <DialogTitle sx={{ color: colors.grey[100], fontWeight: '600' }}>
          Approve Loan Application for {customer?.first_name} {customer?.last_name}?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmDisbursementClose} color="error" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleConfirmDisbursement} color="secondary" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* **New Loan Disbursement Dialog** */}
      <Dialog
        open={loanDisbursementDialogOpen}
        onClose={() => setLoanDisbursementDialogOpen(false)}
        PaperProps={{ sx: { backgroundColor: colors.primary[500] } }}
        fullWidth
      >
        <DialogTitle sx={{ color: colors.grey[100], fontWeight: '600' }}>
          Loan Disbursement for {customer?.first_name} {customer?.last_name}:
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Loan Amount"
                type="number"
                fullWidth
                value={loanAmount || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Loan Product"
                type="text"
                fullWidth
                value={loanProduct || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Loan Period (in months)"
                type="number"
                fullWidth
                value={loanPeriod || ''}
                // InputProps={{ readOnly: false }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Mode of Disbursal"
                select
                fullWidth
                value={modeOfDisbursal || ''}
                onChange={(e) => setModeOfDisbursal(e.target.value)}
              >
                <MenuItem value="bank transfer">Bank Transfer</MenuItem>
                <MenuItem value="M-PESA">M-PESA</MenuItem>
                <MenuItem value="cash">Cash</MenuItem>
              </TextField>
            </Grid>
            {modeOfDisbursal === 'bank transfer' && (
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label="Bank Account Number"
                  type="text"
                  fullWidth
                  value={bankAccountNumber || ''}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            )}
            {modeOfDisbursal === 'bank transfer' && (
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label="Bank Name"
                  type="text"
                  fullWidth
                  value={bankName || ''}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            )}
            {modeOfDisbursal === 'M-PESA' && (
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  label="Mobile Number"
                  type="text"
                  fullWidth
                  value={phoneNumber || ''}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Interest Rate (%)"
                type="number"
                fullWidth
                value={interestRate || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Equated Monthly Installment (EMI)"
                type="number"
                fullWidth
                value={emi || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Disbursement Date"
                type="text"
                fullWidth
                value={formatDate(new Date())}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="First Payment Date Range"
                type="text"
                fullWidth
                value={firstPayment || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLoanDisbursementDialogOpen(false)} color="error" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleDisburseFunds} color="secondary" variant="contained">
            Disburse Funds
          </Button>
        </DialogActions>
      </Dialog>

      {/* **Loan Rejection Dialog** */}
      <Dialog open={openRejectionDialog} onClose={handleRejectionDialogClose} fullWidth>
      <DialogTitle sx={{ color: colors.grey[100], fontWeight: '600' }}>
        {rejectionDialogTitle}
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              onClick={handleRejectionDialogClose}
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
      </DialogTitle>
      <DialogContent>
        <p>{dialogContent}</p>
        <TextField
          margin="dense"
          label="Provide Reason"
          type="text"
          fullWidth
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRejectionDialogClose} color="error" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleRejectApplication} color="secondary" variant="contained">
          Reject Application
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default ReportDialogs;