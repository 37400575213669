import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import axios from 'axios';

const CategoryScores = ({ customerId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [injectionScore, setInjectionScore] = useState(null);
  const [personalFinanceScore, setPersonalFinanceScore] = useState(null);
  const [shoppingScore, setShoppingScore] = useState(null);
  const [utilitiesScore, setUtilitiesScore] = useState(null);
  const [recreationScore, setRecreationScore] = useState(null);
  const [scores, setScores] = useState({
    dependantsScore: 0.50,
  });

  useEffect(() => {
    const fetchInjectionScore = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/injections/${customerId}/score`);
        setInjectionScore(response.data.injection_score);
      } catch (error) {
        console.error('Error fetching injection score:', error);
      }
    };

    const fetchShoppingScore = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/shopping/${customerId}/score`);
          setShoppingScore(response.data.shopping_score);
        } catch (error) {
          console.error('Error fetching shopping score:', error);
        }
      };

    const fetchPersonalFinanceScore = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/personal-finance/${customerId}/score`);
        setPersonalFinanceScore(response.data.personal_finance_score);
      } catch (error) {
        console.error('Error fetching personal finance score:', error);
      }
    };

    const fetchUtilitiesScore = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/utilities/${customerId}/score`);
          setUtilitiesScore(response.data.utilities_score);
        } catch (error) {
          console.error('Error fetching utilities score:', error);
        }
      };

      const fetchRecreationScore = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/recreation/${customerId}/score`);
          setRecreationScore(response.data.recreation_score);
        } catch (error) {
          console.error('Error fetching recreation score:', error);
        }
      };


    fetchInjectionScore();
    fetchShoppingScore();
    fetchPersonalFinanceScore();
    fetchUtilitiesScore();
    fetchRecreationScore();
  }, [customerId]);

  if (injectionScore === null || personalFinanceScore === null) {
    return <Typography>Loading scores...</Typography>;
  }

  return (
    <Card sx={{ backgroundColor: colors.primary[500] }}>
      <CardContent>
        <Typography variant="h5" color={colors.greenAccent[500]} fontWeight="600" gutterBottom>
          Score Breakdown:
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Injection Score: <Typography component="span" fontWeight="555">{injectionScore}</Typography>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Shopping Score: <Typography component="span" fontWeight="555">{shoppingScore}</Typography>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Utilities Score: <Typography component="span" fontWeight="555">{utilitiesScore}</Typography>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Personal Finance Score: <Typography component="span" fontWeight="555">{personalFinanceScore}</Typography>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Recreation Score: <Typography component="span" fontWeight="555">{recreationScore}</Typography>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Dependants Score: <Typography component="span" fontWeight="555">{scores.dependantsScore}</Typography>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CategoryScores;
