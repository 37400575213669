import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from "@mui/material";
import { tokens } from '../../../../theme';
import './ColumnChart.css';

const RecreationLineChart = ({ monthlyRecreation }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!monthlyRecreation) {
    return null; // or render a loading indicator
  }

  // Extracting data for the chart
  const months = monthlyRecreation.map(item => item.month);
  const Alcohol = monthlyRecreation.map(item => item.total_alcohol);
  const Gambling = monthlyRecreation.map(item => item.total_gambling);
  const Restaurant = monthlyRecreation.map(item => item.total_restaurant);


  // Chart options
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: months,
      labels: {
        style: {
          colors: colors.grey[100],
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: colors.grey[100],
        }
      }
    },
    legend: {
      position: 'top',
      labels: {
        colors: colors.grey[100],
      }
    },
    fill: {
      opacity: 1,
    },
  };

  // Chart series data
  const series = [
    {
      name: 'Alcohol Spend',
      data: Alcohol,
    },
    {
      name: 'Gambling Spend',
      data: Gambling,
    },
    {
      name: 'Restaurant Spend',
      data: Restaurant,
    },
  ];

  return (
    <div style={{ width: '95%', paddingTop: '15px' }}> 
      <Chart options={options} series={series} type="bar" height={245} />
    </div>
  );
};

export default RecreationLineChart;

