import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { tokens } from "../../theme";
import PersonalInformation from "./tabs/personal_information";
import CustomerDocuments from "./tabs/customer_documents";
import EmergencyContacts from "./tabs/emergency_contacts";
import CustomerAddress from "./tabs/customer_address";
import CustomerBankAccount from "./tabs/customer_bank_account";
import MotorVehicleRecords from "./tabs/motor_vehicle_records";
import CreditAppraisal from "../Credit Appraisal";
import { fetchWithAuth } from "../../auth";

const TabLabel = ({ text }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Typography variant="h6" fontWeight="600" textTransform="none">
      {text}
    </Typography>
  </Box>
);

const CustomerProfile = ({
  selectedCustomer,
  setSelectedCustomer,
  open,
  onClose,
  onSave,
}) => {
  // Set the default tab to 'Personal' (index 0)
  const [activeTab, setActiveTab] = useState(0);
  const [bankDetails, setBankDetails] = useState({});
  const [physicalAddress, setPhysicalAddress] = useState(null);
  const [nextOfKin, setNextOfKin] = useState({});
  const [emergencyContacts, setEmergencyContacts] = useState({});
  const [motorVehicleRecords, setMotorVehicleRecords] = useState([]);
  const [documents, setDocuments] = useState({});
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.id) {
      fetchBankDetails(selectedCustomer.id);
      fetchNextOfKin(selectedCustomer.id);
      fetchEmergencyContacts(selectedCustomer.id);
      fetchDocuments(selectedCustomer.id);
      fetchMotorVehicleRecords(selectedCustomer.id);
    }
  }, [selectedCustomer]);

  const fetchBankDetails = async (customerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/bank_and_physical_address/${customerId}`);
      const data = await response.json();
      if (data.bank_details) {
        setBankDetails(data.bank_details);
      } else {
        setBankDetails(null);
      }
      if (data.physical_address) {
        setPhysicalAddress(data.physical_address);
      } else {
        setPhysicalAddress(null);
      }
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  const fetchNextOfKin = async (customerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/next-of-kin/${customerId}`);
      setNextOfKin(response.data);
    } catch (error) {
      console.error("Error fetching next of kin:", error);
    }
  };

  const fetchEmergencyContacts = async (customerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/next-of-kin/${customerId}`);
      const data = await response.json();
      setEmergencyContacts(data);
    } catch (error) {
      console.error("Error fetching emergency contacts:", error);
    }
  };

const fetchDocuments = async (customerId) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/get_collateral?customer_id=${customerId}`;
      const data = await fetchWithAuth(url);
  
      if (data.collaterals) {
        setDocuments(data.collaterals);
      } else {
        console.error("Error fetching documents: Collaterals not found.");
      }
    } catch (error) {
      console.error("Error fetching documents:", error.message || error);
    }
  };
  

  const fetchMotorVehicleRecords = async (customerId) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/get_motor_vehicle_records/${customerId}`;
      const data = await fetchWithAuth(url);
  
      if (data.records && Array.isArray(data.records)) {
        setMotorVehicleRecords(data.records); // Correctly set state
      } else {
        console.warn("No records found in the response.");
        setMotorVehicleRecords([]); // Set an empty array if no records
      }
    } catch (error) {
      console.error("Error fetching motor vehicle records:", error);
    }
  };  
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: colors.primary[500],
          maxWidth: '860px',
          minHeight: '500px',
          overflow: 'auto',
        },
      }}
    >
      <DialogTitle color={colors.grey[100]} variant="h5" fontWeight="600">
        Customer Profile:
        <Tooltip title="Close">
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: '450px',   
          maxHeight: '450px', 
          overflow: 'auto',
        }}
      > 
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            textColor="secondary"
            indicatorColor="secondary"
            sx={{ marginBottom: 2 }}
          >
            <Tab label={<TabLabel text="Personal" />} />
            <Tab label={<TabLabel text="Documents" />} />
            <Tab label={<TabLabel text="Emergency Contacts" />} />
            <Tab label={<TabLabel text="Address" />} />
            <Tab label={<TabLabel text="Bank Information" />} />
            <Tab label={<TabLabel text="Motor Vehicle Records" />} />
            <Tab label={<TabLabel text="Credit Report" />} /> 
          </Tabs>

          {activeTab === 0 && <PersonalInformation selectedCustomer={selectedCustomer} />}
          {activeTab === 1 && <CustomerDocuments documents={documents} />}
          {activeTab === 2 && <EmergencyContacts emergencyContacts={emergencyContacts} />}
          {activeTab === 3 && <CustomerAddress physicalAddress={physicalAddress} />}
          {activeTab === 4 && <CustomerBankAccount bankDetails={bankDetails} />}
          {activeTab === 5 && <MotorVehicleRecords records={motorVehicleRecords} />}
          {activeTab === 6 && <CreditAppraisal customerId={selectedCustomer?.id} />}
      </DialogContent>
      <DialogActions>
        {/* Add buttons or other actions here */}
      </DialogActions>
    </Dialog>
  );
};

export default CustomerProfile;