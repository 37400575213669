import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, Tabs, Tab, Slide, AppBar } from '@mui/material';
import CasinoTwoToneIcon from '@mui/icons-material/CasinoTwoTone';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import SurfingOutlinedIcon from '@mui/icons-material/SurfingOutlined';
import { tokens } from "../../../../theme";
import { useTheme } from "@mui/material";
import RecreationProgressCircle from './RecreationProgressCircle';
import RecreationLineChart from './RecreationLineChart';
import RecreationSummary from './recreationsummary';
import RestaurantSpend from './restaurant_spend';
import AlcoholSpend from './alcohol_spend';
import GamblingSpend from './gambling_spend';

const Recreation = ({customerId}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [recreationData, setRecreationData] = useState(null);
  const [monthlyRecreation, setMonthlyRecreation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/recreation-spend/${customerId}`);
        const data = await response.json();
        setRecreationData(data);
        setMonthlyRecreation(data.monthly_recreation);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching recreation data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [customerId]);

  const alcoholPercentage = recreationData ? parseFloat(recreationData.alcohol_percentage) : 0;
  const restaurantsPercentage = recreationData ? parseFloat(recreationData.restaurant_percentage) : 0;
  const gamblingPercentage = recreationData ? parseFloat(recreationData.gambling_percentage) : 0;

  const [activeTab, setActiveTab] = useState(0); 

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); 
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Box mt="3px">
      {recreationData && (
        <>
      <Box mb="1px" mt={1}>         
          <Typography variant="h6" color={colors.grey[100]}>
            Recreation and Gambling Habits: 
          </Typography>
        </Box>
      <Box m={1}>
        <Box mt={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <CasinoTwoToneIcon /> <CelebrationOutlinedIcon /> <SurfingOutlinedIcon />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Recreation and Gambling:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold">
                    Total: Ksh {recreationData.total_recreation ? numberWithCommas(recreationData.total_recreation.toFixed(2)) : 'Loading...'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                position: 'relative', // Adding position relative to create a positioning context for absolute positioning
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Alcohol Spend:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    Ksh {recreationData.total_alcohol_spend ? numberWithCommas(recreationData.total_alcohol_spend.toFixed(2)) : '0.00'}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <RecreationProgressCircle progress={alcoholPercentage / 100} size={25} />
                  <Typography variant="h6" ml={1}>
                    {alcoholPercentage}%
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Card sx={{
              width: '100%',
              position: 'relative', // Adding position relative to create a positioning context for absolute positioning
              backgroundColor: colors.primary[400],
            }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Total Gambling Spend:
                </Typography>
                <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                 Ksh {recreationData.total_gambling_in_amount ? numberWithCommas(recreationData.total_gambling_in_amount.toFixed(2)) : '0.00'}
                </Typography>
              </CardContent>
              <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                <RecreationProgressCircle progress={gamblingPercentage / 100} size={25} />
                <Typography variant="h6" ml={1}>
                  {gamblingPercentage}%
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>
        </Box>
        {/* Row 2 */}
        <Box mt={-2.5}>
          <Grid container spacing={1}>
          <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                width: '100%',
                position: 'relative', // Adding position relative to create a positioning context for absolute positioning
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Restaurant Spend:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    Ksh {recreationData.total_restaurant_amount ? numberWithCommas(recreationData.total_restaurant_amount.toFixed(2)) : '0.00'}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                  <RecreationProgressCircle progress={restaurantsPercentage / 100} size={25} />
                  <Typography variant="h6" ml={1}>
                    {restaurantsPercentage}%
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Card sx={{
                width: '100%',
                position: 'relative', // Adding position relative to create a positioning context for absolute positioning
                backgroundColor: colors.primary[400],
              }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Total Gambling Earnings:
                  </Typography>
                  <Typography variant="h6" color={colors.greenAccent[500]} fontWeight="bold" mb={1}>
                    Ksh {recreationData.total_gambling_wins_amount ? numberWithCommas(recreationData.total_gambling_wins_amount) : '0.00'}
                  </Typography>
                </CardContent>
                <div style={{ position: 'absolute', top: '50%', right: '15px', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center' }}>
                </div>
              </Card>
            </Grid>
          </Grid>
          <br/>
          <Typography
            variant="h6"
            color={colors.grey[100]}
          >
            Spending Habits:
          </Typography>
          <br/>
          <div
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 3,
              }}
            >
              <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                width='100%'
                height="250px"
                m="-20px 0 0 0"
                padding={2}
                sx={{
                  width: '95%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {monthlyRecreation && monthlyRecreation.length > 0 ? (
                  <RecreationLineChart monthlyRecreation={monthlyRecreation} />
                ) : (
                  <Typography variant="body1">No data available</Typography>
                )}
              </Box>
            </div>
          <br/>
          <AppBar position="static" sx={{backgroundColor: colors.primary[500], borderRadius: 2}}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              variant="scrollable"
              sx={{ indicator: {
                backgroundColor: colors.mode === 'dark' ? tokens('dark').myAccent[200] : tokens('light').myAccent[200],
              }}}
            >
              <Tab
                label="Recreation Summary"
                sx={{ fontWeight: '600'}}
              />
              <Tab
                label="Restaurant Transactions"
                sx={{ fontWeight: '600'}}
              />
               <Tab
                label="Alcohol Transactions"
                sx={{ fontWeight: '600'}}
              />
              <Tab
                label="Gambling Transactions"
                sx={{ fontWeight: '600'}}
              />
            </Tabs>
          </AppBar>

          <Slide direction="left" in={activeTab === 0} mountOnEnter unmountOnExit>
            <div>
              <RecreationSummary customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 1} mountOnEnter unmountOnExit>
            <div>
              <RestaurantSpend customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 2} mountOnEnter unmountOnExit>
            <div>
              <AlcoholSpend customerId={customerId}/>
            </div>
          </Slide>
          <Slide direction="left" in={activeTab === 3} mountOnEnter unmountOnExit>
            <div>
              <GamblingSpend customerId={customerId}/>
            </div>
          </Slide>
        </Box>
      </Box>
      </>
      )}      
    </Box>
  );
};

export default Recreation;
