
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Tabs,
  Tab,
  Divider,
  Avatar,
  IconButton,
  Snackbar,
  Alert,
  useTheme,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import PersonIcon from "@mui/icons-material/Person";
import { tokens } from "../../theme";
import { fetchWithAuth } from "../../auth";

const CustomerDetails = ({
  selectedCustomer,
  setSelectedCustomer,
  open,
  onClose,
  onSave,
}) => {
  const [activeTab, setActiveTab] = useState(0); // Default to tab 0
  const [bankDetails, setBankDetails] = useState({});
  const [physicalAddress, setPhysicalAddress] = useState(null);
  const [nextOfKin, setNextOfKin] = useState({});
  const [emergencyContacts, setEmergencyContacts] = useState({});
  const [documents, setDocuments] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Added this line
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.id) {
      fetchBankDetails(selectedCustomer.id);
      fetchNextOfKin(selectedCustomer.id);
      fetchEmergencyContacts(selectedCustomer.id);
      fetchDocuments(selectedCustomer.id);
    }
  }, [selectedCustomer]);

  const fetchBankDetails = async (customerId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/bank_and_physical_address/${customerId}`
      );
      const data = await response.json();
      setBankDetails(data.bank_details || null);
      setPhysicalAddress(data.physical_address || null);
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  const fetchNextOfKin = async (customerId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/next-of-kin/${customerId}`
      );
      setNextOfKin(response.data);
    } catch (error) {
      console.error("Error fetching next of kin:", error);
    }
  };

  const fetchEmergencyContacts = async (customerId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/next-of-kin/${customerId}`
      );
      const data = await response.json();
      setEmergencyContacts(data);
    } catch (error) {
      console.error("Error fetching emergency contacts:", error);
    }
  };

  const fetchDocuments = async (customerId) => {
    try {
      const response = await fetchWithAuth(
        `/api/documents/${customerId}`
      );
      setDocuments(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (activeTab) {
      case 0:
        setSelectedCustomer((prev) => ({ ...prev, [name]: value }));
        break;
      case 1:
        setDocuments((prev) => ({ ...prev, [name]: value }));
        break;
      case 2:
        setEmergencyContacts((prev) => ({ ...prev, [name]: value }));
        break;
      case 3:
        setPhysicalAddress((prev) => ({ ...prev, [name]: value }));
        break;
      case 4:
        setBankDetails((prev) => ({ ...prev, [name]: value }));
        break;
      default:
        break;
    }
  };

  const handleSave = async () => {
    try {
      switch (activeTab) {
        case 0:
          await fetchWithAuth(
            `${process.env.REACT_APP_API_BASE_URL}/api/customers/${selectedCustomer.id}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              data: JSON.stringify(selectedCustomer),
            }
          );
          break;
        case 1:
          await fetchWithAuth(
            `${process.env.REACT_APP_API_BASE_URL}/api/documents/${selectedCustomer.id}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              data: JSON.stringify(documents),
            }
          );
          break;
        case 2:
          await fetchWithAuth(
            `${process.env.REACT_APP_API_BASE_URL}/next-of-kin/${selectedCustomer.id}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              data: JSON.stringify(emergencyContacts),
            }
          );
          break;
        case 3:
          await fetchWithAuth(
            `${process.env.REACT_APP_API_BASE_URL}/bank_and_physical_address/${selectedCustomer.id}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              data: JSON.stringify(physicalAddress),
            }
          );
          break;
        case 4:
          await fetchWithAuth(
            `${process.env.REACT_APP_API_BASE_URL}/bank_and_physical_address/${selectedCustomer.id}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              data: JSON.stringify(bankDetails),
            }
          );
          break;
        default:
          break;
      }
      setSnackbarSeverity("success")
      setSnackbarMessage("Details saved successfully!");
      setSnackbarOpen(true);
      onSave();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: colors.primary[600],
          maxWidth: "1000px",
          minHeight: "500px",
          overflow: "auto",
        },
      }}
    >


      <DialogTitle color={colors.grey[100]} variant="h4" fontWeight="600">
        Edit Customer Details:
      </DialogTitle>
  
      <DialogContent
        sx={{
          minHeight: "450px",
          maxHeight: "450px",
          minWidth: "850px",
          maxWidth: "850px",
          overflow: "auto",
        }}
      >
        <Card
          sx={{
            p: 2,
            backgroundColor: colors.primary[600],
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            textColor="secondary"
            indicatorColor="secondary"
            sx={{ marginBottom: 2 }}
          >
            <Tab label="Personal" />
            <Tab label="Documents" />
            <Tab label="Emergency Contacts" />
            <Tab label="Address" />
            <Tab label="Bank Account" />
          </Tabs>
                  {activeTab === 0 && (
            <CardContent>
              <Typography variant="h6" color={colors.grey[100]}>
                Personal Information
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="first_name"
                    label="First Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={selectedCustomer ? selectedCustomer.first_name : ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    name="last_name"
                    label="Last Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={selectedCustomer ? selectedCustomer.last_name : ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={selectedCustomer ? selectedCustomer.email : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="id_number"
                    label="ID Number"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={selectedCustomer ? selectedCustomer.id_number : ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    name="phone_number"
                    label="Phone Number"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={selectedCustomer ? selectedCustomer.phone_number : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          )}
          {activeTab === 1 && (
            <CardContent>
              <Typography variant="h6" color={colors.grey[100]}>
                Documents
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Document Type"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={documents ? documents.documentType : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Document ID"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={documents ? documents.documentId : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
        )}
           {activeTab === 2 && (
            <CardContent>
              <Typography variant="h6" color={colors.grey[100]}>
                Emergency Contacts
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense" 
                    label="First Name"
                    name="first_name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={emergencyContacts ? emergencyContacts.first_name : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Last Name"
                    name="last_name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={emergencyContacts ? emergencyContacts.last_name : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Phone Number"
                    name="phone_number"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={emergencyContacts ? emergencyContacts.phone_number : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Email Address"
                    name="email"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={emergencyContacts ? emergencyContacts.email : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Relationship"
                    name="relationship"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={emergencyContacts ? emergencyContacts.relationship : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          )}
          {activeTab === 3 && (
            <CardContent>
              <Typography variant="h6" color={colors.grey[100]}>
                Address
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Street Address"
                    name="postalAddress"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={physicalAddress ? physicalAddress.postalAddress : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Postal Code"
                    name="postalCode"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={physicalAddress ? physicalAddress.postalCode : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="County"
                    name="county"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={physicalAddress ? physicalAddress.county : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="City"
                    name="cityTown"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={physicalAddress ? physicalAddress.cityTown : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Location"
                    name="location"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={physicalAddress ? physicalAddress.location : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Estate Building"
                    name="estateBuilding"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={physicalAddress ? physicalAddress.estateBuilding : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Nearest Landmark description"
                    name="description"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={physicalAddress ? physicalAddress.description : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          )}
          {activeTab === 4 && (
            <CardContent>
              <Typography variant="h6" color={colors.grey[100]}>
                Bank Account
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Bank Name"
                    name="bankName"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={bankDetails ? bankDetails.bankName : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Account Number"
                    name="branch"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={bankDetails ? bankDetails.branch : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Bank Name"
                    name="accountName"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={bankDetails ? bankDetails.accountName : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    label="Account Number"
                    name="accountNumber"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={bankDetails ? bankDetails.accountNumber : ""}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          )}

        </Card>
      </DialogContent>
      <DialogActions>
      <Button onClick={onClose} color="error" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSave} color="success" variant="contained">
          Save Changes
        </Button>
      </DialogActions>

      <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Positioning at top-center
>
  <Alert
    onClose={handleSnackbarClose}
    severity={snackbarSeverity} // Pass severity dynamically
    sx={{
      width: "100%",
      backgroundColor: 
        snackbarSeverity === "success" ? "green" : 
        snackbarSeverity === "error" ? "red" : 
        snackbarSeverity === "warning" ? "orange" : 
        snackbarSeverity === "info" ? "blue" : "inherit",
      color: "white",
    }}
  >
    {snackbarMessage}
  </Alert>
</Snackbar>

    </Dialog>
  );
};

export default CustomerDetails;

