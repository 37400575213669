// TeamIPFBarChart.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../../theme';

const TeamIPFBarChart = ({ selectedMonth, selectedYear }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                foreColor: colors.grey[100], // Set the color for chart text
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: false,
                    endingShape: 'rounded',
                },
            },
            xaxis: {
                categories: [],
                title: {
                    text: 'Teams',
                    style: {
                        color: colors.grey[100], // Set color for x-axis title
                    },
                },
                labels: {
                    style: {
                        colors: colors.grey[100], // Set color for x-axis labels
                    },
                },
            },
            yaxis: {
                title: {
                    text: 'IPF Net Sales',
                    style: {
                        color: colors.grey[100], // Set color for y-axis title
                    },
                },
                labels: {
                    style: {
                        colors: colors.grey[100], // Set color for y-axis labels
                    },
                },
            },
            colors: [], // Will be populated dynamically
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ipf-net-sales-summary`, {
                    params: { month: selectedMonth, year: selectedYear },
                });

                const teamsData = response.data.teams_ipf_net_sales;

                if (teamsData.length > 0) {
                    const categories = teamsData.map(team => team.team_name);
                    const salesData = teamsData.map(team => team.ipf_net_sales);

                    // Generate random colors for each team
                    const colors = teamsData.map(() => `#${Math.floor(Math.random()*16777215).toString(16)}`);

                    setChartData(prevState => ({
                        ...prevState,
                        series: [{ name: 'IPF Net Sales', data: salesData }],
                        options: {
                            ...prevState.options,
                            xaxis: { categories },
                            colors,
                        },
                    }));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (selectedMonth && selectedYear) {
            fetchData();
        }
    }, [selectedMonth, selectedYear]);

    return (
        <Box sx={{ width: '100%', padding: 2 }}>
            <Typography color={colors.grey[100]} variant="h6" fontWeight="600" gutterBottom>
                IPF Net Sales Summary for {selectedMonth}/{selectedYear}:
            </Typography>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={350}
            />
        </Box>
    );
};

export default TeamIPFBarChart;
