import React, { useState } from 'react';
import { Box, Tabs, Tab, Slide, Typography } from '@mui/material';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material"; // Added import
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@mui/material/AppBar';

// Children Imports
import Injections from './injections';
import PersonalFinance from './personal finance';
import Shopping from './shopping';
import Recreation from './recreation';
import Utilities from './utilities';
import Dependants from './dependants';
import CustomerTransfer from '../FinancialSummary/customertransfer';
import Location from './others';


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  tab: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
  },
}));

const Insights = ({ customerId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTab, setSelectedTab] = useState(0); // State to manage selected tab index

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); // Update selected tab index
  };

  return (
    <Box m="20px">
        <Box mb="1px">         
          <Typography variant="h5" color={colors.greenAccent[400]}>
            Insight Categories: 
          </Typography>
        </Box>
       {/* Tabs */}
      <Box sx={{ width: '100%', mt: '5px' }}>
        <AppBar position="static" sx={{backgroundColor: colors.primary[500], borderRadius: 2}}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="Injections"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Personal Finance"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Shopping"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Recreation"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Utilities"
              sx={{fontWeight: '600'}}
            />
            <Tab
              label="Dependants"
              sx={{fontWeight: '600'}}
            />
          <Tab
              label="Others"
              sx={{fontWeight: '600'}}
            />
          </Tabs>
        </AppBar>
      </Box>
      <Slide direction="left" in={selectedTab === 0} mountOnEnter unmountOnExit>
        <div>
          <Injections customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 1} mountOnEnter unmountOnExit>
        <div>
          <PersonalFinance customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 2} mountOnEnter unmountOnExit>
        <div>
          <Shopping customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 3} mountOnEnter unmountOnExit>
        <div>
          <Recreation customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 4} mountOnEnter unmountOnExit>
        <div>
          <Utilities customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 5} mountOnEnter unmountOnExit>
        <div>
          {/* <Dependants customerId={customerId} /> */}
          <CustomerTransfer customerId={customerId} />
        </div>
      </Slide>
      <Slide direction="left" in={selectedTab === 6} mountOnEnter unmountOnExit>
        <div>
          <Location customerId={customerId} />
        </div>
      </Slide>
    </Box>
  );
};

export default Insights;
