import React from 'react';
import { CardContent, Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from '../../../theme';

const CustomerAddress = ({ physicalAddress }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <CardContent>
      <Typography variant="h6" color={colors.grey[100]}>
         Physical Address Information
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Street Address"
            type="text"
            fullWidth
            variant="outlined"
            value={physicalAddress ? physicalAddress.postalAddress : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Postal Code"
            type="text"
            fullWidth
            variant="outlined"
            value={physicalAddress ? physicalAddress.postalCode : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="County"
            type="text"
            fullWidth
            variant="outlined"
            value={physicalAddress ? physicalAddress.county : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="City"
            type="text"
            fullWidth
            variant="outlined"
            value={physicalAddress ? physicalAddress.cityTown : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Location"
            type="text"
            fullWidth
            variant="outlined"
            value={physicalAddress ? physicalAddress.location : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Estate Building"
            type="text"
            fullWidth
            variant="outlined"
            value={physicalAddress ? physicalAddress.estateBuilding : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            label="Nearest Landmark description"
            type="text"
            fullWidth
            variant="outlined"
            value={physicalAddress ? physicalAddress.description : ""}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default CustomerAddress;
