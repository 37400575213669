import React, { useState } from 'react';
import Tree from 'react-d3-tree';
import { useTheme } from "@mui/material";
import { tokens } from '../../theme';

const initialTreeData = [
  {
    name: 'Overall Score',
    children: [
      {
        name: 'Capability Score (0.5)',
        children: [
          { name: 'Personal Finance (0.2)' },
          { name: 'Injections (0.3)' }
        ],
        collapsed: true,
      },
      {
        name: 'Character Score (0.5)',
        children: [
          { name: 'Utilities (0.1)', collapsed: true, children: [] },
          { name: 'Dependants (0.05)', collapsed: true, children: [] },
          { name: 'Recreation (0.1)', collapsed: true, children: [] },
          { name: 'Shopping (0.1)', collapsed: true, children: [] },
          { name: 'Personal Finance (Loans) (0.15)', collapsed: true, children: [] }
        ],
        collapsed: true,
      }
    ]
  }
];

const containerStyles = {
  width: '100%',
  height: '600px',
};

const nodeSvgShape = {
  shape: 'rect',
  shapeProps: {
    width: 140,
    height: 20,
    x: -70,
    y: -10,
    fill: '#ffffff',
    stroke: '#000000',
    strokeWidth: 1,
  },
};

const CreditScoreTree = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [treeData, setTreeData] = useState(initialTreeData);

  const handleNodeClick = (nodeDatum) => {
    const toggleNodeCollapse = (node) => {
      if (node.name === nodeDatum.name) {
        node.collapsed = !node.collapsed;
        if (!node.collapsed && node.children.length === 0) {
          switch (node.name) {
            case 'Utilities (0.1)':
              node.children = [
                { name: 'Rent' },
                { name: 'Power and Water' },
                { name: 'Fuel/Gas' },
                { name: 'Airtime, Data & TV' },
              ];
              break;
            case 'Dependants (0.05)':
              node.children = [{ name: 'Dependants Spend' }];
              break;
            case 'Recreation (0.1)':
              node.children = [
                { name: 'Alcohol Spend' },
                { name: 'Restaurant Spend' },
                { name: 'Betting Spend' },
                { name: 'Betting Earnings' },
              ];
              break;
            case 'Shopping (0.1)':
              node.children = [
                { name: 'Retail Spend' },
                { name: 'FMCG Spend' },
                { name: 'Health Spend' },
                { name: 'Food and Grocery' },
              ];
              break;
            case 'Personal Finance (Loans) (0.15)':
              node.children = [
                { name: 'Loans' },
                { name: 'Loan Repayments' },
                { name: 'Savings' },
                { name: 'Mshwari' },
              ];
              break;
            default:
              break;
          }
        }
      }
      if (node.children) {
        node.children.forEach(toggleNodeCollapse);
      }
    };

    setTreeData((prevTreeData) => {
      const newTreeData = JSON.parse(JSON.stringify(prevTreeData));
      toggleNodeCollapse(newTreeData[0]);
      return newTreeData;
    });
  };

  const translate = { x: 100, y: 160 };
  const zoom = 0.5;

  return (
    <div style={containerStyles}>
      <Tree
        data={treeData}
        orientation="horizontal"
        translate={translate}
        zoom={zoom}
        nodeSize={{ x: 200, y: 100 }}
        nodeSvgShape={nodeSvgShape}
        styles={{
          links: {
            stroke: colors.myAccent[600],
            strokeWidth: 2,
          },
          nodes: {
            node: {
              circle: {
                stroke: '#000000',
                strokeWidth: 2,
              },
              name: {
                stroke: 'none',
                fill: colors.myAccent[600],
              },
              attributes: {
                stroke: 'none',
                fill: '#000000',
              },
            },
            leafNode: {
              circle: {
                stroke: '#000000',
                strokeWidth: 2,
              },
              name: {
                stroke: 'none',
                fill: colors.myAccent[600],
              },
              attributes: {
                stroke: 'none',
                fill: '#000000',
              },
            },
          },
        }}
        collapsible={true}
        onNodeClick={handleNodeClick}
      />
    </div>
  );
};

export default CreditScoreTree;
