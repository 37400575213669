import React from "react";
import { Grid, Typography, Paper, Box, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { tokens } from "../../../theme";

const MotorVehicleRecords = ({ records }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: "left",
    backgroundColor: colors.primary[400],
  }));
  
  return (
    <Box>
      {records && records.length > 0 ? (
        <Grid container spacing={2}>
          {records.map((record, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Vehicle Make:
                  </Typography>
                  <Typography variant="body1">{record.car_make}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Model:
                  </Typography>
                  <Typography variant="body1">{record.car_model}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Registration Number:
                  </Typography>
                  <Typography variant="body1">
                    {record.vehicle_registration_number}
                  </Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Chassis Number:
                  </Typography>
                  <Typography variant="body1">
                    {record.vehicle_chassis_number}
                  </Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Year of Manufacture:
                  </Typography>
                  <Typography variant="body1">
                    {record.year_of_manufacture}
                  </Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Market Value:
                  </Typography>
                  <Typography variant="body1">{record.market_value}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Forced Sale Value:
                  </Typography>
                  <Typography variant="body1">
                    {record.initial_forced_sale_value}
                  </Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Condition:
                  </Typography>
                  <Typography variant="body1">{record.condition}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Log Book Number:
                  </Typography>
                  <Typography variant="body1">{record.log_book_number}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Registration Date:
                  </Typography>
                  <Typography variant="body1">
                    {new Date(record.registration_date).toLocaleDateString()}
                  </Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Customs Entry Number:
                  </Typography>
                  <Typography variant="body1">
                    {record.customs_entry_number}
                  </Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Body Type:
                  </Typography>
                  <Typography variant="body1">{record.body_type}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Body Color:
                  </Typography>
                  <Typography variant="body1">{record.body_color}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Engine Number:
                  </Typography>
                  <Typography variant="body1">{record.engine_number}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Fuel Type:
                  </Typography>
                  <Typography variant="body1">{record.fuel_type}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Rating:
                  </Typography>
                  <Typography variant="body1">{record.rating}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Log Book Serial Number:
                  </Typography>
                  <Typography variant="body1">
                    {record.log_book_serial_number}
                  </Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Current Owner:
                  </Typography>
                  <Typography variant="body1">{record.current_owner}</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper>
                  <Typography variant="subtitle2" fontWeight="600">
                    Owner KRA PIN:
                  </Typography>
                  <Typography variant="body1">{record.owner_kra_pin}</Typography>
                </StyledPaper>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1" color="textSecondary" align="center">
          No motor vehicle records available for this client.
        </Typography>
      )}
    </Box>
  );
};

export default MotorVehicleRecords;
