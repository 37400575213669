import React from "react";
import { Box, TextField, Typography, Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";

// List of Kenyan banks
const banks = [
    "Absa Bank", "African Banking Corporation", "Bank of Africa", "Bank of Baroda", "Bank of India", 
    "Central Bank of Kenya", "Citibank", "Commercial Bank of Africa", "Cooperative Bank of Kenya", 
    "Credit Bank", "Development Bank of Kenya", "Diamond Trust Bank", "Ecobank", "Equity Bank", 
    "Family Bank", "First Community Bank", "Guaranty Trust Bank", "Gulf African Bank", 
    "Habib Bank AG Zurich", "I&M Bank", "Imperial Bank", "Jamii Bora Bank", "Kenya Commercial Bank", 
    "Middle East Bank Kenya", "National Bank of Kenya", "NIC Bank", "Paramount Universal Bank", 
    "Prime Bank", "Sidian Bank", "Spire Bank", "Stanbic Bank", "Standard Chartered Kenya", 
    "Trans National Bank Kenya", "UBA Kenya Bank", "Victoria Commercial Bank"
];

// List of Kenyan counties
const counties = [
  { number: 1, name: "Mombasa" },
  { number: 2, name: "Kwale" },
  { number: 3, name: "Kilifi" },
  { number: 4, name: "Tana River" },
  { number: 5, name: "Lamu" },
  { number: 6, name: "Taita-Taveta" },
  { number: 7, name: "Garissa" },
  { number: 8, name: "Wajir" },
  { number: 9, name: "Mandera" },
  { number: 10, name: "Marsabit" },
  { number: 11, name: "Isiolo" },
  { number: 12, name: "Meru" },
  { number: 13, name: "Tharaka-Nithi" },
  { number: 14, name: "Embu" },
  { number: 15, name: "Kitui" },
  { number: 16, name: "Machakos" },
  { number: 17, name: "Makueni" },
  { number: 18, name: "Nyandarua" },
  { number: 19, name: "Nyeri" },
  { number: 20, name: "Kirinyaga" },
  { number: 21, name: "Murang'a" },
  { number: 22, name: "Kiambu" },
  { number: 23, name: "Turkana" },
  { number: 24, name: "West Pokot" },
  { number: 25, name: "Samburu" },
  { number: 26, name: "Trans-Nzoia" },
  { number: 27, name: "Uasin Gishu" },
  { number: 28, name: "Elgeyo-Marakwet" },
  { number: 29, name: "Nandi" },
  { number: 30, name: "Baringo" },
  { number: 31, name: "Laikipia" },
  { number: 32, name: "Nakuru" },
  { number: 33, name: "Narok" },
  { number: 34, name: "Kajiado" },
  { number: 35, name: "Kericho" },
  { number: 36, name: "Bomet" },
  { number: 37, name: "Kakamega" },
  { number: 38, name: "Vihiga" },
  { number: 39, name: "Bungoma" },
  { number: 40, name: "Busia" },
  { number: 41, name: "Siaya" },
  { number: 42, name: "Kisumu" },
  { number: 43, name: "Homa Bay" },
  { number: 44, name: "Migori" },
  { number: 45, name: "Kisii" },
  { number: 46, name: "Nyamira" },
  { number: 47, name: "Nairobi" }
];

const BankAddress = React.memo(({ values, errors, touched, handleBlur, handleChange, handleSubmit, isNonMobile }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Header subtitle="Physical Address and Bank Details:" />
      {/* <Box mt="1px" p="15px" bgcolor={colors.primary[201]}> */}
        <Typography variant="h6" mb={1} gutterBottom>
          Physical Address Details:
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, 1fr)"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Postal Address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.postalAddress}
              name="postalAddress"
              error={!!touched.postalAddress && !!errors.postalAddress}
              helperText={touched.postalAddress && errors.postalAddress}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Postal Code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.postalCode}
              name="postalCode"
              error={!!touched.postalCode && !!errors.postalCode}
              helperText={touched.postalCode && errors.postalCode}
              sx={{ gridColumn: "span 2" }}
            />
            <FormControl fullWidth variant="filled" error={!!touched.county && !!errors.county} sx={{ gridColumn: "span 2" }}>
              <InputLabel>County</InputLabel>
              <Select
                label="County"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.county}
                name="county"
              >
                {counties.map(county => (
                  <MenuItem key={county.number} value={county.name}>{county.name}</MenuItem>
                ))}
              </Select>
              {touched.county && errors.county && (
                <FormHelperText>{errors.county}</FormHelperText>
              )}
            </FormControl>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Location"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.location}
              name="location"
              error={!!touched.location && !!errors.location}
              helperText={touched.location && errors.location}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="City/Town"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.cityTown}
              name="cityTown"
              error={!!touched.cityTown && !!errors.cityTown}
              helperText={touched.cityTown && errors.cityTown}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Estate/Building"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.estateBuilding}
              name="estateBuilding"
              error={!!touched.estateBuilding && !!errors.estateBuilding}
              helperText={touched.estateBuilding && errors.estateBuilding}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Description/Nearest Landmark"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
              name="description"
              error={!!touched.description && !!errors.description}
              helperText={touched.description && errors.description}
              sx={{ gridColumn: "span 4" }}
            />
            <Typography variant="h6" mb={-1} gutterBottom sx={{ gridColumn: "span 4" }}>
              Bank Details:
            </Typography>
            <FormControl fullWidth variant="filled" error={!!touched.bankName && !!errors.bankName} sx={{ gridColumn: "span 2" }}>
              <InputLabel>Bank Name</InputLabel>
              <Select
                label="Bank Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.bankName}
                name="bankName"
              >
                {banks.map(bank => (
                  <MenuItem key={bank} value={bank}>{bank}</MenuItem>
                ))}
              </Select>
              {touched.bankName && errors.bankName && (
                <FormHelperText>{errors.bankName}</FormHelperText>
              )}
            </FormControl>
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Bank Branch"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.branch}
              name="branch"
              error={!!touched.branch && !!errors.branch}
              helperText={touched.branch && errors.branch}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Account Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.accountNumber}
              name="accountNumber"
              error={!!touched.accountNumber && !!errors.accountNumber}
              helperText={touched.accountNumber && errors.accountNumber}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Bank Account Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.accountName}
              name="accountName"
              error={!!touched.accountName && !!errors.accountName}
              helperText={touched.accountName && errors.accountName}
              sx={{ gridColumn: "span 2" }}
            />
          </Box>
        </form>
      </Box>
  );
});

export default BankAddress;
