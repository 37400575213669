import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Typography, Grid, Button, useTheme, Card, CardContent } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { fetchWithAuth } from '../../auth'; 
import CreditAppraisal from "../Credit Appraisal";
import { tokens } from "../../theme";

const Workflow = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [columns, setColumns] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [viewAppraisal, setViewAppraisal] = useState(false); 
  const [filteredStatus, setFilteredStatus] = useState('todo');
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/api/appraised-customers`);
        const categorizedData = {
          [uuidv4()]: { name: 'todo', items: [] },
          [uuidv4()]: { name: 'under review', items: [] },
          [uuidv4()]: { name: 'approval pending', items: [] },
          [uuidv4()]: { name: 'awaiting disbursement', items: [] },
          [uuidv4()]: { name: 'Completed', items: [] }
        };

        data.forEach(customer => {
          const columnId = Object.keys(categorizedData).find(id => categorizedData[id].name === customer.loan_status);
          if (columnId) {
            categorizedData[columnId].items.push({
              id: customer.id,
              ...customer
            });
          }
        });

        setColumns(categorizedData);
        setFilteredCustomers(categorizedData[Object.keys(categorizedData).find(id => categorizedData[id].name === filteredStatus)].items);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: { ...sourceColumn, items: sourceItems },
        [destination.droppableId]: { ...destColumn, items: destItems }
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({ ...columns, [source.droppableId]: { ...column, items: copiedItems } });
    }
  };

  const handleViewAppraisal = (customer) => {
    const customerId = customer.id;
    setViewAppraisal(true); 
    setSelectedCustomerId(customerId);
  };

  const handleStatusFilter = (statusName) => {
    setFilteredStatus(statusName);
    const filtered = columns[Object.keys(columns).find(id => columns[id].name === statusName)].items;
    setFilteredCustomers(filtered);
  };

  return (
    <Box m="20px">
      {!viewAppraisal ? (
        <>
          <Typography variant="h5" fontWeight="600" sx={{ color: colors.grey[100], mb: -3 }}>
            Loan Workflow Dashboard:
          </Typography>
          
          <Grid container spacing={3} sx={{ marginTop: '20px' }}>
            {Object.entries(columns).map(([columnId, column]) => (
              <Grid item key={columnId} xs={12} sm={6} md={4}>
                <Card 
                  sx={{
                    backgroundColor: colors.primary[400],
                    color: colors.grey[100],
                    border: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#ddd'}`,
                    transition: 'background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease',
                    cursor: 'pointer',
                    '&:hover': { boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)' }
                  }}
                  onClick={() => handleStatusFilter(column.name)}
                >
                  <CardContent>
                    <Typography variant="h6" fontWeight="600" align="center">{column.name}</Typography>
                    <Typography variant="body2" align="center">Loans: {column.items.length}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Box mt={5}>
            <Typography variant="h5" fontWeight="600" sx={{ color: colors.grey[100], mb: 2 }}>
              Showing Loans for: {filteredStatus}
            </Typography>
            <DataGrid
              rows={filteredCustomers}
              columns={[
                { field: 'first_name', headerName: 'First Name', flex: 1 },
                { field: 'last_name', headerName: 'Last Name', flex: 1 },
                { field: 'email', headerName: 'Email', flex: 1 },
                { field: 'phone_number', headerName: 'Phone Number', flex: 1 },
                { field: 'id_number', headerName: 'ID Number', flex: 1 },
                { field: 'loan_status', headerName: 'Loan Status', flex: 1 },
                {
                  field: 'appraisal_date',
                  headerName: 'Date of Appraisal',
                  flex: 1,
                  renderCell: (params) => (
                    params.value ? new Date(params.value).toLocaleDateString('en-GB') : null
                  ),
                },
                {
                  field: 'action',
                  headerName: 'Action',
                  flex: 1,
                  renderCell: (params) => (
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        "&:hover": {
                          backgroundColor: colors.myAccent[800],
                        },
                        color: colors.grey[100],
                      }}
                      onClick={() => handleViewAppraisal(params.row)}
                    >
                      View Appraisal
                    </Button>
                  ),
                },
              ]}
              components={{ Toolbar: GridToolbar }}
              sx={{
                height: 400,
                width: '100%',
                borderRadius: '8px',
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            />
          </Box>
        </>
      ) : (
        <CreditAppraisal customerId={selectedCustomerId} />
      )}
    </Box>
  );
};

export default Workflow;