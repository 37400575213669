import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, AppBar, Tabs, Tab, Slide } from "@mui/material";
import { tokens } from "../../../../theme";
import BankInjections from '../../Insights/injections/bankInjections';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2), 
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2), 
  },
}));

const Others = ({ customerId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [othersPaidOut, setOthersPaidOut] = useState(0);
  const [othersPaidIn, setOthersPaidIn] = useState(0);
  const [mostFrequentTransactions, setMostFrequentTransactions] = useState([]);
  const [recipientData, setRecipientData] = useState([]);

  useEffect(() => {
    // Fetch data from backend route to get transaction details
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/other-transaction-amounts/${customerId}`)
      .then(response => response.json())
      .then(data => {
        setTransactionCount(data.other_transactions_count);
        setOthersPaidOut(parseFloat(data.other_transaction_paid_out));
        setOthersPaidIn(parseFloat(data.other_transaction_paid_in));
      })
      .catch(error => console.error('Error fetching others transactions:', error));
  
    // Fetch data from backend route to get most frequent transactions
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/other-frequent-transactions/${customerId}`)
      .then(response => response.json())
      .then(data => {
        setMostFrequentTransactions(data.most_frequent_transactions);
        setRecipientData(data.recipient_data); // Set recipient data directly from the response
      })
      .catch(error => console.error('Error fetching most frequent deposits:', error));
  }, [customerId]);

  // Function to format numbers with commas
  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); 
  };

  return (
    <Box m="5px" mt={2}>
      <Grid container justifyContent="center" spacing={1}>
        {/* Grid items for cards */}
        {[1, 2, 3].map((index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card sx={{
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: colors.primary[400],
              marginTop: theme.spacing(1),
            }}>
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight="600"
                  color={colors.grey[100]}
                  gutterBottom
                >
                  {index === 1 ? 'Total Amount Paid In:' : index === 2 ? 'Total Amount Paid out:' : 'Total No. of Transactions:'}
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  {index === 1 ? `ksh ${typeof othersPaidIn === 'number' ? numberWithCommas(othersPaidIn.toFixed(2)) : ''}` : index === 2 ? `ksh ${typeof othersPaidOut === 'number' ? numberWithCommas(othersPaidOut.toFixed(2)) : ''}` : transactionCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br/>
      <Typography
        variant="h5"
        fontWeight="600"
        mt={1}
        color={colors.grey[100]}
        >
          Top 3 Transactions:
        </Typography>
        {/* Modified container div */}
        <div className={classes.chartContainer}>
          {mostFrequentTransactions.map((recipient, index) => (
            <Box key={index} sx={{
              backgroundColor: colors.primary[400],
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '250px',
              width: '32%',
              padding: '10px',
            }}>
              <Typography variant="h6" color={colors.greenAccent[400]}>
                {recipient.toUpperCase()} {/* Convert recipient name to uppercase */}
              </Typography>
              {/* <TransferLineChart data={recipientData.transactions} /> */}
            </Box>
          ))}
        </div>
        <br/>
       {/* Tabs */}
       <AppBar position="static" sx={{backgroundColor: colors.primary[500], borderRadius: 2}}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
          >
            {['Bank Injections'].map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={{fontWeight: '600'}}
              />
            ))}
          </Tabs>
        </AppBar>
        {/* Slide for tab content */}
        <Slide direction="left" in={activeTab === 0} mountOnEnter unmountOnExit>
          <div>
          <BankInjections customerId={customerId} />
          </div>
        </Slide>
      </Box>
    );
};

export default Others;

