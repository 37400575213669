import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import PersonIcon from '@mui/icons-material/Person';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import ScubaDivingIcon from '@mui/icons-material/ScubaDiving';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Gauge from './creditscoregauge';

const fetchScores = async (customerId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/overall-score/${customerId}`);
    const data = await response.json();
    if (data.error) {
      console.error("Error fetching scores:", data.error);
      return null;
    }
    return data;
  } catch (error) {
    console.error("Error fetching scores:", error);
    return null;
  }
};

const CreditScore = ({ customerId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [scores, setScores] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchScores(customerId);
      if (data) {
        setScores(data);
      }
    };
    fetchData();
  }, [customerId]);

  if (!scores) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box mt="3px">
      <Box m={2}>
        <Typography variant="h5" color={colors.greenAccent[500]} fontWeight="600" gutterBottom>
          Comprehensive Score:
        </Typography>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '90%',
                  textAlign: 'center',
                  backgroundColor: colors.primary[400],
                }}>
                <CardContent>
                  <StarsIcon style={{ fontSize: 40 }} />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Capability Score:
                  </Typography>
                  <Typography mt={2} variant="body1" color={colors.greenAccent[500]} component="p">
                    Application Score: {scores.capability_score.toFixed(2)}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color={colors.greenAccent[500]}
                  >
                    Risk Grade: {scores.capability_score < 0.5 ? 'Low' : 'High'}
                  </Typography>
                  <Typography
                    mt={3}
                    variant="body2"
                    component="p"
                   > Capability Score is a direct look at a Customer's ability to pay back 'x' amount of money.
                   </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '90%',
                  textAlign: 'center',
                  backgroundColor: colors.primary[400],
                }}>
                <CardContent>
                  <PersonIcon style={{ fontSize: 40 }} />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Character Score:
                  </Typography>
                  <Typography mt={2} variant="body1" color={colors.greenAccent[500]} component="p">
                    Application Score: {scores.character_score.toFixed(2)}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color={colors.greenAccent[500]}
                  >
                    Risk Grade: {scores.character_score < 0.5 ? 'Low' : 'High'}
                  </Typography>
                  <Typography
                    mt={3}
                    variant="body2"
                    component="p"
                   > 
                    Character Score is a direct look at a Customer's financial behaviour based on their spending habits.
                   </Typography> 
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={11} sm={4}>
              <Card sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '90%',
                  textAlign: 'center',
                  backgroundColor: colors.primary[400],
                }}>
                <CardContent>
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Overall Score:
                  </Typography>
                  <Typography>
                    <Gauge height={31} value={scores.capability_score + scores.character_score} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box mt={1}>
          <Typography variant="h5" color={colors.greenAccent[500]} fontWeight="600" gutterBottom>
            <InsightsOutlinedIcon /> Category Score:
          </Typography>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  textAlign: 'center',
                  backgroundColor: colors.primary[400],
                }}>
                <CardContent>
                  <AddCardOutlinedIcon />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Injections:
                  </Typography>
                  <Typography variant="body1" color={colors.greenAccent[500]} component="p" fontWeight="bold">
                    Score: <span>{scores.injection_score.toFixed(2)}</span>
                  </Typography>
                  <Typography variant="body2" component="p">
                    The Injection score is high. Customer bulk money received is from Banks, closely followed by individuals and Money deposits from agents.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  textAlign: 'center',
                  backgroundColor: colors.primary[400],
                }}>
                <CardContent>
                  <ShoppingCartIcon />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Shopping:
                  </Typography>
                  <Typography variant="body1" color={colors.greenAccent[500]} component="p" fontWeight="bold">
                    Score: <span>{scores.shopping_score.toFixed(2)}</span>
                  </Typography>
                  <Typography variant="body2" component="p">
                    The Shopping score is high. Customer spends more on FMCG and Food. Their spend on Retail and Health is low.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  textAlign: 'center',
                  backgroundColor: colors.primary[400],
                }}>
                <CardContent>
                  <AttachMoneyIcon />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Utilities:
                  </Typography>
                  <Typography variant="body1" color={colors.greenAccent[500]} component="p" fontWeight="bold">
                    Score: <span>{scores.utilities_score.toFixed(2)}</span>
                  </Typography>
                  <Typography variant="body2" component="p">
                    The Utilities score is high. Customer pays Rent, utilities, and subscriptions for applications. These payments are timely.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  textAlign: 'center',
                  backgroundColor: colors.primary[400],
                }}>
                <CardContent>
                  <PaymentsIcon />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Personal Finance:
                  </Typography>
                  <Typography variant="body1" color={colors.greenAccent[500]} component="p" fontWeight="bold">
                    Score: <span>{scores.personal_finance_score.toFixed(2)}</span>
                  </Typography>
                  <Typography variant="body2" component="p">
                    The Personal Finance score is high. Customer's source of money is primarily from Salary, received almost monthly.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  textAlign: 'center',
                  backgroundColor: colors.primary[400],
                }}>
                <CardContent>
                  <ScubaDivingIcon />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Recreation:
                  </Typography>
                  <Typography variant="body1" color={colors.greenAccent[500]} component="p" fontWeight="bold">
                    Score: <span>{scores.recreation_score.toFixed(2)}</span>
                  </Typography>
                  <Typography variant="body2" component="p">
                    The Recreation score is high. Customer spends more on Eating out and is not driven by restaurants, fast foods, bars, and night clubs.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  textAlign: 'center',
                  backgroundColor: colors.primary[400],
                }}>
                <CardContent>
                  <FamilyRestroomIcon />
                  <Typography variant="h6" component="h2" fontWeight="bold">
                    Dependants:
                  </Typography>
                  <Typography variant="body1" color={colors.greenAccent[500]} component="p" fontWeight="bold">
                    {/* Score: <span>{scores.overall_score.toFixed(2)}</span> */}
                    Score: <span>0.50</span>
                  </Typography>
                  <Typography variant="body2" component="p">
                    The Dependants score is low. Customer takes care of others and the dependant activities indicate high potential for child support.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CreditScore;
